import React from 'react';
import { Grid, Skeleton } from '@mui/material';
import { Wrapper } from 'components/_commons/Wrapper';

export const SkeletonDetailUser = () => (
  <Wrapper>
    <Skeleton height={100} style={{ margin: '1rem auto' }} variant="circular" width={100} />
    <Skeleton height={40} style={{ margin: '2rem auto' }} width={200} />
    <Grid container spacing={4} wrap="wrap">
      <Grid item sm={6} xs={12}>
        <Skeleton height={200} style={{ marginBottom: '2rem' }} variant="rect" />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Skeleton height={200} style={{ marginBottom: '2rem' }} variant="rect" />
      </Grid>
    </Grid>
    <Skeleton height={200} style={{ marginBottom: '2rem' }} variant="rect" />
  </Wrapper>
);