import { DialogContent } from '@mui/material';

import { styled } from '@mui/material/styles';
import { DocumentStep } from 'components/_commons/Modals/AddToolsModal/DocumentStep';
import { RegistrationStep } from 'components/_commons/Modals/AddToolsModal/RegistrationStep';
import { SummaryStep } from 'components/_commons/Modals/AddToolsModal/SummaryStep';

import { ModalSectionTitle } from 'components/_commons/Modals/common/ModalSectionTitle';

import { ToolsModalStepsEnum } from 'core/enums';
import { useFetch } from 'hooks';
import React, { useCallback } from 'react';
import { AttestationFormToolService } from 'services';
import { Validators } from 'utils/helpers';

const Container = styled('div')(() => ({
  ' & > :not(:last-child)': {
    marginBottom: '1rem'
  }
}));

export const ToolFormContainer = ({
  currentStep, equipment, formContext, requestId, equipmentType
}) => {
  const formValues = formContext.getValues();
  const isNew = Validators.isCurrentToolNew(
    formValues?.equipmentView?.wasNewAtAcquisition,
    formValues?.equipmentView?.acquisitionDate
  );
  const toolType = formValues?.equipmentView?.type?.value;

  const {
    response: detentionTypes, isLoading: detentionLoading
  } = useFetch(() => AttestationFormToolService.getDetentionDocumentTypes(isNew, toolType), [], [isNew, toolType]);
  const {
    response: verificationTypes, isLoading: verificationLoading
  } = useFetch(() => AttestationFormToolService.getVerificationDocumentTypes(isNew), [], [isNew]);
  const {
    response: otherTypes, isLoading: otherTypesLoading
  } = useFetch(() => AttestationFormToolService.getSpecificDocumentTypes(toolType), [], [toolType]);

  const renderSteps = useCallback(() => {
    switch (currentStep) {
    case ToolsModalStepsEnum.Registration:
      return (
        <RegistrationStep
          equipment={equipment}
          equipmentType={equipmentType}
          formContext={formContext}
          isEdition={Boolean(equipment)}
          requestId={requestId}
          specificDocumentTypes={otherTypes}
          specificDocumentTypesLoading={otherTypesLoading}
        />
      );

    case ToolsModalStepsEnum.Possession:
      return (
        <DocumentStep
          documentTypes={detentionTypes}
          documentTypesLoading={detentionLoading}
          formContext={formContext}
        />
      );

    case ToolsModalStepsEnum.Verification:
      return (
        <DocumentStep
          documentTypes={verificationTypes}
          documentTypesLoading={verificationLoading}
          formContext={formContext}
        />
      );

    case ToolsModalStepsEnum.Summary:
      return (
        <SummaryStep
          detentionTypes={detentionTypes}
          detentionTypesLoading={detentionLoading}
          formContext={formContext}
          verificationTypes={verificationTypes}
          verificationTypesLoading={verificationLoading}
        />
      );

    default:
      return null;
    }
  }, [formContext, currentStep, otherTypesLoading, verificationLoading, detentionLoading]);

  return (
    <DialogContent style={{ width: '80vw', height: '70vh', paddingTop: '30px' }}>
      <Container>
        {ToolsModalStepsEnum.Registration !== currentStep && (
          <ModalSectionTitle title={ToolsModalStepsEnum.Tools[currentStep].title} />
        )}
        {renderSteps()}
      </Container>
    </DialogContent>
  );
};