import React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';

const SkeletonContainer = styled('div')(() => ({
  svg: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    marginLeft: '-40px',
    marginTop: '-40px'
  }
}));

const SpinnerContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const ButtonSpinner = () => {
  const theme = useTheme();

  return (
    <SpinnerContainer>
      <FontAwesomeIcon color={theme.palette.grey.main} icon={faSpinner} size="1x" spin />
    </SpinnerContainer>
  );
};

export const Spinner = ({ ...props }) => {
  const theme = useTheme();

  return (
    <SpinnerContainer {...props}>
      <FontAwesomeIcon color={theme.palette.primary.main} icon={faSpinner} size="2x" spin />
    </SpinnerContainer>
  );
};

export const SkeletonMain = () => {
  const theme = useTheme();

  return (
    <SkeletonContainer>
      <FontAwesomeIcon color={theme.palette.primary.main} icon={faSpinner} size="5x" spin />
    </SkeletonContainer>
  );
};

const SkeletonSectionContainer = styled('div')(({ theme, ...props }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  paddingTop: '2rem',
  background: props.backgroundColor || 'rgba(0, 0, 0, 0.2)',
  zIndex: theme.zIndex.big
}));

const StyledCenteredIcon = styled(FontAwesomeIcon)(() => ({
  alignSelf: 'center'
}));

export const SkeletonSection = ({ ...props }) => {
  const theme = useTheme();

  return (
    <SkeletonSectionContainer {...props}>
      <StyledCenteredIcon color={theme.palette.primary.main} icon={faSpinner} size="3x" spin />
    </SkeletonSectionContainer>
  );
};