import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

// GET
const getLabels = (application, locale) => RequestHelper.GET(API_ROUTES.TRANSLATIONS.LABELS(application, locale));

const getLanguageList = () => RequestHelper.GET(API_ROUTES.TRANSLATIONS.LANGUAGE_LIST());

export const I18nService = {
  getLabels,
  getLanguageList
};