import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Card, Grid, IconButton, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SectionEnum } from 'components/AttestationForm/attestationFormSection.enum';
import { DocumentList } from 'components/_commons/Document/DocumentList';
import StepTitle from 'components/_commons/Form/StepTitle/StepTitle';
import InfoLine from 'components/_commons/InfoLine/InfoLine';
import { Question } from 'components/_commons/Question/Question';
import { Spinner } from 'components/_commons/Skeletons';
import { TecneaTitle } from 'components/_commons/Title';
import { useModal } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { AttestationFormRequestService, DocumentService } from 'services';
import shortid from 'shortid';
import { DocumentHelper, translate } from 'utils';
import { ATTESTATION_FORM_ACTIONS, REQUEST_TYPE, ROUTES } from 'utils/constants';
import { iconEnum } from 'utils/icons';

const StepComponent = ({ children }) => (
  <Grid item width="100%">
    <Grid container direction="column" spacing={1.5} width="100%">
      {Array.isArray(children)
        ? children.map((child) => <Grid item key={shortid.generate()} width="100%">{child}</Grid>)
        : <Grid item width="100%">{children}</Grid>}
    </Grid>
  </Grid>
);

export const PurchaseOrder = observer((({
  requestId,
  availableActions,
  updateAlerts,
  setFormAlerts
}) => {
  const theme = useTheme();
  const showModal = useModal();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [attestationFormRequest, setAttestationFormRequest] = useState({});
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const [additionalFiles, setAdditionalFiles] = useState([]);
  const [groupContract, setGroupContract] = useState();
  const [contractReference, setContractReference] = useState('');
  const allowedEditPurchaseOrder = availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_PURCHASE_ORDER);
  const canUpdateSituation = availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_UPDATE_SITUATION);
  const [purchaseOrderValidated, setPurchaseOrderValidated] = useState(true);
  const canEditPurchaseOrder = allowedEditPurchaseOrder && !purchaseOrderValidated;
  const canEditInstitution = canUpdateSituation
      || availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_SUPERVISE);
  const canEdit = availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_FORM);

  const loadOrderFormTab = useCallback(
    () => {
      setIsLoading(true);
      AttestationFormRequestService.getDetails(requestId)
        .then((resp) => {
          setGroupContract(resp.isGroupContract ?? false);
          setPurchaseOrderValidated(resp.purchaseOrderValidated);
          setAttestationFormRequest(resp);
          setContractReference(resp.contractReference || '');
          setPurchaseOrder(resp.documents.find((document) => (document.type.value === 'ORDER_FORM')) || {});
          setAdditionalFiles(resp.documents.filter((document) => (document.type.value === 'ORDER_FORM_COMPLEMENT')) || []);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [updateAlerts, requestId, setPurchaseOrderValidated, allowedEditPurchaseOrder]
  );

  useEffect(() => {
    loadOrderFormTab();
    if (availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_RESOLVE_ALERT_AS_OPERATOR)) {
      updateAlerts(SectionEnum.PurchaseOrder);
    } else {
      AttestationFormRequestService.validateAllSections(requestId).then(() => {
        updateAlerts(SectionEnum.PurchaseOrder);
      });
    }
  }, []);

  const handleGroupContractChange = useCallback((event, value) => showModal({
    type: 'CONFIRM',
    title: 'attestationFormRequest.actions.changeGroupContract',
    content: translate('attestationFormRequest.confirmation.changeGroupContract'),
    onConfirm: () => {
      if (value != null) {
        setGroupContract(value);
        AttestationFormRequestService.updateContractType(requestId, value)
          .then(loadOrderFormTab)
          .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
      }
    },
    confirmLabel: 'button.confirm',
    closeOnSubmit: true
  }), [showModal, enqueueSnackbar]);

  const handleAddAdditionalFile = (doc) => {
    if (doc) {
      AttestationFormRequestService.saveDocument(
        requestId,
        {
          name: doc.name,
          base64Content: DocumentHelper.getDocumentWithoutBase64(doc)
        },
        'ORDER_FORM_COMPLEMENT'
      )
        .then((resp) => setAdditionalFiles(
          [...additionalFiles,
            {
              document: {
                ...doc,
                base64Content: DocumentHelper.getDocumentWithoutBase64(doc)
              },
              documentId: resp.documentId,
              id: resp.id
            }]
        ))
        .then(() => enqueueSnackbar(translate('attestationFormRequest.purchaseOrder.documentSaved'), { variant: 'success' }));
    }
  };

  const deleteAdditionalFiles = useCallback((file) => showModal({
    type: 'WARNING',
    onConfirm: () => {
      if (file.id) {
        DocumentService.deleteAttestationFormDocument(file.id)
          .then(() => setAdditionalFiles(additionalFiles.filter((additionalFile) => additionalFile.id !== file.id)))
          .finally(() => {
            enqueueSnackbar(translate('attestationFormRequest.purchaseOrder.documentDeleted'), { variant: 'success' });
          });
      }
    }
  }), [additionalFiles, setAdditionalFiles, enqueueSnackbar]);

  const handleAddPurchaseOrder = (doc) => {
    if (doc) {
      AttestationFormRequestService.saveDocument(
        requestId,
        {
          name: doc.name,
          base64Content: DocumentHelper.getDocumentWithoutBase64(doc)
        },
        'ORDER_FORM'
      )
        .then((resp) => {
          setPurchaseOrder({
            document: {
              ...doc,
              base64Content: DocumentHelper.getDocumentWithoutBase64(doc)
            },
            documentId: resp.documentId,
            id: resp.id
          });
          updateAlerts(SectionEnum.PurchaseOrder);
        })
        .finally(() => enqueueSnackbar(translate('attestationFormRequest.purchaseOrder.documentSaved'), { variant: 'success' }));
    }
  };

  const deletePurchaseOrder = useCallback((file) => showModal({
    type: 'WARNING',
    onConfirm: () => {
      if (file.id) {
        DocumentService.deleteAttestationFormDocument(file.id)
          .then(() => {
            setPurchaseOrder({});
            updateAlerts(SectionEnum.PurchaseOrder);
          })
          .then(() => {
            enqueueSnackbar(translate('attestationFormRequest.purchaseOrder.documentDeleted'), { variant: 'success' });
          });
      }
    }
  }), [setPurchaseOrder, purchaseOrder, updateAlerts]);

  const downloadPurchaseOrderTemplate = useCallback(() => {
    AttestationFormRequestService.getPurchaseOrderTemplate(requestId);
  }, []);

  const updateAttestationCategories = (attestationCategories) => {
    AttestationFormRequestService.updateRequestCategories(requestId, attestationCategories)
      .then(() => {
        setAttestationFormRequest({
          ...attestationFormRequest,
          categories: attestationCategories
        });
        AttestationFormRequestService.validateCategories(requestId)
          .then((alerts) => setFormAlerts(alerts))
          .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
      });
  };

  const invalidatePurchaseOrder = useCallback(() => (
    AttestationFormRequestService.invalidatePurchaseOrder(requestId)
      .then((resp) => {
        if (attestationFormRequest.type === REQUEST_TYPE.UPDATE) {
          navigate(generatePath(ROUTES.ATTESTATION_FORM, { requestId: resp.id }));
          navigate(0);
        }
        AttestationFormRequestService.validateAllSections(requestId).then(() => {
          loadOrderFormTab();
          enqueueSnackbar(translate('attestationFormRequest.snackbar.situationUpdated'), { variant: 'success' });
        });
      })
      .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }))
  ), [loadOrderFormTab, navigate, requestId, enqueueSnackbar, attestationFormRequest]);

  const updateSituation = useCallback((institutionView) => (
    AttestationFormRequestService.updateSituationInstitution(requestId, institutionView)
      .then((resp) => {
        if (attestationFormRequest.type === REQUEST_TYPE.UPDATE) {
          navigate(generatePath(ROUTES.ATTESTATION_FORM, { requestId: resp.id }));
          navigate(0);
        }
        setAttestationFormRequest(resp);
        AttestationFormRequestService.validateAllSections(requestId).then(() => {
          loadOrderFormTab();
          enqueueSnackbar(translate('attestationFormRequest.snackbar.situationUpdated'), { variant: 'success' });
        });
      })
      .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }))
  ), [setAttestationFormRequest, enqueueSnackbar, attestationFormRequest]);

  const onUpdateSituation = useCallback(() => showModal({
    type: 'CREATE_INSTITUTION',
    onConfirm: (resp) => updateSituation({ categories: resp.attestationCategories, institutionView: resp }),
    defaultValues: {
      ...attestationFormRequest?.institutionInstance,
      attestationCategories: attestationFormRequest?.categories
    },
    withCategories: true,
    isUpdatingSituation: canUpdateSituation
  }), [showModal, attestationFormRequest, updateAttestationCategories, updateSituation]);

  const updateValidatedPurchaseOrder = useCallback(() => {
    showModal({
      type: 'WARNING',
      onConfirm: invalidatePurchaseOrder,
      title: translate('attestationFormRequest.actions.updateValidatedPurchaseOrder'),
      text: translate('attestationFormRequest.confirmation.updateValidatedPurchaseOrder')
    });
  }, [invalidatePurchaseOrder, showModal]);

  const renderInfoLineItem = useCallback((info, label) => (info) && (
    <Grid item>
      <InfoLine
        info={info}
        label={label}
      />
    </Grid>
  ), []);

  const renderDocumentList = useCallback((documents, onDeleteDocument, onImportDocument, { ...props } = {}) => (
    <DocumentList
      canEdit={canEditPurchaseOrder}
      documents={documents}
      importLabel="button.import"
      spacing={2.5}
      titleStyles={{
        textAlign: 'center',
        variant: 'h6'
      }}
      onDelete={onDeleteDocument}
      onImport={onImportDocument}
      {...props}
    />
  ), [canEditPurchaseOrder]);

  const submitReferenceContract = useCallback(() => {
    AttestationFormRequestService.updateGroupContractReference(requestId, contractReference)
      .then(() => {
        loadOrderFormTab();
        enqueueSnackbar(translate('attestationFormRequest.snackbar.contractReferenceUpdated'), { variant: 'success' });
      })
      .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
  }, [contractReference, enqueueSnackbar]);

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item md={6} xs={8}>
        <Grid container direction="column" height="100%" spacing={2.5} wrap="nowrap">
          <Grid item>
            <Grid alignItems="center" container direction="row">
              <Grid item>
                <TecneaTitle label={translate('attestationFormRequest.purchaseOrder.institutionDetails')} />
              </Grid>
              {canEditInstitution && (
                <Grid item marginLeft={2}>
                  <Tooltip title={translate('attestationFormRequest.tooltip.situationUpdate')}>
                    <IconButton color="primary" onClick={onUpdateSituation}>
                      <FontAwesomeIcon icon={iconEnum.pen.icon} size="lg" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
          {isLoading && !attestationFormRequest?.institutionInstance ? <Spinner style={{ height: '100%' }} />
            : (
              <>
                {renderInfoLineItem(
                  attestationFormRequest?.institutionInstance?.name,
                  'attestationFormRequest.purchaseOrder.institutionName'
                )}
                {
                  attestationFormRequest?.institutionInstance?.identifiers?.map((identifier) => (
                    <Grid item key={identifier.idValue}>
                      <InfoLine
                        info={identifier.value}
                        key={identifier.idValue}
                        label={identifier.name}
                        withTranslation={false}
                      />
                    </Grid>
                  ))
                }
                {renderInfoLineItem(
                  attestationFormRequest?.institutionInstance?.attestationNumber,
                  'attestationFormRequest.purchaseOrder.certificateNumber'
                )}
                <Grid item>
                  <Grid alignContent="center" container direction="row">
                    <InfoLine
                      info={(attestationFormRequest?.categories ?? []).join(', ')}
                      label="attestationFormRequest.purchaseOrder.categories"
                    />
                  </Grid>
                </Grid>
                {renderInfoLineItem(
                  attestationFormRequest?.institutionInstance?.address?.address2,
                  'attestationFormRequest.purchaseOrder.entrance'
                )}
                {renderInfoLineItem(
                  attestationFormRequest?.institutionInstance?.address?.address1,
                  'attestationFormRequest.purchaseOrder.streetNumber'
                )}
                {renderInfoLineItem(
                  attestationFormRequest?.institutionInstance?.address?.postalCode,
                  'attestationFormRequest.purchaseOrder.zipCode'
                )}
                {renderInfoLineItem(
                  attestationFormRequest?.institutionInstance?.address?.city,
                  'attestationFormRequest.purchaseOrder.town'
                )}
                {renderInfoLineItem(
                  attestationFormRequest?.operatorsCount?.toString(),
                  'common.operatorsCount'
                )}
              </>
            )}
        </Grid>
      </Grid>
      <Grid item md={6} xs={8}>
        <Grid container direction="column" spacing={2.5}>
          <Grid item>
            <TecneaTitle label={translate('attestationFormRequest.purchaseOrder.importPurchaseOrder')} />
          </Grid>
          <Grid item>
            {canEdit
              ? (
                <Question label={translate('attestationFormRequest.purchaseOrder.groupContractQuestion')}>
                  <ToggleButtonGroup
                    aria-label="contract"
                    disabled={!allowedEditPurchaseOrder}
                    exclusive
                    name="contract"
                    size="medium"
                    style={{ maxHeight: 40 }}
                    value={groupContract}
                    onChange={handleGroupContractChange}
                  >
                    <ToggleButton
                      aria-label={translate('common.yes')}
                      key={shortid.generate()}
                      /* eslint-disable-next-line react/jsx-boolean-value */
                      value={true}
                    >
                      {translate('common.yes')}
                    </ToggleButton>
                    <ToggleButton
                      aria-label={translate('common.no')}
                      key={shortid.generate()}
                      value={false}
                    >
                      {translate('common.no')}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Question>
              )
              : (
                <Typography variant="h5">
                  {groupContract
                    ? translate('attestationFormRequest.purchaseOrder.isGroupContract')
                    : translate('attestationFormRequest.purchaseOrder.noGroupContract')}
                </Typography>
              )}
          </Grid>
          <Grid item width="100%">
            {groupContract
              ? (
                <Card style={{ padding: 20, borderWidth: 2 }} variant="outlined">
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                          <Typography>{translate('attestationFormRequest.purchaseOrder.uploadPurchaseOrder')}</Typography>
                        </Grid>
                        <Grid item marginLeft={2}>
                          {allowedEditPurchaseOrder
                                    && !canEditPurchaseOrder
                                    && (
                                      <Tooltip title={translate('attestationFormRequest.tooltip.situationUpdate')}>
                                        <IconButton color="primary" onClick={updateValidatedPurchaseOrder}>
                                          <FontAwesomeIcon icon={iconEnum.pen.icon} size="lg" />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item width="100%">
                      {renderDocumentList(
                        (Object.keys(purchaseOrder).length ? [purchaseOrder] : []),
                        deletePurchaseOrder,
                        handleAddPurchaseOrder,
                        { sizeLimit: 1 }
                      )}
                    </Grid>
                    <Grid item>
                      <Typography>{translate('attestationFormRequest.purchaseOrder.writeContractReference')}</Typography>
                    </Grid>
                    <Grid alignItems="center" container item spacing={2}>
                      <Grid item>
                        <TextField
                          disabled={!canEditPurchaseOrder}
                          label={translate('attestationFormRequest.purchaseOrder.contractReference')}
                          value={contractReference}
                          variant="outlined"
                          onChange={(e) => setContractReference(e.target.value)}
                        />
                      </Grid>
                      {canEditPurchaseOrder
                        && (
                          <Grid item>
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={submitReferenceContract}
                            >
                              <Typography noWrap>{translate('button.submit')}</Typography>
                            </Button>
                          </Grid>
                        )}
                    </Grid>

                  </Grid>
                </Card>
              ) : (
                <Card style={{ padding: 20, borderWidth: 2 }} variant="outlined">
                  <Grid container direction="column" spacing={3}>
                    <StepComponent>
                      <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                          <StepTitle number={1} text="attestationFormRequest.purchaseOrder.downloadEmptyOrder" />
                        </Grid>
                        <Grid item marginLeft={2}>
                          {allowedEditPurchaseOrder
                              && !canEditPurchaseOrder
                                && (
                                  <Tooltip title={translate('attestationFormRequest.tooltip.situationUpdate')}>
                                    <IconButton color="primary" onClick={updateValidatedPurchaseOrder}>
                                      <FontAwesomeIcon icon={iconEnum.pen.icon} size="lg" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                        </Grid>
                      </Grid>
                      <Button
                        color="primary"
                        startIcon={<FontAwesomeIcon color={theme.palette.primary.contrastText} icon={iconEnum.download.icon} />}
                        variant="contained"
                        onClick={downloadPurchaseOrderTemplate}
                      >
                        <Typography noWrap>{translate('button.download')}</Typography>
                      </Button>
                    </StepComponent>
                    <StepComponent>
                      <StepTitle number={2} text="attestationFormRequest.purchaseOrder.uploadPurchaseOrder" />
                      {renderDocumentList(
                        (Object.keys(purchaseOrder).length ? [purchaseOrder] : []),
                        deletePurchaseOrder,
                        handleAddPurchaseOrder,
                        { sizeLimit: 1 }
                      )}
                    </StepComponent>
                    <StepComponent>
                      <StepTitle number={3} text="attestationFormRequest.purchaseOrder.uploadAdditionalFiles" />
                      {renderDocumentList(
                        additionalFiles,
                        deleteAdditionalFiles,
                        handleAddAdditionalFile,
                        { variant: 'outlined' }
                      )}
                    </StepComponent>
                  </Grid>
                </Card>
              )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}));

PurchaseOrder.propTypes = {
  requestId: PropTypes.string.isRequired
};

PurchaseOrder.defaultProps = {};