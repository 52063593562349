/* ***
** Usage

const Component = () => {
  const hasAccessRights = useAccessRights({ roles: [APPLICATION_ROLES.ADMIN] });
};

*/

import { useStores } from 'hooks';
import { UserHelper } from 'utils';

const useAccessRights = ({
  roles = [], checkOffline = false
} = {}) => {
  const { userStore } = useStores();
  const { isOffline } = userStore;

  if (checkOffline && isOffline) {
    return true;
  }

  return (UserHelper.hasAdminAccessRights() || (UserHelper.hasAccessRight(roles)));
};

export default useAccessRights;