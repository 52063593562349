import React, { useCallback } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { translate } from 'utils';
import { ListItem } from '@mui/material';
import { FormElement } from 'components/_commons/Form/FormElement/FormElement';

export const StyledListItem = styled(ListItem)(() => ({
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: '#DEEBFF'
  }
}));

export const MultiValueRemove = (removeProps) => {
  const clearableValue = removeProps?.data?.clearableValue;
  return clearableValue && (
    <components.MultiValueRemove {...removeProps} />
  );
};

export const StyledRequiredSelectHack = styled('input')(() => ({
  height: '1px',
  width: '100%',
  margin: 0,
  padding: 0,
  border: 'none',
  opacity: 0,
  cursor: 'default'
}));

const SelectField = ({
  name, options, value, label, onChange, required, noMargin, isDisabled,
  placeholder, isClearable, closeMenuOnSelect, messageNoOptions,
  height, width, ...props
}) => {
  const displayNoOptions = useCallback(() => translate(messageNoOptions), [messageNoOptions]);

  const customStyles = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 2000
    }),
    control: (provided) => ({
      ...provided,
      height,
      width
    })
  };

  return (
    <FormElement
      label={label ? translate(label) : label}
      noMargin={noMargin}
      required={required}
      shrinked
    >
      <Select
        closeMenuOnSelect={closeMenuOnSelect}
        components={{ MultiValueRemove }}
        isClearable={isClearable}
        isDisabled={isDisabled}
        menuPortalTarget={document.body}
        name={name}
        noOptionsMessage={displayNoOptions}
        options={options}
        placeholder={translate(placeholder)}
        required={required}
        styles={customStyles}
        value={value}
        onChange={onChange}
        {...props}
      />
      {required && (
        <StyledRequiredSelectHack
          autoComplete="off"
          defaultValue={value || ''}
          required
          tabIndex={-1}
        />
      )}
    </FormElement>
  );
};

SelectField.propTypes = {
  closeMenuOnSelect: PropTypes.bool,
  label: PropTypes.string,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  messageNoOptions: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  value: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({}))
  ]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool
};

SelectField.defaultProps = {
  closeMenuOnSelect: true,
  isClearable: true,
  isDisabled: false,
  label: undefined,
  messageNoOptions: 'warnings.noOptionsAvailable',
  options: [],
  placeholder: 'common.selectOption',
  required: false,
  value: null
};

export default SelectField;