import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import shortid from 'shortid';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { iconEnum } from 'utils/icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { faCircle as regularCircle } from '@fortawesome/pro-regular-svg-icons';

export const StepperEyeIcon = ({ size = 'xl', current }) => {
  const theme = useTheme();
  return (
    <span className={`fa-layers fa-fw fa-${size}`}>
      <FontAwesomeIcon
        color={theme.palette.warning.main}
        icon={current ? regularCircle : faCircle}
        key={shortid.generate()}
      />
      <FontAwesomeIcon
        color={current ? theme.palette.warning.main : theme.palette.common.white}
        icon={iconEnum.eye.icon}
        key={shortid.generate()}
        transform="shrink-7"
      />
    </span>
  );
};