// Docs : https://geo.api.gouv.fr/adresse
import React, {
  Fragment, useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Chip, ClickAwayListener, Divider, Grid,
  IconButton, InputAdornment, TextField, Typography
} from '@mui/material';
import { FormHelper } from 'utils/helpers';
import { AddressService } from 'services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAltSlash, faSearch, faSpinner, faTimes
} from '@fortawesome/pro-regular-svg-icons';
import { translate } from 'utils';
import { FormLine } from 'components/_commons/Form/FormElement/FormElement';
import { FormInputField } from 'components/_commons/Form/Inputs';

const AutocompleteContainer = styled('section')(() => ({
  position: 'relative',
  zIndex: '1'
}));

const AddressList = styled('ul')(({ theme }) => ({
  position: 'absolute',
  top: '56px',
  width: '100%',
  maxHeight: '250px',
  margin: '0',
  padding: '0',
  background: theme.palette.common.white,
  border: `1px solid${theme.palette.grey.lighter}`,
  overflow: 'auto',
  zIndex: '-1'
}));

const AddressItem = styled('li')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  transition: 'all 0.3s ease-in',
  padding: '1rem',
  cursor: 'pointer',
  '&:hover, &:focus': {
    backgroundColor: theme.palette.grey.lighter
  }
}));

const ENTER_KEYCODE = 'Enter';

const FormAddress = ({
  formContext, disabled
}) => {
  const [query, setQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [addressListIsVisible, setAddressListIsVisible] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const debouncedQuery = FormHelper.useDebounce(query, 500);
  const { watch, setValue } = formContext;

  useEffect(() => {
    const getAddressList = async () => {
      if (debouncedQuery) {
        setIsSearching(true);
        const country = (watch('country')?.countryCode) ?? '';

        try {
          const results = await AddressService.getAddressList(debouncedQuery, country)
            .then((response) => response.features);
          setAddressList(results);
          setAddressListIsVisible(true);
          setIsSearching(false);
        } catch {
          setIsSearching(false);
        }
      } else {
        setAddressList([]);
      }
    };

    getAddressList();
  }, [debouncedQuery, watch]);

  useEffect(() => {
    if (selectedAddress && selectedAddress.city) {
      setValue('address1', selectedAddress.address1);
      setValue('postalCode', selectedAddress.postcode);
      setValue('city', selectedAddress.city);
      setAddressListIsVisible(false);
    }
    // eslint-disable-next-line
    }, [selectedAddress]);

  const handleChangeQuery = useCallback((event) => setQuery(event.target.value), []);

  const handleClearQuery = useCallback(() => {
    setQuery('');
    setAddressList([]);
    setIsSearching(false);
  }, []);

  const handleShowAddressList = useCallback(() => setAddressListIsVisible(true), []);

  const handleHideAddressList = useCallback(() => setAddressListIsVisible(false), []);

  const handleSelectAddress = useCallback((address) => {
    const postcode = address.context.find((ctx) => ctx.id.includes('postcode'));
    const city = address.context.find((ctx) => ctx.id.includes('place'));
    const country = address.context.find((ctx) => ctx.id.includes('country'));

    const addressFormatted = {
      address1: address.text,
      postcode: postcode ? postcode.text : '',
      city: city ? city.text : '',
      country: country ? country.text : ''
    };

    setSelectedAddress(addressFormatted);
    handleHideAddressList();
  }, [handleHideAddressList]);

  return (
    <>
      <Typography margin={1} marginBottom={3} variant="h6">
        {translate('common.address')}
      </Typography>

      {!disabled && (
        <ClickAwayListener onClickAway={handleHideAddressList}>
          <AutocompleteContainer>
            <TextField
              autoComplete="off"
              fullWidth
              InputProps={{
                endAdornment: query !== '' && (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={handleClearQuery}>
                      <FontAwesomeIcon color="var(--primary-color)" icon={faTimes} size="xs" />
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    {isSearching
                      ? <FontAwesomeIcon icon={faSpinner} spin />
                      : <FontAwesomeIcon color="var(--primary-color)" icon={faSearch} />}
                  </InputAdornment>
                )
              }}
              label={translate('common.addressAutocomplete')}
              value={query}
              variant="outlined"
              onChange={handleChangeQuery}
              onFocus={handleShowAddressList}
            />
            {addressListIsVisible && (
              <AddressList>
                {addressList.length > 0 && addressList.map((address) => (
                  <Fragment key={address.id}>
                    <AddressItem
                      tabIndex={0}
                      onClick={() => handleSelectAddress(address)}
                      onKeyDown={(e) => e.key === ENTER_KEYCODE && handleSelectAddress(address)}
                    >
                      <div style={{ minWidth: '120px' }}>
                        <Chip label={translate(`common.${address.place_type[0]}`)} />
                      </div>
                      <div>
                        <Typography style={{ fontWeight: 600 }}>{address.place_name}</Typography>
                      </div>
                    </AddressItem>
                    <Divider />
                  </Fragment>
                ))}
                {addressList.length === 0 && debouncedQuery && !isSearching && (
                  <Typography align="center" style={{ padding: '2rem' }}>
                    <FontAwesomeIcon icon={faMapMarkerAltSlash} size="2x" />
                    <br />
                    {translate('errors.noAddressList')}
                  </Typography>
                )}
              </AddressList>
            )}
          </AutocompleteContainer>
        </ClickAwayListener>
      )}
      <FormLine>
        <Grid container spacing={2}>
          <Grid item marginTop={2} sm={6} xs={12}>
            <FormInputField
              autoComplete="off"
              disabled={disabled}
              label={translate('common.address')}
              name="address1"
              required
            />
          </Grid>
          <Grid item marginTop={2} sm={6} xs={12}>
            <FormInputField
              autoComplete="off"
              disabled={disabled}
              label={translate('common.address2')}
              name="address2"
            />
          </Grid>
        </Grid>
      </FormLine>
      <FormLine>
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item marginTop={2} sm={4} xs={12}>
                <FormInputField
                  autoComplete="off"
                  disabled={disabled}
                  label={translate('common.postalCode')}
                  name="postalCode"
                  required
                />
              </Grid>
              <Grid item marginTop={2} sm={8} xs={12}>
                <FormInputField
                  autoComplete="off"
                  disabled={disabled}
                  label={translate('common.city')}
                  name="city"
                  required
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormLine>
    </>
  );
};

FormAddress.propTypes = {
  formContext: PropTypes.shape({}).isRequired
};

export default FormAddress;