import { Divider, Grid, Typography } from '@mui/material';
import { Document } from 'components/_commons/Document/Document';
import { TecneaTitle } from 'components/_commons/Title';
import React from 'react';
import { isTablet, translate } from 'utils';
import { DateHelper, DocumentHelper } from 'utils/helpers';

export const DocumentAssignationModalSummary = ({ formContext }) => {
  const document = formContext.getValues('document');
  const documentType = formContext.getValues('documentType');
  const documentDate = formContext.getValues('documentDate');
  const tools = formContext.getValues('tools');

  return (
    <Grid container direction="column" padding={3} spacing={isTablet() ? 2 : 4}>
      <Grid item>
        <TecneaTitle label={translate('attestationFormRequest.assignationModal.summary.title')} />
      </Grid>
      <Grid item>
        <Typography variant="h5">
          {documentType.label}
        </Typography>
        <br />
        <Grid container>
          <Grid item lg={4} xs={12}>
            <Document
              base64Content={DocumentHelper.getDocumentWithBase64Typing(document)}
              document={document}
              name={document.name}
            />
          </Grid>
          <Grid item lg={8} xs={12}>
            <Typography>
              {`${translate('attestationFormRequest.assignationModal.summary.dateAt')} ${DateHelper.formatDate(documentDate)}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h5">
          {translate('attestationFormRequest.assignationModal.summary.proofForTools')}
        </Typography>
        <br />
        {tools.map((tool) => (
          <Grid container key={tool?.equipmentView?.identifier}>
            <Grid item lg={3} xs={4}>
              <Typography>{tool?.equipmentView?.type?.label}</Typography>
            </Grid>
            <Grid item lg={3} xs={4}>
              <Typography>{tool?.equipmentView?.brand}</Typography>
              <Typography>{tool?.equipmentView?.model}</Typography>
              <Typography>{tool?.equipmentView?.serialNumber}</Typography>
            </Grid>
            <Grid item lg={3} xs={4}>
              <Typography>
                {DateHelper.formatDate(tool?.equipmentView?.acquisitionDate)}
              </Typography>
              <Typography>
                {translate(tool?.equipmentView?.isNew ? 'common.newTool' : 'common.notNewTool')}
              </Typography>
            </Grid>
            <Grid item lg={9} xs={12}>
              <Divider style={{ margin: '8px' }} />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};