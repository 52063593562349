import { FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
import { InfoTooltip } from 'components/_commons/InfoTooltip';
import { Text } from 'components/_commons/Text';
import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'utils';

const StyledQuestion = styled('article')(() => ({
  marginTop: '1.5rem',

  '&:first-of-type': {
    marginTop: 0
  },

  '&:last-of-type': {
    marginBottom: 0
  },

  '.MuiFormControl-root': {
    display: 'flex',
    marginBottom: '0.5rem',
    zIndex: 'initial',

    '&.MuiTextField-root': {
      flex: 1
    }
  }
}));

export const Question = ({
  label, color, icon, tooltip, children, required = false, fontWeight
}) => (
  <StyledQuestion>
    <FormControl>
      <Text color={color} fontSize="1.6rem" fontWeight={fontWeight} margin="0 0 1rem 0">
        {label}
        {required && (<span style={{ color: 'red' }}>*</span>)}
        {icon}
        {tooltip && <InfoTooltip text={translate(tooltip)} />}
      </Text>
      {children}
    </FormControl>
  </StyledQuestion>
);

Question.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  fontWeight: PropTypes.number
};

Question.defaultProps = {
  color: '',
  fontWeight: 500,
  icon: null,
  required: false,
  tooltip: ''
};