import React from 'react';

import { Grid, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { DocumentHelper, translate } from 'utils';

import { faClipboardCheck, faEmptySet } from '@fortawesome/pro-solid-svg-icons';
import { justifications } from 'components/AttestationForm';
import { SectionEnum } from 'components/AttestationForm/attestationFormSection.enum';
import { DocumentDisplay } from 'components/_commons/Document/DocumentDisplay';
import { CheckboxGroup, FormTextareaField } from 'components/_commons/Form/Inputs';
import FormDatePicker from 'components/_commons/Form/Inputs/Datepicker/FormDatePicker';
import RadioButtonGroupFixed from 'components/_commons/Form/Inputs/RadioButton/RadioButtonGroupFixed';
import { InvalidDocumentIcon } from 'components/_commons/Icon/InvalidDocumentIcon';
import { SingleSelect } from 'components/_commons/Modals/common/SingleSelect';
import { WithLeftIconText } from 'components/_commons/Text';
import { TecneaTitle } from 'components/_commons/Title';
import { VALIDATION_STATUS } from 'utils/constants';
import { DateHelper } from 'utils/helpers';
import { Spinner } from '../../Skeletons';

const CustomGrid = styled(Grid)(() => ({
  padding: '2rem',
  ' & > :not(:last-child)': {
    paddingBottom: '3rem'
  }
}));

export const ClassifyDocumentFormContainer = ({
  fileData, categories, documentTypes, loadingOptions, allowedSections, onChangeLinkedEntity, loadingDocumentTypes,
  allowedSectionsLoading, formContext, options, canEdit = true, canValidate, loadingDocumentContent = false
}) => {
  const theme = useTheme();
  const sectionTitle = () => {
    switch (formContext.watch('section')?.value) {
    case SectionEnum.Operators:
      return 'attestationFormRequest.classifyModal.selectOperator';

    case SectionEnum.Tooling:
      return 'attestationFormRequest.classifyModal.selectTools';

    case SectionEnum.Tracability:
      return 'attestationFormRequest.classifyModal.selectTracability';

    default:
      return '';
    }
  };

  return (
    <Grid container height="100%">
      <Grid item md={5} xs={7}>
        {fileData?.base64Content ? (
          <DocumentDisplay
            base64Content={DocumentHelper.getDocumentWithBase64Typing(fileData)}
            documentName={fileData.name}
            downloadLabel={translate('common.documentTooBig')}
            height="100%"
            isLoadingContent={loadingDocumentContent}
            width="100%"
          />
        ) : <Spinner style={{ width: '100%', minHeight: '100%' }} />}
      </Grid>
      <CustomGrid item md={7} xs={5}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TecneaTitle label={translate('attestationFormRequest.classifyModal.title')} />
          </Grid>
          <Grid item xs={12}>
            <SingleSelect
              disabled={allowedSectionsLoading}
              isLoading={allowedSectionsLoading}
              key={0}
              label={translate('attestationFormRequest.classifyModal.selectedSection')}
              name="section"
              options={allowedSections}
              required
              onChange={() => formContext.resetField('entity')}
            />
          </Grid>
        </Grid>
        {formContext.watch('section')?.value && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TecneaTitle label={translate(sectionTitle())} />
            </Grid>
            <Grid item xs={12}>
              <SingleSelect
                disabled={!canEdit}
                isLoading={loadingOptions}
                key={0}
                label={translate(sectionTitle())}
                name="entity"
                options={options}
                required
                onChange={onChangeLinkedEntity}
              />
            </Grid>
            {formContext.watch('section')?.value === SectionEnum.Tooling && (
              <>
                <Grid item xs={12}>
                  <TecneaTitle label={translate('attestationFormRequest.classifyModal.information')} />
                </Grid>
                <Grid item xs={12}>
                  <SingleSelect
                    disabled={!canEdit}
                    isLoading={loadingOptions || loadingDocumentTypes}
                    label={translate('attestationFormRequest.verificationModal.proofType')}
                    name="documentType"
                    options={documentTypes ?? []}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormDatePicker
                    disabled={!canEdit}
                    disableFuture
                    label="common.documentDate"
                    name="documentDate"
                    required
                    validate={(value) => DateHelper.validateIfDateIsValidAndInThePast(value)}
                    value={formContext.watch('documentDate')}
                  />
                </Grid>
              </>
            )}
          </Grid>
        )}
        {formContext.watch('section')?.value === SectionEnum.Operators && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TecneaTitle label={translate('attestationFormRequest.classifyModal.information')} />
            </Grid>
            <Grid item xs={12}>
              <SingleSelect
                disabled={!canEdit}
                label={translate('attestationFormRequest.verificationModal.proofType')}
                name="documentType"
                options={documentTypes ?? []}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormDatePicker
                disabled={!canEdit}
                disableFuture
                label="common.documentDate"
                name="documentDate"
                required
                validate={(value) => DateHelper.validateIfDateIsValidAndInThePast(value)}
                value={formContext.watch('documentDate')}
              />
            </Grid>
            {
              formContext.watch('documentType')?.value === justifications.APTITUDE_ATTESTATION && (
                <Grid item xs={12}>
                  <CheckboxGroup
                    disabled={!canEdit}
                    name="attestationCategories"
                    options={categories ?? []}
                    required
                    row
                  />
                </Grid>
              )
            }
          </Grid>
        )}
        {canValidate && (
          <>
            <Grid item md={12}>
              <TecneaTitle label={translate('attestationFormRequest.verificationModal.validation')} />
            </Grid>
            <Grid item md={12}>
              <Typography
                color={theme.palette.warning.main}
                paddingLeft="2rem"
              >
                {translate('warnings.documentValidation.comment')}
              </Typography>
            </Grid>
            <Grid item md={12}>
              <RadioButtonGroupFixed
                controlledValue={formContext.watch('document.validation.validationStatus')}
                disabled={!canValidate}
                name="document.validation.validationStatus"
                options={[
                  {
                    label: <WithLeftIconText
                      color={theme.palette.success.main}
                      icon={faClipboardCheck}
                      label={translate('print.enum.DocumentValidationStatus.VALID')}
                      spacing={1}
                    />,
                    value: VALIDATION_STATUS.VALID
                  },
                  {
                    label: <WithLeftIconText
                      customIcon={<InvalidDocumentIcon size="2x" />}
                      label={translate('print.enum.DocumentValidationStatus.RELEVANT_INVALID')}
                      spacing={1}
                    />,
                    value: VALIDATION_STATUS.RELEVANT_INVALID
                  },
                  {
                    label: <WithLeftIconText
                      color={theme.palette.grey.main}
                      icon={faEmptySet}
                      label={translate('print.enum.DocumentValidationStatus.INVALID')}
                      spacing={1}
                    />,
                    value: VALIDATION_STATUS.INVALID
                  }
                ]}
                valueKey="value"
              />
            </Grid>
            <Grid item md={12}>
              <FormTextareaField
                fullWidth
                label={translate('common.documentValidation.comment')}
                name="document.validation.comment"
              />
            </Grid>
          </>
        )}
      </CustomGrid>
    </Grid>
  );
};