import { FormContainer, useForm } from 'react-hook-form-mui';
import React, { useCallback, useState } from 'react';
import {
  Button, DialogActions, DialogContent, Grid, Typography
} from '@mui/material';
import { DocumentHelper, isTablet, translate } from 'utils';

import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { ModalFormField } from 'components/_commons/Modals/_ModalFormField';
import { FormInputField } from 'components/_commons/Form/Inputs';
import { DocumentList } from 'components/_commons/Document/DocumentList';

export const ResolveAlertModal = ({
  onClose,
  currentAlert,
  onOperatorResolve,
  onInstructorResolve,
  onCloseAlert,
  onReopenAlert
}) => {
  const formContext = useForm();
  const { watch, trigger } = formContext;
  const [documents, setDocuments] = useState(currentAlert?.documentList?.documents
    ? currentAlert.documentList.documents.map((document) => ({ document }))
    : []);

  const importDocument = useCallback((file) => {
    if (file) {
      const documentView = {
        document: { ...file, base64Content: DocumentHelper.getDocumentWithoutBase64(file) },
        file
      };
      setDocuments([...documents, documentView]);
    }
  }, [documents]);

  const deleteDocument = useCallback((file) => {
    setDocuments(documents.filter((doc) => doc !== file));
  }, [documents]);

  const getSubmittedAlert = useCallback(() => {
    const submittedAlert = { ...currentAlert };
    watch('resolvingAction') && (submittedAlert.resolvingAction = watch('resolvingAction'));
    watch('evaluationComment') && (submittedAlert.evaluationComment = watch('evaluationComment'));
    submittedAlert.documentList = { documents: documents.map((doc) => ({ ...doc.document })) };
    return submittedAlert;
  }, [currentAlert, documents, watch]);

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{ autoComplete: 'off', name: 'resolveAlertForm' }}
    >
      <ModalHeader onClose={onClose}>
        {translate('attestationFormRequest.alerts.modalResolve.title')}
      </ModalHeader>

      <DialogContent style={{ width: isTablet() ? '75vw' : '50vw', marginTop: '30px' }}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <ModalFormField title={translate('attestationFormRequest.alerts.modalResolve.descriptionTitle')}>
              <Typography>
                {currentAlert.description}
              </Typography>
            </ModalFormField>
          </Grid>
          {currentAlert.actionPlan && (
            <Grid item>
              <ModalFormField title={translate('attestationFormRequest.alerts.modalResolve.actionPlanTitle')}>
                <Typography>
                  {currentAlert.actionPlan}
                </Typography>
              </ModalFormField>
            </Grid>
          )}
          {currentAlert.resolvingAction && (
            <Grid item>
              <ModalFormField title={translate('attestationFormRequest.alerts.modalResolve.operatorCommentToReview')}>
                <Typography>
                  {currentAlert.resolvingAction}
                </Typography>
              </ModalFormField>
            </Grid>
          )}
          {currentAlert.evaluationComment && (
            <Grid item>
              <ModalFormField title={translate('attestationFormRequest.alerts.modalResolve.evaluationCommentToResolve')}>
                <Typography>
                  {currentAlert.evaluationComment}
                </Typography>
              </ModalFormField>
            </Grid>
          )}
          {onOperatorResolve && (
            <Grid item>
              <ModalFormField title={translate('attestationFormRequest.alerts.modalResolve.resolvingActionTitle')}>
                <FormInputField
                  fullWidth
                  label={translate('attestationFormRequest.alerts.modalResolve.resolvingActionLabel')}
                  name="resolvingAction"
                  required
                />
              </ModalFormField>
            </Grid>
          )}
          {(onInstructorResolve || onReopenAlert || onCloseAlert) && (
            <Grid item>
              <ModalFormField title={translate('attestationFormRequest.alerts.modalResolve.resolvingActionTitle')}>
                <FormInputField
                  fullWidth
                  label={translate('attestationFormRequest.alerts.modalResolve.resolvingActionLabel')}
                  name="evaluationComment"
                  required={Boolean(onReopenAlert)}
                />
              </ModalFormField>
            </Grid>
          )}
          {(onOperatorResolve || onInstructorResolve || onCloseAlert) && (
            <Grid item>
              <DocumentList
                canEdit
                documents={documents}
                importLabel="attestationFormRequest.alerts.modalResolve.importDocument"
                spacing={2}
                title="attestationFormRequest.alerts.modalResolve.documentsLabel"
                titleStyles={{ variant: 'h6', fontWeight: 'bold' }}
                onDelete={deleteDocument}
                onImport={importDocument}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          onClick={onClose}
        >
          {translate('button.cancel')}
        </Button>
        {onReopenAlert && (
          <Button
            color="primary"
            onClick={async () => {
              const result = await trigger();
              result && onReopenAlert(getSubmittedAlert());
              result && onClose();
            }}
          >
            {translate('attestationFormRequest.alerts.modalResolve.reopenButton')}
          </Button>
        )}
        {onOperatorResolve && (
          <Button
            color="primary"
            onClick={async () => {
              const result = await trigger();
              result && onOperatorResolve(getSubmittedAlert());
              result && onClose();
            }}
          >
            {translate('attestationFormRequest.alerts.modalResolve.submitButton')}
          </Button>
        )}
        {onInstructorResolve && (
          <Button
            color="primary"
            onClick={() => {
              onInstructorResolve(getSubmittedAlert());
              onClose();
            }}
          >
            {translate('attestationFormRequest.alerts.modalResolve.validateButton')}
          </Button>
        )}
        {onCloseAlert && (
          <Button
            color="primary"
            onClick={() => {
              onCloseAlert();
              onClose();
            }}
          >
            {translate('attestationFormRequest.alerts.modalResolve.validateButton')}
          </Button>
        )}

      </DialogActions>

    </FormContainer>
  );
};