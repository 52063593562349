import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Autocomplete, DialogContent, Grid, TextField, Typography
} from '@mui/material';
import { translate } from 'utils';
import { iconEnum } from 'utils/icons';
import { useFetch } from 'hooks';
import { useSnackbar } from 'notistack';
import { EVALUATION_ROLES } from 'utils/constants';
import { FluidBalanceService } from 'services/FluidBalanceService';
import { CustomActionButtons } from './_CustomActionButtons';
import { ModalHeader } from './_ModalHeader';

export const FluidBalanceAssignationModal = ({
  onConfirm, onClose, defaultValue, role, fluidBalanceId
}) => {
  const [user, setUser] = useState(defaultValue);
  const {
    response: users,
    isLoading
  } = useFetch(() => FluidBalanceService.getEvaluatorOptions(fluidBalanceId, role), [], [fluidBalanceId, role]);
  const { enqueueSnackbar } = useSnackbar();

  const handleSelectUser = useCallback((value) => setUser(value), []);

  const handleValidateModal = useCallback((e) => {
    e.preventDefault();

    FluidBalanceService.assignEvaluator(fluidBalanceId, role, user?.value ?? null).then(() => {
      if (user == null) {
        enqueueSnackbar(translate(`modalFluidBalanceAssignation.${role}.unassigned`), { variant: 'success' });
      } else {
        enqueueSnackbar(translate(`modalFluidBalanceAssignation.${role}.assigned`), { variant: 'success' });
      }
      onConfirm();
      onClose();
    })
      .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }));
  }, [onClose, onConfirm, user]);

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={iconEnum.fluidBalance.icon} />
        <Typography component="span">
          {translate('modalFluidBalanceAssignation.title')}
        </Typography>
      </ModalHeader>

      <DialogContent>
        <Grid container direction="column" paddingTop={2}>
          <br />
          <Autocomplete
            disablePortal={false}
            id="assignUser"
            loading={isLoading}
            noOptionsText={translate('warnings.noOptionsAvailable')}
            options={users}
            renderInput={(params) => <TextField {...params} label={translate(`modalFluidBalanceAssignation.${role}.label`)} />}
            style={{ width: 350 }}
            value={user ?? null}
            onChange={(event, value) => handleSelectUser(value)}
          />
        </Grid>
      </DialogContent>

      <CustomActionButtons onClose={onClose} />
    </form>
  );
};

FluidBalanceAssignationModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  defaultValue: PropTypes.shape({}),
  role: PropTypes.oneOf([EVALUATION_ROLES.INSTRUCTOR, EVALUATION_ROLES.SUPERVISOR])
};

FluidBalanceAssignationModal.defaultProps = {
  defaultValue: {},
  role: EVALUATION_ROLES.INSTRUCTOR
};