import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormContainer, useForm } from 'react-hook-form-mui';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit } from '@fortawesome/pro-regular-svg-icons';
import { DialogContent, FormControl, Grid } from '@mui/material';

import { useStores } from 'hooks';
import { FormHelper, translate } from 'utils';
import { UserHelper, Validators } from 'utils/helpers';
import { APPLICATION_ADMIN_ROLES } from 'utils/constants';

import { CustomActionButtons } from 'components/_commons/Modals/_CustomActionButtons';
import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { FormInputField, ToggleButtons } from 'components/_commons/Form/Inputs';
import { SelectLabel } from 'components/_commons/Text';
import { StyledInput } from 'components/_commons/Modals/common/SelectEntity';
import { MultipleInputField } from 'components/_commons/Form/Inputs/InputField/MultipleInputField';

export const UpdateUserModal = ({ onClose, onSubmit, modalState: { userData } }) => {
  const { userStore } = useStores();
  const { scope } = userStore;
  const formContext = useForm({
    defaultValues: {
      ...userData,
      person: {
        ...userData?.person,
        contact: {
          ...userData?.person?.contact,
          contactEmails: userData?.person?.contact?.contactEmails?.split(';') ?? []
        }
      }
    }
  });
  const { watch } = formContext;

  const canAdministrate = useMemo(
    () => UserHelper.hasAccessRight(APPLICATION_ADMIN_ROLES) && userData?.accountId !== scope?.accountId,
    [userData, scope]
  );

  const onSubmitModal = useCallback((values) => {
    const finalValues = {
      ...values,
      person: {
        ...values?.person,
        contact: {
          ...values?.person?.contact,
          contactEmails: values?.person?.contact?.contactEmails.join(';')
        }
      }
    };
    onSubmit(finalValues);
  }, [onSubmit]);

  const options = [
    { id: true, label: translate('common.activated'), color: 'success' },
    { id: false, label: translate('common.deactivated'), color: 'error' }
  ];

  const needFullAddress = watch('person.address.address1')
    || watch('person.address.address2')
    || watch('person.address.postalCode')
    || watch('person.address.city');

  const personFullName = `${userData?.person?.firstName} ${userData?.person?.lastName}`;

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{ autoComplete: 'off', name: 'updateUserForm' }}
      onSuccess={onSubmitModal}
    >
      <ModalHeader onClose={onClose}>
        <Grid alignItems="center" container direction="row" justifyContent="space-between" spacing={1} wrap="wrap">
          <Grid item>
            <FontAwesomeIcon icon={faUserEdit} />
            {`${translate('pageUserDetails.modal.editUser.title')} : ${userData.login ?? personFullName}`}
          </Grid>
        </Grid>
      </ModalHeader>

      <DialogContent style={{ paddingTop: '30px' }}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12}>
            <SelectLabel>{translate('pageUserDetails.section.general')}</SelectLabel>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth variant="standard">
              <StyledInput>
                <FormInputField fullWidth label={translate('common.lastName')} name="person.lastName" required />
              </StyledInput>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth variant="standard">
              <StyledInput>
                <FormInputField fullWidth label={translate('common.firstName')} name="person.firstName" required />
              </StyledInput>
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormControl fullWidth variant="standard">
              <StyledInput>
                <FormInputField fullWidth label={translate('common.occupation')} name="person.occupation" />
              </StyledInput>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <SelectLabel>{translate('common.contact')}</SelectLabel>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="standard">
              <StyledInput>
                <MultipleInputField
                  fullWidth
                  label={translate('common.contact')}
                  name="person.contact.contactEmails"
                  required
                  validate={Validators.validateEmails}
                />
              </StyledInput>
            </FormControl>
          </Grid>
          {formContext.watch('accountActivated') && (
            <Grid item md={6} xs={12}>
              <FormControl fullWidth variant="standard">
                <StyledInput>
                  <FormInputField
                    fullWidth
                    label={translate('common.userModal.technicalEmail')}
                    name="person.contact.technicalEmail"
                    required
                    type="email"
                    validation={{
                      validate: (value) => Validators.validateEmail(value)
                    }}
                  />
                </StyledInput>
              </FormControl>
            </Grid>
          )}
          <Grid item md={6} xs={12}>
            <FormControl fullWidth variant="standard">
              <StyledInput>
                <FormInputField fullWidth label={translate('common.phone')} name="person.contact.phoneNumber" />
              </StyledInput>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <SelectLabel>{translate('common.address')}</SelectLabel>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth variant="standard">
              <StyledInput>
                <FormInputField
                  fullWidth
                  label={translate('common.address')}
                  name="person.address.address1"
                  required={needFullAddress}
                />
              </StyledInput>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth variant="standard">
              <StyledInput>
                <FormInputField fullWidth label={translate('common.address2')} name="person.address.address2" />
              </StyledInput>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth variant="standard">
              <StyledInput>
                <FormInputField
                  fullWidth
                  label={translate('common.postalCode')}
                  name="person.address.postalCode"
                  required={needFullAddress}
                />
              </StyledInput>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth variant="standard">
              <StyledInput>
                <FormInputField
                  fullWidth
                  label={translate('common.city')}
                  name="person.address.city"
                  required={needFullAddress}
                />
              </StyledInput>
            </FormControl>
          </Grid>
          {canAdministrate && (
            <>
              <Grid item xs={12}>
                <SelectLabel>{translate('common.userAccount')}</SelectLabel>
              </Grid>
              {userData.accountActivated
                ? (
                  <Grid className="display-flex align-items" item md={6} xs={12}>
                    <ToggleButtons enforceSelection name="accountActivated" options={options} />
                  </Grid>
                )
                : (
                  <Grid className="display-flex align-items" item md={6} xs={12}>
                    <ToggleButtons enforceSelection name="accountActivated" options={FormHelper.yesNoOption()} />
                  </Grid>
                )}
              {formContext.watch('accountActivated') && (
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth variant="standard">
                    <StyledInput>
                      <FormInputField
                        disabled={userData.accountActivated}
                        fullWidth
                        label={translate('common.login')}
                        name="login"
                        required
                      />
                    </StyledInput>
                  </FormControl>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </DialogContent>

      <CustomActionButtons onClose={onClose} />
    </FormContainer>
  );
};

UpdateUserModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

UpdateUserModal.defaultProps = {};