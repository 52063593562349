import { userStore } from 'stores';
import { generateUUID } from 'utils';

export const getDefaultHeaders = (method = 'GET') => {
  const headers = {
    'Content-Type': 'application/json',
    'X-Request-ID': generateUUID()
  };

  switch (method.toLowerCase()) {
  case 'post':
  case 'put':
    headers['Content-Type'] = 'application/json';
    break;
  default:
    break;
  }

  return headers;
};

export const httpSettings = (settings) => {
  const headers = settings.headers || getDefaultHeaders(settings.method);
  const init = settings;

  if (init.body) {
    switch (headers['Content-Type']) {
    case 'application/json':
      if (!(init.body instanceof FormData)) {
        init.body = JSON.stringify(init.body);
      }
      break;
    case 'application/x-www-form-urlencoded': {
      const formDataEntries = init.body instanceof FormData ? init.body.entries() : Object.entries(init.body);
      const formDataArray = [];

      formDataEntries.forEach(([key, value]) => {
        formDataArray.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      });
      init.body = formDataArray.join('&');
      break;
    }
    default:
      break;
    }
  }

  const userToken = userStore.getToken();

  headers.Accept = '*/*';

  if (userToken && !headers.Authorization) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  init.headers = headers;

  return init;
};