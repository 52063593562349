import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';

import {
  Button, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import { translate } from 'utils';
import sanitizeHtml from 'sanitize-html';

export const WarningModal = ({
  onClose, onConfirm, onCancel, onAction, text, title, buttonCancel, buttonConfirm, buttonAction, keepModalOpen
}) => {
  const onSubmit = useCallback(() => {
    if (onConfirm) {
      onConfirm();
    }
    if (!keepModalOpen) {
      onClose();
    }
  }, [onConfirm, onClose, keepModalOpen]);

  return (
    <>
      <DialogTitle className="warningModal">
        <FontAwesomeIcon icon={faExclamationTriangle} />
        {title || translate('warnings.warning')}
      </DialogTitle>

      <DialogContent
        dangerouslySetInnerHTML={{ __html: text ? sanitizeHtml(text) : sanitizeHtml(translate('warnings.deleteWarning')) }}
        style={{ maxWidth: '500px', marginTop: 30 }}
      />

      <DialogActions>
        <Button onClick={() => {
          onCancel();
          onClose();
        }}
        >
          {buttonCancel || translate('button.cancel')}
        </Button>
        {buttonAction && (
          <Button onClick={() => {
            onClose();
            onAction();
          }}
          >
            {buttonAction}
          </Button>
        )}
        <Button
          color="primary"
          id="warningModalConfirm"
          onClick={onSubmit}
        >
          {buttonConfirm || translate('confirms.confirm')}
        </Button>
      </DialogActions>
    </>
  );
};

WarningModal.propTypes = {
  buttonAction: PropTypes.string,
  buttonCancel: PropTypes.string,
  buttonConfirm: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  keepModalOpen: PropTypes.bool,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({})
  ]),
  title: PropTypes.string
};

WarningModal.defaultProps = {
  buttonAction: '',
  buttonCancel: '',
  buttonConfirm: '',
  keepModalOpen: false,
  onAction: null,
  onCancel: () => {},
  onConfirm: null,
  text: '',
  title: ''
};