import React, { createRef } from 'react';
import { configure } from 'mobx';
import { createRoot } from 'react-dom/client';
import { GlobalStyles, IconButton } from '@mui/material';
import { ModalProvider } from 'hooks';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import { isMobile } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { App } from 'app/App';
import './styles/_fonts.css';
import './locales/i18n';
import { GLOBAL_STYLES } from 'styles';
import { CustomThemeProvider } from 'styles/CustomThemeProvider';

configure({
  enforceActions: 'observed'
});

const notistackRef = createRef();

const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

const displayCloseButton = (key) => (
  <IconButton onClick={onClickDismiss(key)}>
    <FontAwesomeIcon color="#ffffff" icon={faXmark} />
  </IconButton>
);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <CustomThemeProvider>
      <GlobalStyles styles={GLOBAL_STYLES} />
      <SnackbarProvider
        action={displayCloseButton}
        dense={isMobile()}
        maxSnack={3}
        ref={notistackRef}
      >
        {/* Use two modal providers to ensure we can display a modal from the context of another one */}
        <ModalProvider>
          <ModalProvider>
            <App />
          </ModalProvider>
        </ModalProvider>
      </SnackbarProvider>
    </CustomThemeProvider>
  </BrowserRouter>
);