import React, { useCallback } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { translate } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { useTheme } from '@mui/material/styles';

const SwitchVisibility = observer(({
  name, value, label, onChange
}) => {
  const theme = useTheme();

  const handleChange = useCallback((event) => {
    onChange(event.target.name, event.target.checked);
  }, [onChange]);

  return (
    <FormControlLabel
      control={(
        <Switch
          checked={value}
          color="primary"
          name={name}
          onChange={handleChange}
        />
      )}
      label={(
        <>
          <FontAwesomeIcon
            color={value ? theme.palette.success.main : theme.palette.warning.main}
            icon={value ? faEye : faEyeSlash}
            style={{ minWidth: 25 }}
          />
          {translate(label)}
        </>
      )}
      style={{ marginBottom: '1rem' }}
    />
  );
});

SwitchVisibility.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired
};

SwitchVisibility.defaultProps = {
  label: 'common.switchVisibility'
};

export default SwitchVisibility;