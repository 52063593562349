const DOCUMENT_ASSIGNATION_STEPS = {
  REGISTRATION: 'REGISTRATION',
  ASSIGNATION: 'ASSIGNATION',
  SUMMARY: 'SUMMARY'
};

const Steps = {
  [DOCUMENT_ASSIGNATION_STEPS.REGISTRATION]: {
    step: DOCUMENT_ASSIGNATION_STEPS.REGISTRATION,
    title: 'attestationFormRequest.tools.modal.title.registration',
    percent: 0
  },
  [DOCUMENT_ASSIGNATION_STEPS.ASSIGNATION]: {
    step: DOCUMENT_ASSIGNATION_STEPS.ASSIGNATION,
    title: 'attestationFormRequest.tools.modal.title.possession',
    percent: 50
  },
  [DOCUMENT_ASSIGNATION_STEPS.SUMMARY]: {
    step: DOCUMENT_ASSIGNATION_STEPS.SUMMARY,
    title: 'attestationFormRequest.tools.modal.title.summary',
    percent: 100
  }
};

const isLast = (step) => step === Object.values(DOCUMENT_ASSIGNATION_STEPS).slice(-1)[0];
const isFirst = (step) => step === Object.values(DOCUMENT_ASSIGNATION_STEPS)[0];

function nextStep(step) {
  const stepOrder = Object.values(DOCUMENT_ASSIGNATION_STEPS);
  const index = stepOrder.indexOf(step);

  return stepOrder[Math.min(stepOrder.length - 1, index + 1)];
}

function prevStep(step) {
  const stepOrder = Object.values(DOCUMENT_ASSIGNATION_STEPS);
  const index = stepOrder.indexOf(step);

  return stepOrder[Math.max(0, index - 1)];
}

export const DocumentAssignationModalStepsEnum = {
  ...DOCUMENT_ASSIGNATION_STEPS,
  Steps,
  isFirst,
  isLast,
  nextStep,
  prevStep
};