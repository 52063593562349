import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SectionEnum } from 'components/AttestationForm/attestationFormSection.enum';
import { ButtonSpinner, Spinner } from 'components/_commons/Skeletons';
import { useFetch, useModal } from 'hooks';
import { useExcelImport } from 'hooks/useExcelImport';
import React, { useCallback, useEffect, useState } from 'react';

import { faUser } from '@fortawesome/pro-regular-svg-icons';

import { Button, CardButtonAdd } from 'components/_commons/Button';
import {
  AttestationFormOperatorService,
  AttestationFormRequestService, DocumentService,
  InstitutionService
} from 'services';
import { DocumentHelper, translate } from 'utils';

import { Grid, Tooltip, Typography } from '@mui/material';
import { UnclassifiedDocumentType } from 'components/AttestationForm';
import { DocumentsTable } from 'components/AttestationForm/shared/DocumentsTable';
import { TecneaTitle } from 'components/_commons/Title';
import { useFetchState } from 'hooks/useFetch';
import { useSnackbar } from 'notistack';
import { EnumService, enumValueTypes } from 'services/EnumService';
import { ATTESTATION_FORM_ACTIONS } from 'utils/constants';
import { iconEnum, ICONS } from 'utils/icons';
import { OperatorsTable } from './OperatorsTable';
import { OrganizationBlock } from './OrganizationBlock';

export const Operators = ({
  requestId,
  updateAlerts,
  alertedOperators,
  availableActions,
  institutionId,
  showHistory,
  updateUnreadDocumentAlerts
}) => {
  const canValidate = availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_DOCUMENT_VALIDATION);
  const canEdit = availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_FORM);

  const { enqueueSnackbar } = useSnackbar();
  const openModal = useModal();
  const [operators, setOperators] = useState([]);
  const [areOperatorsLoading, setAreOperatorsLoading] = useState(true);
  const [isExporting, setIsExporting] = useState(false);
  const [refreshDocumentList, setRefreshDocumentList] = useState(0);
  const { response: categories } = useFetch(() => AttestationFormRequestService.getRequestCategories(requestId), []);
  const [workMode, setWorkMode, isWorkModeLoading] = useFetchState(() => AttestationFormRequestService.getWorkMode(requestId));
  const { response: workModes, isLoading: areWorkModesLoading } = (
    useFetch(() => AttestationFormRequestService.getWorkModeWithTooltips(requestId), []));
  const { response: documentTypes } = useFetch(() => AttestationFormOperatorService.getDocumentsType(), []);
  const { response: categoriesEnum } = useFetch(() => EnumService.getEnumValues(enumValueTypes.ATTESTATION_CATEGORY), []);
  const categoriesOptions = categoriesEnum.map((opt) => ({ value: opt, label: opt }));
  const { response: history } = (
    useFetch(() => InstitutionService.getChangesSinceLastEvaluation(institutionId, SectionEnum.Operators), [])
  );
  const {
    response: unclassifiedDocuments,
    isLoading: unclassifiedDocumentsLoading
  } = useFetch(
    () => AttestationFormRequestService.getUnclassifiedDocuments(
      requestId,
      UnclassifiedDocumentType.UnclassifiedOperator
    ),
    [],
    [refreshDocumentList]
  );

  const loadOperators = useCallback(() => {
    setAreOperatorsLoading(true);
    AttestationFormRequestService.getOperators(requestId)
      .then((resp) => setOperators(resp))
      .catch((err) => enqueueSnackbar(err.message || err, { variant: 'error' }))
      .finally(() => setAreOperatorsLoading(false));
  }, [enqueueSnackbar, requestId]);

  const reloadAlerts = useCallback(
    () => {
      updateAlerts(SectionEnum.Operators);
      if (canValidate) {
        updateUnreadDocumentAlerts();
      }
    },
    [updateAlerts, canValidate]
  );

  useEffect(() => {
    loadOperators();
  }, []);

  useEffect(() => {
    reloadAlerts();
  }, [refreshDocumentList]);

  const reloadOperatorsAndAlerts = useCallback(
    () => {
      loadOperators();
      reloadAlerts();
    },
    [loadOperators, reloadAlerts]
  );

  const {
    isImporting,
    importFunc: importAttestationFormOperators
  } = useExcelImport(
    (file) => AttestationFormOperatorService.importAttestationFormOperators(requestId, file),
    reloadOperatorsAndAlerts
  );

  const exportAttestationFormOperators = useCallback(() => {
    setIsExporting(true);
    AttestationFormOperatorService.exportAttestationFormOperators(requestId)
      .then((response) => DocumentHelper.handleExcelFileDownload(response))
      .catch((exception) => {
        enqueueSnackbar(exception.message, { variant: 'error' });
      })
      .finally(() => setIsExporting(false));
  }, [enqueueSnackbar]);

  const getOperatorsWithHistory = useCallback(() => [
    ...operators.map((operator) => ({
      ...operator,
      change: history.find((h) => h.subjectId === operator.id)?.type
    })),
    ...history.filter((h) => h.type.value === 'DELETION').map((h) => ({
      deleted: true,
      change: h.type,
      attestationDocuments: [],
      diplomaDocuments: [],
      person: {
        firstName: h.subjectString,
        lastName: ''
      }
    }))
  ], [history, operators]);

  const handleChangeOrganization = (mode) => {
    AttestationFormOperatorService.updateOrganization(requestId, mode)
      .then(() => {
        setWorkMode(mode);
        updateAlerts(SectionEnum.Operators);
        enqueueSnackbar(translate('operatorsStep.organization.snackbar.changes'), { variant: 'success' });
      })
      .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
  };

  const updateOperatorRow = useCallback((operatorId, field, value) => {
    setOperators(operators?.map((opt) => (operatorId === opt.id ? { ...opt, [field]: value } : opt)));
  }, [operators]);

  const addOperator = useCallback((operator) => openModal({
    type: 'ADD_OPERATOR',
    closeOnSubmit: true,
    maxWidth: false,
    defaultOperator: operator,
    documentTypes,
    requestId,
    onSubmit: (submittedOperator) => {
      if (operator?.id) {
        AttestationFormOperatorService.updateOperator(submittedOperator, operator.id)
          .then(() => {
            enqueueSnackbar(translate('attestationFormRequest.operator.snackbar.edited'), { variant: 'success' });
            updateAlerts(SectionEnum.Operators);
          })
          .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }))
          .finally(() => loadOperators());
      } else {
        AttestationFormRequestService.createOperator(submittedOperator, requestId)
          .then(() => {
            enqueueSnackbar(translate('attestationFormRequest.operator.snackbar.created'), { variant: 'success' });
            updateAlerts(SectionEnum.Operators);
          })
          .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }))
          .finally(() => loadOperators());
      }
    }
  }), [openModal, loadOperators, updateAlerts, documentTypes]);

  const showDocuments = useCallback((operator, documentTypeName) => openModal({
    canValidate,
    canEdit,
    type: 'DOCUMENTS_VERIFICATION',
    onSubmit: ({ documents }) => {
      const request = {
        ...operator,
        documentTypesToUpdate: operator?.[documentTypeName]?.map(
          (doc) => doc.type.value
        )
      };
      request[documentTypeName] = documents;
      AttestationFormOperatorService.updateOperator(request, operator?.id)
        .then(() => {
          if (canValidate) {
            updateUnreadDocumentAlerts();
          }
          enqueueSnackbar(canValidate ? translate('attestationFormRequest.validationModal.snackbar.saved')
            : translate('attestationFormRequest.verificationModal.snackbar.saved'), { variant: 'success' });
          loadOperators();
          updateAlerts(SectionEnum.Operators);
        })
        .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
    },
    closeOnSubmit: true,
    ignoreValidation: true,
    isProof: true,
    maxWidth: false,
    fullscreen: true,
    modalState: {
      documentTypes,
      options: categoriesOptions,
      documents: operator?.[documentTypeName]?.map((doc) => (
        { ...doc, attestationCategories: doc.attestationCategories ?? [] }
      ))
    }
  }), [openModal, documentTypes, categoriesOptions]);

  const showAttestationDocuments = useCallback((operator) => (
    showDocuments(operator, 'attestationDocuments')
  ), [openModal, showDocuments]);
  const showDiplomaDocuments = useCallback((operator) => (
    showDocuments(operator, 'diplomaDocuments')
  ), [openModal, showDocuments]);

  const deactivateOperator = useCallback(({ operatorId, deactivate }) => {
    setAreOperatorsLoading(true);
    AttestationFormOperatorService.deactivate(operatorId, deactivate)
      .then(() => {
        updateOperatorRow(operatorId, 'activated', deactivate);
        updateAlerts(SectionEnum.Operators);
      })
      .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }))
      .finally(() => setAreOperatorsLoading(false));
  }, [enqueueSnackbar, updateOperatorRow]);

  const removeOperator = ({ id: operatorId }) => {
    openModal({
      type: 'WARNING',
      onConfirm: () => {
        AttestationFormOperatorService.remove(operatorId)
          .then(() => {
            const newOperatorList = operators?.filter((opt) => operatorId !== opt.id);
            setOperators(newOperatorList);
            newOperatorList.length === 1 && handleChangeOrganization(workModes[0].value);
            enqueueSnackbar(translate('attestationFormRequest.operator.snackbar.removed'), { variant: 'success' });
          })
          .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
      }
    });
  };

  return (
    <Grid container direction="column" spacing={6}>
      {(operators.length > 1) && (
        <Grid item>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <TecneaTitle label={translate('operatorsStep.organization.title', { count: 2 })} />
            </Grid>
            {(isWorkModeLoading || areWorkModesLoading)
              ? <Spinner />
              : (
                <Grid item>
                  <OrganizationBlock
                    availableActions={availableActions}
                    changeOrganization={handleChangeOrganization}
                    currentOrganization={workMode}
                    organizations={workModes}
                    requestId={requestId}
                    updateAlerts={updateAlerts}
                  />
                </Grid>
              )}
          </Grid>
        </Grid>
      )}
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <TecneaTitle label={translate('operatorsStep.operators.subtitle')} />
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2}>
                      {canEdit
                        && (
                          <>
                            <Grid item>
                              <Tooltip
                                placement="left"
                                title={translate('attestationFormRequest.operators.importOperatorsTooltip')}
                              >
                                <div>
                                  <Button
                                    color="primary"
                                    disabled={isImporting}
                                    startIcon={isImporting ? <ButtonSpinner /> : <FontAwesomeIcon icon={ICONS.IMPORT.icon} />}
                                    onClick={importAttestationFormOperators}
                                  >
                                    {translate('attestationFormRequest.operators.importOperators')}
                                  </Button>
                                </div>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Button
                                startIcon={<FontAwesomeIcon icon={iconEnum.download.icon} />}
                                type="secondary"
                                onClick={() => DocumentService.getImportExampleFile('attestation-form-operator')}
                              >
                                {translate('common.exampleFile')}
                              </Button>
                            </Grid>
                          </>
                        )}
                      <Grid item>
                        <Button
                          color="primary"
                          disabled={isExporting}
                          startIcon={isExporting ? <ButtonSpinner /> : <FontAwesomeIcon icon={ICONS.EXPORT.icon} />}
                          onClick={exportAttestationFormOperators}
                        >
                          {translate('attestationFormRequest.operators.exportOperators')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography>{`${translate('operatorsStep.operators.title')} ${(categories ?? []).join(', ')}`}</Typography>
              </Grid>
              {canEdit
                && (
                  <Grid item>
                    <CardButtonAdd
                      icon={faUser}
                      label={translate('certificateFolderForm.operator.add')}
                      variant="outlined"
                      onClick={() => addOperator(undefined)}
                    />
                  </Grid>
                )}
            </Grid>
          </Grid>
          <Grid item>
            <OperatorsTable
              alertedOperators={alertedOperators}
              availableActions={availableActions}
              categories={categories}
              deactivate={deactivateOperator}
              handleEdit={addOperator}
              handleRemove={removeOperator}
              isLoading={areOperatorsLoading}
              operators={showHistory ? getOperatorsWithHistory() : operators}
              showAttestation={showAttestationDocuments}
              showDiploma={showDiplomaDocuments}
              showHistory={showHistory}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <DocumentsTable
          canEdit={canEdit}
          canValidate={canValidate}
          disabled={areOperatorsLoading}
          folders={unclassifiedDocuments}
          isLoading={unclassifiedDocumentsLoading}
          requestId={requestId}
          section={SectionEnum.Operators}
          onUpdate={() => setRefreshDocumentList((value) => value + 1)}
          onUpdateCurrentSection={loadOperators}
        />
      </Grid>
    </Grid>
  );
};