import { RequestHelper } from 'utils';
import { API_ROUTES, INSEE_API_TOKEN } from 'utils/constants';

const customHeadersInseeApi = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${INSEE_API_TOKEN}`
};
const inseeUrl = 'https://api.insee.fr/entreprises/sirene/V3.11';
// eslint-disable-next-line max-len
// Docs : https://api.insee.fr/catalogue/site/themes/wso2/subthemes/insee/pages/item-info.jag?name=Sirene&version=V3&provider=insee

const getCompanyTree = (companyId, activity) => RequestHelper.GET(API_ROUTES.COMPANY.TREE(companyId, activity));
const isPublic = (companyId) => RequestHelper.GET(API_ROUTES.COMPANY.IS_PUBLIC(companyId));
const getParentCompany = (companyId) => RequestHelper.GET(API_ROUTES.COMPANY.TREE_PARENT_COMPANY(companyId));
const getCompany = (companyId) => RequestHelper.GET(API_ROUTES.COMPANY.ADMINISTRATION(companyId));
const getCompanyFromInstitutionModel = (institutionModelId) => RequestHelper.GET(
  API_ROUTES.COMPANY.FROM_INSTITUTION_MODEL(institutionModelId)
);
const updateCompany = (companyId, company) => RequestHelper.PUT(API_ROUTES.COMPANY.ADMINISTRATION(companyId), company);
const deleteCompany = (companyId) => RequestHelper.DELETE(API_ROUTES.COMPANY.ADMINISTRATION(companyId));
const createCompany = (company) => RequestHelper.POST(API_ROUTES.COMPANY.SAVE(), company);
const getCompanyList = (filter, page, signal) => RequestHelper.POST(API_ROUTES.COMPANY.FILTER(page), filter, null, signal);
const getCompanySelectItemList = (filter, page = 0) => (
  RequestHelper.POST(API_ROUTES.COMPANY.OPTIONS(page), filter)
);
const getCompanyStructureLevels = (companyId) => RequestHelper.GET(API_ROUTES.COMPANIES.DETAIL(companyId));
const getAccessibleCompanies = () => RequestHelper.GET(API_ROUTES.COMPANIES.ACCESSIBLE_COMPANIES());

// INSEE
const getCompanyBySiren = (siren = '') => RequestHelper.GET(`${inseeUrl}/siren/${siren}`, customHeadersInseeApi);
const getInstitutionBySiret = (siret = '') => RequestHelper.GET(`${inseeUrl}/siret/${siret}`, customHeadersInseeApi);

export const CompanyService = {
  getCompany,
  getCompanyFromInstitutionModel,
  getCompanyTree,
  getParentCompany,
  updateCompany,
  deleteCompany,
  createCompany,
  getCompanyList,
  getCompanySelectItemList,
  getCompanyBySiren,
  getCompanyStructureLevels,
  getInstitutionBySiret,
  isPublic,
  getAccessibleCompanies
};