import React from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';

const ButtonClose = styled(IconButton)(() => ({
  position: 'absolute !important',
  right: 0,
  top: 0
}));

export const CloseButton = ({
  onClose, iconSize
}) => {
  const theme = useTheme();

  return (
    <ButtonClose aria-label="close" onClick={onClose}>
      <FontAwesomeIcon color={theme.palette.common.white} icon={faXmark} style={{ margin: 0, fontSize: iconSize ?? '3rem' }} />
    </ButtonClose>
  );
};

CloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
  iconSize: PropTypes.string
};

CloseButton.defaultProps = {
  iconSize: null
};