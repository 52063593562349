import { DialogContent, Grid, Typography } from '@mui/material';
import { DocumentList } from 'components/_commons/Document/DocumentList';
import { FormInputField, FormTextareaField } from 'components/_commons/Form/Inputs';
import FormDatePicker from 'components/_commons/Form/Inputs/Datepicker/FormDatePicker';
import FormSelectField from 'components/_commons/Form/Inputs/SelectField/FormSelectField';
import ItemOption from 'components/_commons/Form/ItemOption/ItemOption';
import { CustomActionButtons } from 'components/_commons/Modals/_CustomActionButtons';
import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { StyledInput } from 'components/_commons/Modals/common/SelectEntity';
import debounce from 'debounce-promise';
import { useFetch, useModal } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { AuditService, DocumentService, InstitutionService } from 'services';
import { enumOptionTypes, EnumService } from 'services/EnumService';
import { DocumentHelper, isTablet, translate } from 'utils';
import { AUDIT_TYPES } from 'utils/constants';
import { DateHelper } from 'utils/helpers';

export const CreateAuditModal = ({
  onClose,
  onSubmit,
  defaultValues,
  withInstitutionToSelect
}) => {
  const formContext = useForm({ defaultValues });
  const { watch, setValue } = formContext;
  const { response: auditStatuses } = useFetch(() => EnumService.getEnumOptions(enumOptionTypes.AUDIT_STATUS), []);
  const { response: auditTypes } = useFetch(() => EnumService.getEnumOptions(enumOptionTypes.AUDIT_TYPE), []);
  const { response: auditors } = useFetch(() => AuditService.getAuditors(), []);
  const [freeSearchInstitution, setFreeSearchInstitution] = useState('');
  const [institutions, setInstitutions] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const showModal = useModal();

  const getInstitutionsPromise = useCallback((freeSearch) => new Promise((resolve, reject) => {
    InstitutionService.getInstitutionOptions({ freeSearch })
      .then((response) => resolve(response))
      .catch((error) => reject((error && error.message) || error));
  }), []);

  const debouncedFetchInstitutions = debounce(getInstitutionsPromise, 500);

  const fetchInstitutions = useCallback((freeSearch) => {
    setIsLoading(true);
    debouncedFetchInstitutions(freeSearch)
      .then((resp) => setInstitutions(resp))
      .finally(() => setIsLoading(false));
  }, [setInstitutions, setIsLoading]);

  useEffect(() => {
    if (!defaultValues?.report?.base64Content && defaultValues?.report) {
      DocumentService.getFileTransferModel(defaultValues.report.id)
        .then((fileData) => formContext.setValue('report.base64Content', fileData.base64Content));
    }
  }, []);

  useEffect(() => {
    fetchInstitutions(freeSearchInstitution);
  }, [fetchInstitutions, freeSearchInstitution]);

  const handleAddReport = (doc) => {
    if (doc) {
      setValue('report', { name: doc.name, base64Content: DocumentHelper.getDocumentWithoutBase64(doc) });
    }
  };

  const handleDeleteReport = () => {
    setValue('report', null);
  };

  const submit = useCallback(() => {
    onSubmit(
      {
        ...formContext.getValues(),
        startDate: watch('startDate') ? DateHelper.getLocalDateTime(watch('startDate')) : null,
        appointment: DateHelper.getDateWithoutTimezoneTransformation(watch('appointment'))
      }
    );
    onClose();
  }, [onClose, onSubmit, formContext]);

  const handleSubmit = () => {
    if (watch('type').value === AUDIT_TYPES.READING_COMMITTEE) {
      showModal({
        type: 'CONFIRM',
        onConfirm: () => submit(),
        title: 'modalAudit.action.save_reading_committee',
        content: translate('modalAudit.confirmation.save_reading_committee')
      });
    } else {
      submit();
    }
  };

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{
        autoComplete: 'off',
        name: 'auditForm',
        style: { overflow: 'auto' }
      }}
      onSuccess={handleSubmit}
    >
      <ModalHeader onClose={onClose}>
        <Typography component="span">
          {defaultValues && defaultValues.id
            ? translate('modalAudit.editAudit')
            : translate('modalAudit.newAudit')}
        </Typography>
      </ModalHeader>

      <DialogContent style={{ width: isTablet() ? '90vw' : '60vw', paddingLeft: '3rem' }}>
        <Grid container direction="column">
          <Typography margin={1} paddingTop={2} variant="h5">
            {translate('modalAudit.auditSection')}
          </Typography>
          <Grid item width="50%">
            {withInstitutionToSelect && (
              <StyledInput>
                <FormSelectField
                  disablePortal={false}
                  filterOptions={(x) => x}
                  getOptionLabel={(option) => option?.label}
                  inputValue={freeSearchInstitution}
                  label={translate('common.institution')}
                  loading={isLoading}
                  loadingText={translate('common.loading')}
                  name="institution"
                  noOptionsText={translate('warnings.noOptionsAvailable')}
                  options={institutions ?? []}
                  renderOption={(props, option) => (
                    <ItemOption {...props} isInsideSelect key={option.id}>
                      <Typography variant="h6">
                        {option.label}
                      </Typography>
                    </ItemOption>
                  )}
                  required
                  variant="outlined"
                  onChange={() => setFreeSearchInstitution('')}
                  onInputChange={(value) => {
                    setFreeSearchInstitution(value);
                    fetchInstitutions(value);
                  }}
                />
              </StyledInput>
            )}
          </Grid>
          <Grid item width="50%">
            <FormSelectField
              label={translate('modalAudit.auditInformation.type')}
              name="type"
              options={auditTypes}
              required
            />
          </Grid>
          <Grid item width="50%">
            <FormSelectField
              label={translate('modalAudit.auditInformation.auditor')}
              name="auditor"
              options={auditors}
            />
          </Grid>
          <Grid item width="50%">
            <FormInputField
              fullWidth
              label={translate('modalAudit.auditInformation.auditorFreeField')}
              name="auditorFreeField"
            />
          </Grid>

          <Grid container direction="row" marginTop={1} spacing={5}>
            <Grid item>
              <FormDatePicker
                label="modalAudit.auditInformation.appointment"
                name="appointment"
                required
              />
            </Grid>
            <Grid item>
              <FormDatePicker
                disableFuture
                label="modalAudit.auditInformation.startDate"
                name="startDate"
                validate={(value) => (value ? DateHelper.validateIfDateIsValid(value) : true)}
              />
            </Grid>
          </Grid>

          <Typography margin={1} paddingTop={2} variant="h5">
            {translate('modalAudit.conclusionSection')}
          </Typography>

          <Grid item width="50%">
            <FormSelectField
              label={translate('modalAudit.auditInformation.status')}
              name="status"
              options={auditStatuses}
            />
          </Grid>
          <FormTextareaField
            label={translate('modalAudit.auditInformation.reason')}
            name="reason"
          />
          <FormTextareaField
            label={translate('modalAudit.auditInformation.comment')}
            name="comment"
          />
          <FormTextareaField
            label={translate('modalAudit.auditInformation.nonCompliances')}
            name="nonCompliances"
          />
          <FormTextareaField
            label={translate('modalAudit.auditInformation.privateComment')}
            name="privateComment"
          />

          <Typography margin={1} paddingBottom={1} paddingTop={2} variant="h5">
            {translate('modalAudit.reportSection')}
          </Typography>
          <Grid item>
            <DocumentList
              canEdit={!defaultValues?.report?.id}
              documents={watch('report') ? [{ document: watch('report') }] : []}
              importLabel="button.import"
              sizeLimit={1}
              spacing={2.5}
              titleStyles={{
                textAlign: 'center',
                variant: 'h6'
              }}
              onDelete={handleDeleteReport}
              onImport={handleAddReport}
            />
          </Grid>

        </Grid>
      </DialogContent>

      <CustomActionButtons
        onClose={onClose}
      />

    </FormContainer>
  );
};