import {
  FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, useTheme
} from '@mui/material';
import React from 'react';
import { useController } from 'react-hook-form';

const RadioButtonGroupFixed = ({
  controlledValue,
  helperText,
  options,
  label,
  name,
  parseError,
  labelKey = 'label',
  valueKey = 'id',
  required,
  emptyOptionLabel,
  returnObject,
  row,
  itemStyles,
  labelStyles,
  control,
  type,
  validate,
  ...rest
}) => {
  const theme = useTheme();
  const {
    field: { value, onChange },
    fieldState: { error }
  } = useController({
    name,
    rules: {
      required,
      validate: {
        validation: () => ((validate) ? validate() : true)
      }
    },
    control
  });

  if (error) {
    // eslint-disable-next-line no-param-reassign
    helperText = typeof parseError === 'function'
      ? parseError(error)
      : error.message;
  }

  const onRadioChange = (event) => {
    const radioValue = event.target.value;
    const returnValue = returnObject
      ? options.find((items) => items[valueKey] === radioValue)
      : radioValue;
    // setValue(name, returnValue, { shouldValidate: true })
    onChange(returnValue);
    if (typeof rest.onChange === 'function') {
      rest.onChange(returnValue);
    }
  };

  return (
    <div>
      {label && (
        <FormLabel error={!!error} required={Boolean(required)}>
          {label}
        </FormLabel>
      )}
      <RadioGroup
        name={name}
        row={row}
        value={value || ''}
        onChange={onRadioChange}
      >
        {emptyOptionLabel && (
          <FormControlLabel
            control={(
              <Radio
                checked={!value}
                sx={{
                  color: error ? theme.palette.error.main : undefined
                }}
              />
            )}
            label={emptyOptionLabel}
            value=""
          />
        )}
        {options.map((option) => {
          const optionKey = option[valueKey];
          if (!optionKey) {
            // eslint-disable-next-line no-console
            console.error(
              `CheckboxButtonGroup: valueKey ${valueKey} does not exist on option`,
              option
            );
          }
          let val = returnObject ? value?.[valueKey] : value;
          if (type === 'number') {
            val = Number(val);
          }
          const isChecked = controlledValue ? controlledValue === optionKey : val === optionKey;
          return (
            <FormControlLabel
              control={(
                <Radio
                  checked={isChecked}
                  sx={{
                    color: error ? theme.palette.error.main : undefined
                  }}
                />
              )}
              key={optionKey}
              label={(
                <span style={labelStyles}>
                  {option[labelKey]}
                  {option.endAdornment}
                </span>
              )}
              style={itemStyles}
              value={optionKey}
            />
          );
        })}
      </RadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </div>
  );
};

export default RadioButtonGroupFixed;