import PropTypes from 'prop-types';
import {
  Card, CardContent, CardHeader, Divider, Grid, IconButton, Tooltip, useTheme
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import shortid from 'shortid';
import InfoLine from 'components/_commons/InfoLine/InfoLine';
import { translate } from 'utils';
import { iconEnum } from 'utils/icons';

export const ContactCard = ({
  contacts, onDelete, onEdit, canEdit
}) => {
  const { typeLabel } = contacts[0];
  const theme = useTheme();

  return (
    <Card variant="outlined">
      <CardHeader
        style={{ backgroundColor: theme.palette.grey.lighter, textAlign: 'center', padding: 10 }}
        title={typeLabel.toUpperCase()}
        titleTypographyProps={{ variant: 'h5', style: { fontWeight: 'bold' } }}
      />
      <CardContent>
        <>
          {contacts.map((contact, index) => (
            <div key={shortid.generate()}>
              {(index > 0) && <Divider style={{ margin: 10 }} variant="middle" />}
              <Grid container direction="row" justifyContent="space-between">
                <Grid item xs>
                  <InfoLine info={contact.person.lastName} label="common.lastName" />
                  <InfoLine info={contact.person.firstName} label="common.firstName" />
                  <InfoLine info={contact.person.occupation} label="common.occupation" />
                  <InfoLine info={contact.person.contact.phoneNumber} label="common.phone" type="phone" />
                  <InfoLine info={contact.person.contact.contactEmails} label="common.email" type="email-list" />
                </Grid>
                {canEdit && (
                  <Grid item>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <Tooltip title={translate('attestationFormRequest.contacts.actions.edit')}>
                          <IconButton color="primary" onClick={() => onEdit(contact)}>
                            <FontAwesomeIcon icon={iconEnum.pen.icon} size="sm" />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Tooltip title={translate('attestationFormRequest.contacts.actions.remove')}>
                          <IconButton color="primary" onClick={() => onDelete(contact)}>
                            <FontAwesomeIcon
                              color={iconEnum.trashAlt.defaultColor}
                              icon={iconEnum.trashAlt.icon}
                              size="sm"
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </div>
          ))}
        </>
      </CardContent>
    </Card>
  );
};

ContactCard.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.shape({
    person: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      occupation: PropTypes.string,
      contact: PropTypes.shape({
        phoneNumber: PropTypes.string,
        contactEmails: PropTypes.string
      })
    })
  })).isRequired
};