import React, { useEffect } from 'react';

import { translate } from 'utils';
import { useFetch } from 'hooks';
import { OperatorModel } from 'core/models';
import { EnumService, enumValueTypes } from 'services/EnumService';

import { Spinner } from 'components/_commons/Skeletons';
import { ModalSectionTitle } from 'components/_commons/Modals/common/ModalSectionTitle';
import { DocumentBlock } from 'components/_commons/Modals/common/DocumentBlock';

export const AddOperatorDocument = ({ formContext, types, setIsLoading }) => {
  const { response, isLoading } = useFetch(() => EnumService.getEnumValues(enumValueTypes.ATTESTATION_CATEGORY), [], []);

  const options = (response ?? []).map((opt) => ({ value: opt, label: opt }));
  const documentType = OperatorModel.mapDocumentTypeByGroup(types);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [setIsLoading, isLoading]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <ModalSectionTitle
        subtitle="attestationFormRequest.operator.document.subtitle"
        title="attestationFormRequest.operator.document.title"
      />
      <DocumentBlock
        attestationCategories={options}
        documentDefaultValues={{
          documentDate: undefined,
          type: documentType.attestationDocuments?.[0]
        }}
        fieldName="attestationDocuments"
        formContext={formContext}
        labelButton={translate('attestationFormRequest.operators.attestations')}
        showDivider
        type="primary"
      />
      <DocumentBlock
        diplomaTypes={documentType.diplomaDocuments}
        documentDefaultValues={{ documentDate: undefined }}
        fieldName="diplomaDocuments"
        formContext={formContext}
        labelButton={translate('attestationFormRequest.operators.diploma')}
        type="primary"
        variant="outlined"
      />
    </>
  );
};