import { useModal } from 'hooks';
import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Divider, Grid, Tooltip } from '@mui/material';

import { faFileAlt } from '@fortawesome/pro-regular-svg-icons';

import { DocumentHelper, translate } from 'utils';

import { InputFile } from 'components/_commons/Form/Inputs';
import { AddDocumentItem } from 'components/_commons/Modals/common/AddDocumentItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { useTheme } from '@mui/material/styles';

export const DocumentBlock = ({
  labelButton, variant, formContext, showDivider, attestationCategories,
  diplomaTypes, fieldName, type, documentDefaultValues = {}, withTooltip = false
}) => {
  const showModal = useModal();
  const theme = useTheme();
  const { fields, remove, append } = useFieldArray({
    control: formContext.control,
    name: fieldName
  });

  const removeDocument = useCallback((index) => showModal({
    type: 'WARNING',
    onConfirm: () => (
      remove(index)
    )
  }), []);

  return (
    <div className="m2">
      <Grid
        alignItems="center"
        container
        justifyContent="center"
        spacing={2}
        wrap="wrap"
      >
        <Grid alignItems="center" container item md={6} spacing={1} xs={8}>
          <Grid item xs={11}>
            <InputFile
              endIcon={faFileAlt}
              fullWidth
              handleAddDocument={(file) => append({
                ...documentDefaultValues,
                ...file,
                base64Content: DocumentHelper.getDocumentWithoutBase64(file)
              })}
              id={`input-file-${fieldName}`}
              label={null}
              text={labelButton}
              type={type}
              variant={variant}
              whiteSpace="break-spaces"
            />
          </Grid>
          <Grid item xs={1}>
            {withTooltip && documentDefaultValues?.type?.value
              && (
                <Tooltip
                  title={documentDefaultValues.type.tooltip}
                >
                  <FontAwesomeIcon
                    color={theme.palette.primary.main}
                    icon={faQuestionCircle}
                    size="2x"
                  />
                </Tooltip>
              )}
          </Grid>
        </Grid>
      </Grid>
      <Grid alignItems="center" container justifyContent="center">
        <Grid item md={10} xs={12}>
          <div className="m3 spacing-bottom">
            {fields.map((field, index) => (
              <AddDocumentItem
                attestationCategories={attestationCategories}
                diplomaTypes={diplomaTypes}
                docIndex={index}
                document={field}
                documentType={documentDefaultValues?.type}
                fieldName={fieldName}
                formContext={formContext}
                key={`input-file-${fieldName}-${index + 1}`}
                removeDocument={() => removeDocument(index)}
              />
            ))}
          </div>
        </Grid>
      </Grid>
      {showDivider && <Divider>{translate('attestationFormRequest.operators.divider')}</Divider>}
    </div>
  );
};