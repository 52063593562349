import { InputAdornment } from '@mui/material';
import React from 'react';
import { TextFieldElement } from 'react-hook-form-mui';
import { translate } from 'utils';

const FormInputField = ({
  label, variant = 'standard', required, fullWidth, name, onChange, value,
  disabled, endAdornment, size, validation, textAlign, width, type, customInputProps, withArrow, helperText, onBlur
}) => {
  let style = {
    width,
    '& input': {
      textAlign
    }
  };

  if (!withArrow) {
    style = {
      ...style,
      '& input[type=number]': {
        MozAppearance: 'textfield'
      },
      '& input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      }
    };
  }

  return (
    <TextFieldElement
      disabled={disabled}
      fullWidth={fullWidth}
      helperText={helperText}
      InputProps={{
        inputProps: customInputProps,
        endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment>,
        size,
        sx: style,
        type
      }}
      label={label}
      name={name}
      required={required}
      validation={{
        required: (required ? translate('common.requiredField') : false),
        ...validation
      }}
      value={value}
      variant={variant}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
};

export default FormInputField;