import React from 'react';
import PropTypes from 'prop-types';
import { DialogTitle, Grid } from '@mui/material';
import { CloseButton } from './CloseButton';

export const ModalHeader = ({
  onClose, children, className, iconSize
}) => (
  <DialogTitle className={className} style={{ paddingRight: '4rem' }}>
    {children}
    <Grid alignItems="center" columnSpacing={2} container justifyContent="space-between">
      <Grid item>
        <CloseButton iconSize={iconSize} onClose={onClose} />
      </Grid>
    </Grid>
  </DialogTitle>
);

ModalHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string
};

ModalHeader.defaultProps = {
  className: ''
};