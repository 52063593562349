import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import { DocumentHelper, isMobile, translate } from 'utils';
import { useSnackbar } from 'notistack';
import { observer } from 'mobx-react-lite';
import { Button } from 'components/_commons/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MAX_FILE_SIZE } from 'utils/constants';
import { ICONS } from 'utils/icons';

const InputFileContainer = styled('div')(({ ...props }) => {
  const styles = {
    'input[type="file"]': {
      position: 'absolute',
      visibility: 'hidden',
      zIndex: -1
    },

    '@media (max-width: 560px)': {
      maxWidth: '260px'
    }
  };

  if (!props.disabled) {
    styles.label = { cursor: 'pointer' };
  }

  return styles;
});

const InputFile = observer(({
  label, labelVar, labelButton, document, handleAddDocument, maxSize, startIcon, id, disabled, fullWidth,
  fileAccepted, maxWidth, required, needResize, type, endIcon, text, variant, whiteSpace
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const inputFile = useRef(null);
  const handleFileChange = useCallback((event) => {
    const allFiles = event.target.files;
    DocumentHelper.handleFileUpload(event, needResize, maxSize)
      .then((doc) => {
        const e = event;
        handleAddDocument(doc, allFiles);
        e.target.value = null;
      })
      .catch((error) => {
        if (error?.message === 'documentTooBig') {
          enqueueSnackbar(translate('errors.documentTooBig', { maxSize: MAX_FILE_SIZE }), { variant: 'error' });
        } else {
          enqueueSnackbar(translate('errors.UNCATCHED_ERROR'), { variant: 'error' });
        }
      });
  }, [handleAddDocument, needResize, maxSize, enqueueSnackbar]);

  const isValid = Boolean((required && document) || !required);
  const customMaxWidth = isMobile() ? '170px' : maxWidth;

  return (
    <InputFileContainer disabled={disabled}>
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
        spacing={2}
        wrap="wrap"
      >
        {label && (
          <Grid item xs={4} zeroMinWidth>
            <Typography noWrap>
              {translate(label, labelVar)}
            </Typography>
          </Grid>
        )}
        <Grid item xs={label ? 8 : 12}>
          <Button
            disabled={disabled}
            fullWidth={fullWidth}
            isvalid={String(isValid)}
            maxwidth={customMaxWidth}
            type={type}
            variant={variant}
            whiteSpace={whiteSpace}
            onClick={() => inputFile.current.click()}
          >
            <Grid alignItems="center" container spacing={4} wrap="nowrap">
              {startIcon
                && (
                  <Grid item xs={1}>
                    <FontAwesomeIcon icon={startIcon} />
                  </Grid>
                )}

              <Grid item xs={12 - Boolean(endIcon) - Boolean(startIcon)}>
                {document?.name
                  ? (
                    <Typography noWrap>
                      {document.name}
                    </Typography>
                  )
                  : <Typography noWrap>{text ?? translate(labelButton)}</Typography>}
              </Grid>
              {endIcon
                && (
                  <Grid item xs={2}>
                    <Grid container justifyContent="flex-end" spacing={1} wrap="nowrap">
                      <Grid item>
                        <FontAwesomeIcon icon={endIcon} />
                      </Grid>
                      <Grid item>
                        <FontAwesomeIcon icon={ICONS.PLUS.icon} />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </Button>
        </Grid>
      </Grid>

      <input
        accept={fileAccepted}
        disabled={disabled}
        id={`${id}_file`}
        ref={inputFile}
        type="file"
        onChange={handleFileChange}
      />
    </InputFileContainer>
  );
});

InputFile.propTypes = {
  document: PropTypes.shape({}),
  disabled: PropTypes.bool,
  fileAccepted: PropTypes.string,
  handleAddDocument: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  label: PropTypes.string,
  labelButton: PropTypes.string,
  labelVar: PropTypes.shape({}),
  maxSize: PropTypes.number,
  maxWidth: PropTypes.string,
  needResize: PropTypes.bool
};

InputFile.defaultProps = {
  disabled: false,
  document: null,
  fileAccepted: '.pdf, .doc, .docx, .jpg, .jpeg, .png',
  id: 1,
  label: null,
  labelButton: 'forms.inputs.selectFile',
  labelVar: null,
  maxSize: MAX_FILE_SIZE,
  maxWidth: '300px',
  needResize: false
};

export default InputFile;