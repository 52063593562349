import React, { useCallback, useEffect, useState } from 'react';
import { Snackbar } from '@mui/material';
import { useStores } from 'hooks';
import { useSnackbar } from 'notistack';
import { Routes } from 'routes/routes';
import styled from 'styled-components';
import { KeyCloakUtils, StorageHelper, translate } from 'utils';
import { HealthcheckService } from 'services';
import { observer } from 'mobx-react-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignalAltSlash } from '@fortawesome/pro-regular-svg-icons';
import GoogleAnalytics from 'components/_commons/GoogleAnalytics/GoogleAnalytics';
import { frenchLocale } from 'stores/I18nStore';
import i18n from 'i18next';
import { SkeletonMain } from 'components/_commons/Skeletons';
import ErrorBoundary from './ErrorBoundary';

const Main = styled.main`
    flex: 1;
    padding-bottom: 6px;
    background-color: var(--white);
  `;

const CustomSnackBar = ({ open, message }) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    message={message}
    open={open}
  />
);

export const App = observer(() => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    i18nStore, userStore, enumOptionsStore
  } = useStores();
  const { isOffline } = userStore;
  const [isLoading, setIsLoading] = useState(true);
  const [isIntegrityCompromised, setIsIntegrityCompromised] = useState(false);
  const [translationLoaded, setTranslationsLoaded] = useState(false);

  i18n.on('languageChanged', () => setTranslationsLoaded(true));

  const checkHealth = useCallback(() => HealthcheckService.healthcheck()
    .then((healthcheck) => {
      userStore.setOffline(false);
      setIsIntegrityCompromised(false);

      const serviceNotOk = Object.values(healthcheck).find((status) => status !== 'UP');
      serviceNotOk && setIsIntegrityCompromised(true);
    })
    .catch(() => userStore.setOffline(true)), [userStore]);

  const checkToken = useCallback(() => {
    if (!userStore.isOffline) {
      if (userStore.keycloak && userStore.keycloak.authenticated && userStore.isTokenExpired()) {
        return userStore.refreshToken()
          .then(() => checkHealth())
          .catch(() => userStore.disconnectUser()
            .then(() => userStore.keycloakLogin()));
      }

      return checkHealth().catch((error) => enqueueSnackbar(error.message, { variant: 'error' }));
    }

    return null;
  }, [userStore, checkHealth, enqueueSnackbar]);

  useEffect(() => {
    // Persist storage on load
    StorageHelper.checkStoragePersisted();
  }, [enqueueSnackbar]);

  useEffect(() => {
    window.addEventListener('online', () => userStore.setOffline(!navigator.onLine));
    window.addEventListener('offline', () => userStore.setOffline(!navigator.onLine));

    userStore.setOffline(!navigator.onLine);

    i18nStore.loadLanguageList();

    KeyCloakUtils.init()
      .then((keycloak) => {
        userStore.connectUser(keycloak);

        if (keycloak.tokenParsed) {
          checkHealth();

          i18nStore.checkLanguage(keycloak.tokenParsed.locale || frenchLocale);
          i18nStore.loadLanguage(keycloak.tokenParsed.locale || frenchLocale);
          userStore.getCurrentScope()
            // eslint-disable-next-line no-console
            .catch((error) => console.error(error));
        } else if (localStorage.getItem('i18nextLng')) {
          i18nStore.checkLanguage(localStorage.getItem('i18nextLng'));
          i18nStore.loadLanguage(localStorage.getItem('i18nextLng'));
        }
      })
      .catch(() => {
        userStore.setOffline(!navigator.onLine);
        userStore.setIsConnecting(false);
      })
      .finally(() => {
        userStore.setIsConnecting(false);
        setIsLoading(false);

        if (!localStorage.getItem('i18nextLng')) {
          i18nStore.loadLanguage(frenchLocale);
        }
      });
  }, [i18nStore, userStore]);

  useEffect(() => {
    if (translationLoaded && !userStore.isCurrentScopeLoading) {
      const loadSharedData = async () => {
        enumOptionsStore.getAllAttestationFormRelatedEnums()
          // eslint-disable-next-line no-console
          .catch((error) => console.error(error));
      };

      // Load shared data
      loadSharedData().then();
    }
    // eslint-disable-next-line
  }, [translationLoaded, userStore, userStore.isCurrentScopeLoading]);

  // Set an interval to call the healthCheck service
  useEffect(() => {
    setInterval(() => {
      if (userStore.isConnected) {
        checkToken();
      }
    }, 1000 * 60);
  }, []);

  if (isLoading
    || !translationLoaded) {
    return <SkeletonMain />;
  }

  return (
    <ErrorBoundary>
      <CustomSnackBar
        message={(
          <>
            <FontAwesomeIcon className="mr1" icon={faSignalAltSlash} />
            {translate('errors.offline')}
          </>
        )}
        open={isOffline}
      />
      <CustomSnackBar message={translate('errors.integrityCompromised')} open={isIntegrityCompromised} />

      <Main>
        <Routes />
      </Main>
      <GoogleAnalytics />
    </ErrorBoundary>
  );
});