import { Tooltip } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import React from 'react';
import { isTablet } from 'utils';

export const GenericTooltip = ({
  children, title, open, onClickAwayTablet, ...props
}) => (isTablet()
  ? (
    <ClickAwayListener onClickAway={open && onClickAwayTablet}>
      <Tooltip
        disableFocusListener
        disableHoverListener
        disableTouchListener
        open={open}
        PopperProps={{
          disablePortal: true
        }}
        title={title}
        {...props}
      >
        {children}
      </Tooltip>
    </ClickAwayListener>
  )
  : (
    <Tooltip title={title} {...props}>
      {children}
    </Tooltip>
  ));