import { useEffect, useState } from 'react';
import { DocumentHelper } from './DocumentHelper';
import { FilterHelper } from './FilterHelper';
import { FormHelper } from './FormHelper';
import { IndexedDBHelper } from './IndexedDBHelper';
import { NavigatorHelper } from './NavigatorHelper';
import { RequestHelper } from './RequestHelper';
import { StorageHelper } from './StorageHelper';
import { UserHelper } from './UserHelper';
import { DateHelper } from './DateHelper';
import { Validators } from './ValidatorsHelper';
import { AttestationCategoryHelper } from './AttestationCategoryHelper';

const useDebounce = (value, delay = 2000) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export {
  DateHelper,
  DocumentHelper,
  FilterHelper,
  FormHelper,
  IndexedDBHelper,
  NavigatorHelper,
  RequestHelper,
  StorageHelper,
  UserHelper,
  Validators,
  AttestationCategoryHelper,
  useDebounce
};