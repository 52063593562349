import { FormContainer, useForm } from 'react-hook-form-mui';
import React, { useCallback } from 'react';
import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { translate } from 'utils';
import { DialogContent } from '@mui/material';
import { ModalFormField } from 'components/_commons/Modals/_ModalFormField';
import { FormInputField } from 'components/_commons/Form/Inputs';
import { CustomActionButtons } from 'components/_commons/Modals/_CustomActionButtons';

export const AddAlertModal = ({
  onClose,
  onSubmit
}) => {
  const formContext = useForm();

  const onSubmitModal = useCallback((submittedFields) => {
    onSubmit(submittedFields);
  }, [onSubmit]);

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{ autoComplete: 'off', name: 'addAlertForm' }}
      onSuccess={onSubmitModal}
    >
      <ModalHeader onClose={onClose}>
        {translate('attestationFormRequest.alerts.modalCreate.title')}
      </ModalHeader>

      <DialogContent style={{ width: '50vw', marginTop: '30px' }}>
        <ModalFormField title={translate('attestationFormRequest.alerts.modalCreate.commentTitle')}>
          <FormInputField
            fullWidth
            label={translate('attestationFormRequest.alerts.modalCreate.commentLabel')}
            name="description"
            required
          />
        </ModalFormField>
      </DialogContent>

      <CustomActionButtons onClose={onClose} />
    </FormContainer>
  );
};