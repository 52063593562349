import { Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Progress } from 'components/_commons/Progress/Progress';
import { ToolsModalStepsEnum } from 'core/enums';
import React from 'react';
import { translate } from 'utils';

const StyledContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey.lighter,
  padding: '10px',
  borderRadius: '2px'
}));

export const AddToolModalActions = ({
  onClose, goBack, onNextStep, disabled, currentStep
}) => (
  <StyledContainer>
    <Grid alignItems="center" container justifyContent="space-between" spacing={1}>
      <Grid item xs={4}>
        <div className="display-flex">
          <Button color="secondary" variant="contained" onClick={onClose}>
            {translate('button.cancel')}
          </Button>
          {!ToolsModalStepsEnum.isFirst(currentStep) && (
            <div className="ml1">
              <Button className="ml1" color="primary" disabled={disabled} variant="contained" onClick={goBack}>
                {translate('button.previous')}
              </Button>
            </div>
          )}
        </div>
      </Grid>
      <Grid item xs={currentStep === ToolsModalStepsEnum.Registration ? 1 : 4}>
        {!ToolsModalStepsEnum.isFirst(currentStep) && (
          <Progress value={ToolsModalStepsEnum.Tools[currentStep].percent} variant="determinate" />
        )}
      </Grid>
      <Grid item xs={currentStep === ToolsModalStepsEnum.Registration ? 7 : 4}>
        <div className="flex-end">
          <Button
            color={currentStep === ToolsModalStepsEnum.Registration ? 'secondary' : 'primary'}
            disabled={disabled}
            type="submit"
            variant="contained"
          >
            {translate(ToolsModalStepsEnum.Tools[currentStep].nextStepLabel)}
          </Button>
          {currentStep === ToolsModalStepsEnum.Registration && (
            <div className="ml1">
              <Button
                color="primary"
                disabled={disabled}
                variant="contained"
                onClick={onNextStep}
              >
                {translate('attestationFormRequest.tools.modal.addToolJustification')}
              </Button>
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  </StyledContainer>
);