import { Grid, Typography } from '@mui/material';

import { Document } from 'components/_commons/Document/Document';
import InfoLine from 'components/_commons/InfoLine/InfoLine';
import React from 'react';
import shortid from 'shortid';
import { translate } from 'utils';

import { DateHelper, Validators } from 'utils/helpers';

export const SummaryStep = ({
  detentionTypes, detentionTypesLoading, verificationTypes, verificationTypesLoading, formContext
}) => {
  const formValues = formContext.getValues();

  const renderDocumentSummaryByTypes = (documentType, title) => {
    const documents = Object.keys(formValues?.equipmentView?.documentList?.innerDocuments ?? {})
      .reduce((acc, prev) => ([...acc, ...formValues.equipmentView.documentList.innerDocuments[prev]]), [])
      .filter((doc) => documentType === doc.type.name);
    return Boolean(documents.length) && (
      <Grid item>
        <Grid container direction="column">
          <Grid item xs={12}>
            <Typography>{title}</Typography>
          </Grid>
          <Grid item xs={12}>
            {documents.map((document) => (
              <Grid alignItems="flex-start" container justifyContent="space-between" key={shortid.generate()} wrap="nowrap">
                <Grid item lg={10} md={9} xs={7}>
                  <Document
                    base64Content={document.base64Content}
                    deletable={false}
                    name={document.name}
                  />
                </Grid>
                <Grid item lg={2} md={3} xs={5}>
                  <Typography>{DateHelper.formatDate(document.documentDate)}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const detentionDocumentsSummary = detentionTypes.map((type) => renderDocumentSummaryByTypes(type.value, type.label));
  const verificationDocumentsSummary = verificationTypes.map((type) => renderDocumentSummaryByTypes(type.value, type.label));

  return (
    <Grid alignItems="flex-start" container justifyContent="center" spacing={2} wrap="wrap">
      <Grid item md={6} xs={10}>
        <Typography style={{ margin: '1.5rem 0', fontWeight: 600 }} variant="h6">
          {translate('attestationFormRequest.tools.modal.title.generalSummary')}
        </Typography>

        <InfoLine
          info={Validators.isCurrentToolNew(
            formValues?.equipmentView?.wasNewAtAcquisition,
            formValues?.equipmentView?.acquisitionDate
          )
            ? translate('common.yes')
            : translate('common.no')}
          label="attestationFormRequest.tools.modal.newToolLabel"
        />

        <InfoLine
          info={formValues?.equipmentView?.brand?.name}
          label="attestationFormRequest.tools.table.brand"
        />

        <InfoLine
          info={formValues?.equipmentView?.model}
          label="attestationFormRequest.tools.table.model"
        />

        <InfoLine
          info={formValues?.equipmentView?.serialNumber}
          label="attestationFormRequest.tools.table.serialNumber"
        />

        <InfoLine
          info={formValues?.equipmentView?.acquisitionDate}
          label="attestationFormRequest.tools.table.acquisition"
        />

        <InfoLine
          info={formValues?.equipmentView?.comment}
          label="attestationFormRequest.tools.table.comment"
        />
      </Grid>
      <Grid item md={6} xs={10}>
        <Grid container direction="column" spacing={2}>
          {!detentionTypesLoading && Boolean(detentionDocumentsSummary.length) && (
            <Grid item xs={12}>
              <Typography style={{ margin: '1.5rem 0', fontWeight: 600 }} variant="h6">
                {translate('attestationFormRequest.tools.table.detention')}
              </Typography>
              <Grid container direction="column" spacing={1}>
                {detentionDocumentsSummary}
              </Grid>
            </Grid>
          )}
          {!verificationTypesLoading && Boolean(verificationDocumentsSummary.length) && (
            <Grid item xs={12}>
              <Typography style={{ margin: '1.5rem 0', fontWeight: 600 }} variant="h6">
                {translate('attestationFormRequest.tools.table.verification')}
              </Typography>
              <Grid container direction="column" spacing={1}>
                {verificationDocumentsSummary}
              </Grid>
            </Grid>
          )}
        </Grid>

      </Grid>
    </Grid>
  );
};