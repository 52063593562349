import { styled } from '@mui/material/styles';

export const Wrapper = styled('section')(({ theme, ...props }) => {
  const styles = {
    maxWidth: theme.sizes.wrapperWidthMax,
    minWidth: theme.sizes.wrapperWidthMin,
    margin: '0 auto',
    padding: '0 2rem'
  };

  if (props.height) {
    styles.height = props.height;
  }

  return styles;
});

export const SmallWrapper = styled(Wrapper)(({ theme }) => ({
  maxWidth: theme.sizes.smallWrapperWidthMax
}));

export const LargeWrapper = styled(Wrapper)(({ theme }) => ({
  maxWidth: theme.sizes.largeWrapperWidthMax
}));

export const FullWrapper = styled(Wrapper)(() => ({
  width: '100%',
  maxWidth: 'none'
}));