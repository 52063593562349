import React from 'react';
import { AutocompleteElement } from 'react-hook-form-mui';
import shortid from 'shortid';
import { translate } from 'utils';

export const getOptionLabelWithInputValue = (option) => {
  // Value selected with enter, right from the input
  if (typeof option === 'string') {
    return option;
  }
  // Add "xxx" option created dynamically
  if (option.inputValue) {
    return option.inputValue;
  }
  // Regular option
  return option.title;
};

const FormSelectField = ({
  label,
  placeholder,
  required,
  variant = 'standard',
  size,
  fullWidth,
  disableClearable,
  isOptionEqualToValue = (option, value) => option.value === value.value,
  name,
  disablePortal = true,
  options,
  filterSelectedOptions = false,
  loadingText,
  noOptionsText,
  loading,
  getOptionLabel,
  filterOptions,
  onChange,
  autoSelect = false,
  freeSolo = false,
  onOpen,
  onInputChange,
  disabled,
  renderOption,
  multiple = false,
  key = 'autocompleteElement'
}) => (
  <AutocompleteElement
    autocompleteProps={{
      disablePortal,
      loadingText,
      noOptionsText,
      size,
      fullWidth,
      disabled,
      getOptionLabel,
      filterOptions,
      autoSelect,
      freeSolo,
      onChange,
      onOpen: () => onOpen && onOpen(),
      onInputChange: (event, value, reason) => {
        if (reason === 'input' && onInputChange) {
          onInputChange(value);
        }
      },
      disableClearable,
      isOptionEqualToValue,
      renderOption,
      filterSelectedOptions
    }}
    key={key || shortid.generate()}
    label={label}
    loading={loading}
    multiple={multiple}
    name={name}
    options={options}
    required={required}
    rules={{
      required: (required ? translate('common.requiredField') : false)
    }}
    textFieldProps={{
      label,
      placeholder,
      variant,
      InputProps: {}
    }}
  />
);

export default FormSelectField;