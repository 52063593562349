import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid, IconButton, Tooltip, Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Buffer } from 'buffer';
import React, { useCallback } from 'react';
import { DocumentHelper, translate } from 'utils';
import { Spinner } from '../Skeletons';

const MainContainer = styled('div')(({ isPdf }) => ({
  overflowX: 'hidden',
  overflowY: isPdf ? 'hidden' : 'auto',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

export const DocumentDisplay = ({
  base64Content, documentName, downloadLabel, height, isLoadingContent = false, width
}) => {
  const isPdf = DocumentHelper.isPDFWithBase64(base64Content);
  const theme = useTheme();

  const downloadDocument = useCallback(() => {
    DocumentHelper.downloadDocumentFromBase64(base64Content, documentName);
  }, [base64Content]);

  const DocumentContainer = styled('object')(() => {
    let styles;
    if (isPdf) {
      styles = { width: '100%', minHeight: '100%' };
    } else {
      styles = { maxWidth: '100%', padding: '15px' };
    }
    return styles;
  });

  const renderCantVisualize = useCallback((label) => (
    <Grid alignItems="center" container direction="column" height="100%" item justifyContent="center" spacing={2}>
      <Grid item>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item>
        <Typography>{documentName}</Typography>
      </Grid>
      <Grid item>
        <Tooltip title={translate('common.downloadDocument')}>
          <IconButton onClick={downloadDocument}>
            <FontAwesomeIcon
              color={theme.palette.primary.main}
              icon={faDownload}
              size="2x"
            />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  ), [downloadDocument]);

  const renderDocumentContent = useCallback(() => (
    DocumentHelper.isVisualizable({ name: documentName })
      ? (
        <DocumentContainer data={base64Content}>
          {renderCantVisualize(translate('common.documentCantBeVisualized'))}
        </DocumentContainer>
      )
      : renderCantVisualize(translate('common.documentCantBeVisualized'))
  ), [base64Content, documentName, renderCantVisualize]);

  const renderDocument = useCallback(() => {
    if (isLoadingContent) {
      return <Spinner style={{ width: '100%', minHeight: '100%' }} />;
    }

    const contentLength = Buffer.from(DocumentHelper.getContentWithoutBase64Typing(base64Content), 'base64').length / 1000;

    if (contentLength > 1450) {
      return renderCantVisualize(downloadLabel);
    }
    return renderDocumentContent();
  }, [isLoadingContent, renderCantVisualize, renderDocumentContent]);

  return (
    <MainContainer isPdf>
      <div style={isPdf ? { height, width } : {}}>
        {renderDocument()}
      </div>
    </MainContainer>
  );
};