import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

export const Progress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 8,
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: theme.palette.primary.main,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.secondary.main
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 6,
    backgroundColor: theme.palette.primary.main
  }
}));