import React from 'react';
import { Grid, Typography } from '@mui/material';
import { translate } from 'utils';

export const ModalSectionTitle = ({ title, subtitle, name }) => (
  <Grid alignItems="center" container direction="row" item spacing={2}>
    <Grid item>
      <img alt="icon" height="30px" src={`${process.env.PUBLIC_URL}/assets/images/icons/rectangle.svg`} />
    </Grid>
    <Grid item>
      <Typography variant="h4">{translate(title, { name })}</Typography>
      {subtitle && <Typography style={{ marginTop: '8px' }} variant="h6">{translate(subtitle)}</Typography>}
    </Grid>
  </Grid>
);