import { Card } from '@mui/material';
import React from 'react';
import { WithLeftIconText } from 'components/_commons/Text';
import { translate } from 'utils';
import { faFileZipper } from '@fortawesome/pro-solid-svg-icons';
import { styled } from '@mui/material/styles';

const ToggleButton = styled(Card)(({ theme, ...props }) => ({
  padding: '2rem 2.5rem',
  cursor: 'pointer',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  borderColor: props.color.main,
  backgroundColor: theme.palette.primary.contrastText,

  '&:hover': {
    backgroundColor: props.color.lightest
  }
}));

export const DecisionButton = ({
  color, onClick, label, description
}) => (
  <ToggleButton
    color={color}
    variant="outlined"
    onClick={onClick}
  >
    <WithLeftIconText
      color={color.main}
      description={description && translate(description)}
      fontWeight="bold"
      icon={faFileZipper}
      label={translate(label)?.toUpperCase()}
      labelColor={color.main}
      spacing={1}
    />
  </ToggleButton>
);