import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TruncatedText } from 'components/_commons/Text';
import React from 'react';

const TitleTypography = styled(Typography)(() => ({
  fontSize: '20px',
  fontWeight: 'bold',
  clear: 'both',
  display: 'inline-block',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
}));

export const TecneaTitle = (({ label }) => (
  <Grid alignItems="center" columnSpacing={2} container direction="row" wrap="nowrap">
    <Grid item>
      <img
        alt="icon"
        height="30px"
        src={`${process.env.PUBLIC_URL}/assets/images/icons/rectangle.svg`}
      />
    </Grid>
    <Grid item xs>
      <TitleTypography component="div">
        <TruncatedText text={label} />
      </TitleTypography>
    </Grid>
  </Grid>
));