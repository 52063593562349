import { DocumentHelper, RequestHelper } from 'utils';
import { API_ROUTES } from 'utils/constants';

// DELETE
const deleteAttestationFormDocument = (attestationFormDocumentId) => (
  RequestHelper.DELETE(API_ROUTES.DOCUMENTS.DOCUMENT(attestationFormDocumentId))
);
const validateDocument = (documentId, documentValidation) => (
  RequestHelper.POST(API_ROUTES.DOCUMENTS.VALIDATION(documentId), documentValidation)
);
const invalidateDocument = (documentId) => (
  RequestHelper.DELETE(API_ROUTES.DOCUMENTS.VALIDATION(documentId))
);

const updateDocumentType = (documentId, type, data) => (
  RequestHelper.PUT(API_ROUTES.DOCUMENTS.TYPE(documentId, type), data)
);

const getImportExampleFile = ((fileIdentifier) => (
  RequestHelper.GET(`${API_ROUTES.IMPORT_FILES.EXAMPLE(fileIdentifier)}`).then((response) => {
    DocumentHelper.handleExcelFileDownload(response);
  })
));

const downloadDocument = (documentId) => RequestHelper.GET(API_ROUTES.DOCUMENTS.FILE_TRANSFER(documentId))
  .then((resp) => DocumentHelper.downloadDocumentFromBase64(resp.base64Content, resp.name));

const getFileTransferModel = (documentId) => RequestHelper.GET(API_ROUTES.DOCUMENTS.FILE_TRANSFER(documentId));
const getDocumentsWithContent = (documents) => RequestHelper.POST(API_ROUTES.DOCUMENTS.CONTENT(), documents);

export const DocumentService = {
  deleteAttestationFormDocument,
  validateDocument,
  invalidateDocument,
  updateDocumentType,
  getImportExampleFile,
  getFileTransferModel,
  getDocumentsWithContent,
  downloadDocument
};