import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogActions, DialogContent, DialogTitle, Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Text } from 'components/_commons/Text';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { DocumentHelper, translate } from 'utils';
import { MAX_FILE_SIZE } from 'utils/constants';
import { ICONS } from '../../../utils/icons';

const StyledFileUpload = styled('input')({
  width: '100%'
});

export const ImportModal = ({
  onConfirm, onClose, closeOnSubmit, defaultValues, tooltip
}) => {
  const [document, setDocument] = useState({});
  const [error, setError] = useState('');

  const theme = useTheme();

  const handleValidateModal = useCallback((e) => {
    e.preventDefault();

    if (!document) {
      return setError(translate('errors.noImportFile'));
    }

    const sendFile = {
      name: document.name,
      base64Content: DocumentHelper.getDocumentWithoutBase64(document)
    };

    onConfirm(sendFile);

    if (closeOnSubmit) {
      return onClose();
    }

    return null;
  }, [onConfirm, onClose, closeOnSubmit, document]);

  const handleFile = (event) => {
    DocumentHelper.handleFileUpload(event, false, MAX_FILE_SIZE)
      .then((data) => setDocument(data));
  };

  return (
    <form onSubmit={handleValidateModal}>
      <DialogTitle>
        <FontAwesomeIcon icon={ICONS.IMPORT.icon} />
        {translate('modalImport.import')}
      </DialogTitle>

      <DialogContent>
        {tooltip && (
          <Typography>
            {translate(tooltip)}
          </Typography>
        )}
        <br />
        {defaultValues && defaultValues.message && (
          <Text color={theme.palette.success.light}>{defaultValues.message}</Text>
        )}

        {error && <Text color={theme.palette.error.main} fontWeight={600} margin="0 0 1rem 0">{error}</Text>}

        <StyledFileUpload
          accept=".xlsx, .xls, .csv"
          placeholder={translate('modalImport.import')}
          type="file"
          width="100%"
          onChange={handleFile}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{translate('button.cancel')}</Button>
        <Button color="primary" disabled={!document} type="submit">{translate('button.import')}</Button>
      </DialogActions>
    </form>
  );
};

ImportModal.propTypes = {
  closeOnSubmit: PropTypes.bool,
  defaultValues: PropTypes.shape({}),
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

ImportModal.defaultProps = {
  closeOnSubmit: true,
  defaultValues: null
};