import { Grid } from '@mui/material';
import React from 'react';
import { isTablet } from 'utils';

export const TwoColumnGrid = ({ children, keys }) => {
  const getFirstHalf = (list) => list.slice(0, Math.ceil(list.length / 2));
  const getSecondHalf = (list) => list.slice(Math.ceil(list.length / 2));

  return (
    <Grid container direction={isTablet() ? 'column' : 'row'} spacing={3}>
      <Grid item sm={6} width="100%" xs={12}>
        <Grid container direction="column" spacing={3}>
          {getFirstHalf(children).map((child, index) => (
            <Grid item key={getFirstHalf(keys)[index]} width="100%">
              {child}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item sm={6} width="100%" xs={12}>
        <Grid container direction="column" spacing={3}>
          {getSecondHalf(children).map((child, index) => (
            <Grid item key={getSecondHalf(keys)[index]} width="100%">
              {child}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};