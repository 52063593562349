import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid, Step, StepButton, Stepper, Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { AlertStatusEnum, AlertTypeEnum } from 'components/_commons/Alert/alert.enum';
import { AlertBlock } from 'components/_commons/Alert/AlertBlock';
import { TextButton } from 'components/_commons/Button/TextButton';
import { StepperEyeIcon } from 'components/_commons/Icon/StepperEyeIcon';
import { Wrapper } from 'components/_commons/Wrapper';
import { AttestationFormActions } from 'components/AttestationForm/AttestationFormActions';
import { RequestStatusEnum } from 'components/AttestationForm/attestationFormRequestStatus.enum';
import { attestationFormFilteredSteps, SectionEnum } from 'components/AttestationForm/attestationFormSection.enum';
import { WarningHeader } from 'components/Header/WarningHeader';
import { useAccessRights, useFetch, useModal } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { AttestationFormRequestService } from 'services';
import { AttestationEvaluationService } from 'services/AttestationEvaluationService';
import { isMobile, translate } from 'utils';
import {
  APPLICATION_MANAGEMENT_ROLES,
  ATTESTATION_FORM_ACTIONS, ATTESTATION_FORM_STEPS, ROUTES
} from 'utils/constants';
import { iconEnum } from 'utils/icons';
import {
  Conclusion, Contacts, Operators, PurchaseOrder, ToolsStep, Tracability, Transmission
} from './_sections';

const StyledArticle = styled('article')(() => ({
  marginBottom: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledAttestationForm = styled('section')(({ theme }) => ({
  position: 'relative',
  margin: '0 auto',
  '@media (max-width: 768px)': {
    header: {
      position: 'sticky',
      top: 0,
      borderBottom: `1px solid ${theme.palette.grey.light}`
    },

    footer: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      padding: '1rem',
      zIndex: theme.zIndex.big,
      background: theme.palette.common.white,
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)',

      '.submitButton': {
        width: '100%'
      }
    }
  },

  '@media (min-width: 769px)': {
    footer: {
      marginTop: '4rem'
    }
  },

  header: {
    width: '100%',
    padding: '1rem 0',
    marginBottom: '1rem',
    background: theme.palette.common.white,
    zIndex: theme.zIndex.small,

    h2: {
      margin: 0,
      color: theme.palette.primary.main
    }
  },

  '.MuiStepper-root': {
    paddingLeft: 0
  },

  '.MuiStepLabel-label p': {
    fontWeight: 'bold !important'
  }
}));

const AttestationFormElement = styled('div')(({ ...props }) => ({
  padding: props.isMobile ? '1rem 0' : '5rem 0'
}));

export const AttestationForm = observer((() => {
  const showModal = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { requestId } = useParams();
  const { response: requestGlobalView, isLoading: isGlobalViewLoading, setResponse: setRequestGlobalView } = useFetch(
    () => AttestationFormRequestService.getRequestGlobalView(requestId),
    {
      currentRequestId: undefined,
      availableActions: [],
      status: RequestStatusEnum.INCOMPLETE,
      lastVisitedSection: SectionEnum.PurchaseOrder
    }
  );
  const [formAlerts, setFormAlerts] = useState([]);
  const [formAlertsLoading, setFormAlertsLoading] = useState(false);
  const [formCurrentStep, setFormCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([0]);
  const navigate = useNavigate();
  const theme = useTheme();
  const [withAlertsToInstruct, setWithAlertsToInstruct] = useState(false);
  const [withAlertsToSupervise, setWithAlertsToSupervise] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const currentStepEnum = ATTESTATION_FORM_STEPS[formCurrentStep].section;
  const hasManagerAccessRights = useAccessRights({ roles: APPLICATION_MANAGEMENT_ROLES });
  const [unreadDocumentAlerts, setUnreadDocumentAlerts] = useState([]);
  const canValidate = requestGlobalView?.availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_DOCUMENT_VALIDATION);
  const updateUnreadDocumentAlerts = () => AttestationEvaluationService.getUnreadDocumentAlerts(requestId)
    .then((resp) => setUnreadDocumentAlerts(resp));

  useEffect(() => {
    if (canValidate) {
      updateUnreadDocumentAlerts();
    }
  }, [canValidate]);

  const filterAlertsByStepAndStatus = useCallback(
    (formStep) => formAlerts.filter((alert) => {
      const withDocumentaryAlerts = alert.type.value !== AlertTypeEnum.Documentary || !canValidate;
      return (
        (formStep == null || alert.section.value === formStep)
          && ((withDocumentaryAlerts && alert.status.value === AlertStatusEnum.Unresolved)
            || (withAlertsToInstruct && alert.status.value === AlertStatusEnum.Resolved)
            || (withAlertsToSupervise && alert.status.value === AlertStatusEnum.ResolvedInstructor))
      );
    }),
    [formAlerts, canValidate, withAlertsToInstruct, withAlertsToSupervise]
  );

  const currentStepAlerts = useMemo(
    () => filterAlertsByStepAndStatus(currentStepEnum),
    [filterAlertsByStepAndStatus, currentStepEnum]
  );

  const isInstructingOrSupervising = requestGlobalView.availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_INSTRUCT)
    || requestGlobalView.availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_SUPERVISE);

  const filteredSteps = useMemo(
    () => attestationFormFilteredSteps(isInstructingOrSupervising, requestGlobalView.availableActions),
    [ATTESTATION_FORM_STEPS, requestGlobalView]
  );

  useEffect(() => {
    setWithAlertsToInstruct(requestGlobalView.status === RequestStatusEnum.INSTRUCTING);
    setWithAlertsToSupervise(requestGlobalView.status === RequestStatusEnum.SUPERVISING);
  }, [requestGlobalView]);

  const goToNextStep = useCallback(() => {
    const nextStep = formCurrentStep + 1;
    setFormCurrentStep(nextStep);
    if (!requestGlobalView.lastVisitedSection
      || ATTESTATION_FORM_STEPS.find((s) => s.section === requestGlobalView.lastVisitedSection)?.id <= nextStep) {
      const newLastSection = ATTESTATION_FORM_STEPS[nextStep].section;
      AttestationFormRequestService.setLastVisitedStep(requestId, newLastSection)
        .then(() => setRequestGlobalView({ ...requestGlobalView, lastVisitedSection: newLastSection }));
    }
    return setCompletedSteps([...completedSteps, nextStep]);
  }, [formCurrentStep, completedSteps, setRequestGlobalView, requestGlobalView]);

  const reloadAlerts = useCallback(() => {
    setFormAlertsLoading(true);
    AttestationFormRequestService.getAllAlerts(requestId)
      .then((alerts) => setFormAlerts(alerts))
      .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }))
      .finally(() => setFormAlertsLoading(false));
  }, [enqueueSnackbar]);

  const handlePreviousStep = useCallback(() => setFormCurrentStep(formCurrentStep - 1), [formCurrentStep, setFormCurrentStep]);

  const handleNextStep = useCallback(() => {
    if (formCurrentStep < filteredSteps.length) {
      goToNextStep();
    }
    return null;
  }, [formCurrentStep, filteredSteps, goToNextStep]);

  const updateFormAlerts = useCallback((section) => {
    setFormAlertsLoading(true);
    AttestationFormRequestService.validateSection(requestId, section)
      .then((alerts) => setFormAlerts(alerts))
      .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }))
      .finally(() => setFormAlertsLoading(false));
  }, [enqueueSnackbar]);

  const handleAddAlert = useCallback(() => showModal({
    type: 'ADD_ALERT',
    onSubmit: (submittedAlert) => {
      AttestationFormRequestService.createAlert(requestId, { ...submittedAlert, section: { value: currentStepEnum } })
        .then((alert) => {
          enqueueSnackbar(translate('attestationFormRequest.alerts.snackbar.created'), { variant: 'success' });
          setFormAlerts([...formAlerts, alert]);
        })
        .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
    },
    closeOnSubmit: true
  }), [showModal, enqueueSnackbar, formAlerts, currentStepEnum]);

  const handleEditRequest = useCallback(() => (showModal({
    type: 'CONFIRM',
    title: 'attestationFormRequest.actions.editSubmittedRequest',
    content: translate('attestationFormRequest.confirmation.modificationSubmittedRequest'),
    onConfirm: () => {
      AttestationFormRequestService.modifyRequest(requestId)
        .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }))
        .then(() => navigate(0));
    },
    confirmLabel: 'button.confirm',
    closeOnSubmit: true
  })), [navigate, enqueueSnackbar, requestId, showModal]);

  const handleRequestUpdate = useCallback(() => (showModal({
    type: 'CONFIRM',
    title: 'attestationFormRequest.actions.requestUpdate',
    content: translate('attestationFormRequest.confirmation.requestUpdate'),
    onConfirm: () => {
      AttestationFormRequestService.requestUpdate(requestId)
        .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }))
        .then((newRequestId) => {
          navigate(generatePath(ROUTES.ATTESTATION_FORM, { requestId: newRequestId }));
          navigate(0);
        });
    },
    confirmLabel: 'button.confirm',
    closeOnSubmit: true
  })), [navigate, enqueueSnackbar, requestId, showModal]);

  const handleSeeHistory = useCallback(() => {
    if (requestGlobalView?.institutionId) {
      navigate(generatePath(ROUTES.INSTITUTION.HISTORY, { institutionId: requestGlobalView.institutionId }));
    }
  }, [navigate, requestGlobalView]);

  const handleQuit = () => {
    // TODO : handle quit action
    if (requestGlobalView?.institutionId) {
      navigate(generatePath(ROUTES.INSTITUTION.DETAIL, { institutionId: requestGlobalView.institutionId }));
    }
  };

  const handleStartInstruction = useCallback(() => showModal({
    type: 'CONFIRM',
    title: 'attestationFormRequest.actions.startInstruction',
    content: translate('attestationFormRequest.confirmation.startInstruction'),
    onConfirm: () => {
      AttestationEvaluationService.startInstruction(requestId)
        .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }))
        .then(() => navigate(0));
    },
    confirmLabel: 'button.confirm',
    closeOnSubmit: true
  }), [showModal, enqueueSnackbar, navigate]);

  const handleStartSupervision = useCallback(() => showModal({
    type: 'CONFIRM',
    title: 'attestationFormRequest.actions.startSupervision',
    content: translate('attestationFormRequest.confirmation.startSupervision'),
    onConfirm: () => {
      AttestationEvaluationService.startSupervision(requestId)
        .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }))
        .then(() => navigate(0));
    },
    confirmLabel: 'button.confirm',
    closeOnSubmit: true
  }), [showModal, enqueueSnackbar, navigate]);

  const getAlertsByAlertStatus = useCallback(
    (alertStatus) => formAlerts.filter((alert) => alert.status.value === alertStatus),
    [formAlerts]
  );

  const renderStepIcon = useCallback((section) => {
    const stepAlerts = (section.section === SectionEnum.Transmission
      ? filterAlertsByStepAndStatus()
      : filterAlertsByStepAndStatus(section.section));

    const canConcludeSupervision = requestGlobalView.status === RequestStatusEnum.SUPERVISING;
    const canConcludeInstruction = requestGlobalView.status === RequestStatusEnum.INSTRUCTING
        && !(getAlertsByAlertStatus(AlertStatusEnum.Resolved).length
            || unreadDocumentAlerts.length);

    if (stepAlerts.length
        || ((section.section === SectionEnum.Conclusion
            && !canConcludeSupervision
            && !canConcludeInstruction
        ))) {
      return (
        <FontAwesomeIcon
          color={iconEnum.xMarkCircle.defaultColor}
          icon={formCurrentStep === section.id - 1 ? iconEnum.xMarkCircle.icon : iconEnum.xMarkCircle.solidIcon}
          size="2x"
        />
      );
    }
    if (unreadDocumentAlerts.filter((alert) => alert.section.value === section.section).length) {
      return <StepperEyeIcon current={formCurrentStep === section.id - 1} size="2x" />;
    }

    return (
      <FontAwesomeIcon
        color={iconEnum.checkCircle.defaultColor}
        icon={formCurrentStep === section.id - 1 ? iconEnum.checkCircle.icon : iconEnum.checkCircle.solidIcon}
        size="2x"
      />
    );
  }, [formCurrentStep, filterAlertsByStepAndStatus, unreadDocumentAlerts, requestGlobalView]);

  const renderSteps = useCallback(() => filteredSteps
    .map((section, index) => {
      const completed = section.id <= ATTESTATION_FORM_STEPS.find((s) => s.section === requestGlobalView?.lastVisitedSection)?.id;
      return (
        <Step
          completed={completed}
          disabled={!completed}
          key={section.id}
        >
          <StepButton
            icon={(requestGlobalView.status !== RequestStatusEnum.INCOMPLETE || completed) && renderStepIcon(section)}
            sx={{
              padding: '24px 8px',
              margin: '-24px -8px'
            }}
            onClick={() => setFormCurrentStep(index)}
          >
            <Typography
              color={formCurrentStep === section.id - 1 && theme.palette.primary.main}
              fontWeight={formCurrentStep === section.id - 1 && 'bold'}
            >
              {translate(section.label)}
            </Typography>
          </StepButton>
        </Step>
      );
    }), [requestGlobalView, completedSteps, setFormCurrentStep, renderStepIcon]);

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid
        item
        style={{
          position: 'sticky',
          top: 0,
          zIndex: theme.zIndex.medium
        }}
      >
        {!isGlobalViewLoading && requestGlobalView.currentRequestId
          ? (
            <WarningHeader>
              <Typography color={theme.palette.warning.contrastText}>
                {translate('attestationFormRequest.oldVersion')}
                {' '}
                <TextButton
                  label={translate('attestationFormRequest.click')}
                  styles={{ width: 'fit-content', fontWeight: 'bold', color: theme.palette.warning.contrastText }}
                  onClick={() => navigate(generatePath(ROUTES.ATTESTATION_FORM, { requestId: requestGlobalView.requestId }))}
                />
              </Typography>
            </WarningHeader>
          )
          : (!isGlobalViewLoading && requestGlobalView.availableActions.length === 0
            && (
              <WarningHeader>
                <Typography color={theme.palette.warning.contrastText}>
                  {translate(hasManagerAccessRights
                    ? 'attestationFormRequest.cannotEditAsManager'
                    : 'attestationFormRequest.cannotEdit')}
                </Typography>
              </WarningHeader>
            ))}
      </Grid>
      <Grid item>
        <StyledAttestationForm>
          {
            isMobile() ? (
              <Wrapper>
                <Stepper>
                  <Step>
                    <StepButton
                      icon={renderStepIcon(filteredSteps[formCurrentStep])}
                      sx={{
                        padding: '24px 8px',
                        margin: '-24px -8px'
                      }}
                    >
                      <Typography color={theme.palette.primary.main} fontWeight="bold">
                        {translate(filteredSteps[formCurrentStep].label)}
                      </Typography>
                    </StepButton>
                  </Step>
                </Stepper>
              </Wrapper>
            ) : (
              <Stepper activeStep={formCurrentStep} alternativeLabel style={{ margin: 'auto', marginTop: 50 }}>
                {renderSteps()}
              </Stepper>
            )
          }
          <AttestationFormElement isMobile={isMobile()} name="attestationFormRequest">
            {ATTESTATION_FORM_STEPS[formCurrentStep] && (
              <StyledArticle>
                <Grid container item lg={9} md={11} spacing={4} xs={11}>
                  <Grid item xs={12}>
                    {Boolean(currentStepAlerts?.length)
                      && (
                        <AlertBlock
                          alerts={currentStepAlerts}
                          availableActions={requestGlobalView.availableActions}
                          canDisplayDocumentaryAlert={!canValidate}
                          isLoading={formAlertsLoading}
                          reloadAlerts={reloadAlerts}
                        />
                      )}
                  </Grid>
                  <Grid item xs={12}>
                    {[
                      <PurchaseOrder
                        availableActions={requestGlobalView?.availableActions}
                        key={0}
                        requestId={requestId}
                        setFormAlerts={setFormAlerts}
                        updateAlerts={updateFormAlerts}
                      />,
                      <Contacts
                        availableActions={requestGlobalView?.availableActions}
                        key={1}
                        requestId={requestId}
                        updateAlerts={updateFormAlerts}
                      />,
                      <Operators
                        alertedOperators={currentStepAlerts.map((alert) => alert?.codeDetail)}
                        availableActions={requestGlobalView?.availableActions}
                        institutionId={requestGlobalView.institutionId}
                        key={2}
                        requestId={requestId}
                        showHistory={showHistory}
                        updateAlerts={updateFormAlerts}
                        updateUnreadDocumentAlerts={updateUnreadDocumentAlerts}
                      />,
                      <ToolsStep
                        alertedTools={currentStepAlerts.map((alert) => alert?.codeDetail)}
                        availableActions={requestGlobalView?.availableActions}
                        institutionId={requestGlobalView.institutionId}
                        key={3}
                        requestId={requestId}
                        showHistory={showHistory}
                        updateAlerts={updateFormAlerts}
                        updateUnreadDocumentAlerts={updateUnreadDocumentAlerts}
                      />,
                      <Tracability
                        availableActions={requestGlobalView.availableActions}
                        key={4}
                        requestId={requestId}
                        updateAlerts={updateFormAlerts}
                        updateUnreadDocumentAlerts={updateUnreadDocumentAlerts}
                      />,
                      isInstructingOrSupervising ? (
                        <Conclusion
                          availableActions={requestGlobalView.availableActions}
                          institutionId={requestGlobalView.institutionId}
                          key={5}
                          requestId={requestId}
                          resolvedAlerts={getAlertsByAlertStatus(AlertStatusEnum.Resolved)}
                          status={requestGlobalView.status}
                          unresolvedAlerts={getAlertsByAlertStatus(AlertStatusEnum.Unresolved)}
                        />
                      ) : (
                        <Transmission
                          availableActions={requestGlobalView.availableActions}
                          institutionId={requestGlobalView.institutionId}
                          key={5}
                          requestId={requestId}
                          shouldValidateProgram={requestGlobalView.shouldValidateProgram}
                          unresolvedAlerts={getAlertsByAlertStatus(AlertStatusEnum.Unresolved)}
                          updateAlerts={updateFormAlerts}
                        />
                      )
                    ][formCurrentStep]}
                  </Grid>
                </Grid>
              </StyledArticle>
            )}

            <AttestationFormActions
              availableActions={requestGlobalView.availableActions}
              currentStep={formCurrentStep}
              handleAddAlert={handleAddAlert}
              handleEditRequest={handleEditRequest}
              handleNextStep={handleNextStep}
              handlePreviousStep={handlePreviousStep}
              handleQuit={handleQuit}
              handleRequestUpdate={handleRequestUpdate}
              handleSeeHistory={handleSeeHistory}
              handleStartInstruction={handleStartInstruction}
              handleStartSupervision={handleStartSupervision}
              numberOfSteps={filteredSteps.length}
              setShowHistory={useAccessRights({ roles: APPLICATION_MANAGEMENT_ROLES }) && setShowHistory}
              showHistory={showHistory}
            />
          </AttestationFormElement>
        </StyledAttestationForm>
      </Grid>
    </Grid>

  );
}));

AttestationForm.propTypes = {};

AttestationForm.defaultProps = {};