/* eslint-disable max-len */
import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

const getEquipmentTypes = () => RequestHelper.GET(API_ROUTES.TOOLS.EQUIPMENT_TYPES());
const getEquipmentTypesForAttestationRequest = (requestId) => RequestHelper.GET(API_ROUTES.TOOLS.EQUIPMENT_TYPES_FOR_REQUEST(requestId));
const getBrands = () => RequestHelper.GET(API_ROUTES.TOOLS.BRANDS());
const create = (institutionId, equipment) => RequestHelper.POST(API_ROUTES.TOOLS.BASE_TOOL(institutionId), equipment);
const update = (equipment) => RequestHelper.PUT(API_ROUTES.TOOLS.TOOL(equipment.id), equipment);
const remove = (equipmentId) => RequestHelper.DELETE(API_ROUTES.TOOLS.TOOL(equipmentId));
const getInstitutionTools = (institutionId, filters, page, signal) => RequestHelper.POST(API_ROUTES.TOOLS.INSTITUTION_TOOLS(institutionId, page), filters, null, signal);

const activateTool = (equipmentId) => RequestHelper.PUT(API_ROUTES.TOOLS.TOOL_ACTIVATION(equipmentId));

export const ToolService = {
  create,
  update,
  remove,
  getEquipmentTypes,
  getEquipmentTypesForAttestationRequest,
  getBrands,
  getInstitutionTools,
  activateTool
};