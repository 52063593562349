import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { faCommentSlash, faHorizontalRule } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Divider, Grid, IconButton, Tooltip, Typography
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { styled, useTheme } from '@mui/material/styles';
import { AlertStatusEnum, AlertTypeEnum } from 'components/_commons/Alert/alert.enum';
import { WithLeftIconText } from 'components/_commons/Text';
import { useModal } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { AlertService } from 'services/AlertService';
import shortid from 'shortid';
import { translate } from 'utils';
import {
  ATTESTATION_FORM_ACTIONS
} from 'utils/constants';
import { iconEnum, ICONS } from 'utils/icons';

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: '0 -1px',
  borderBottomWidth: '2px',
  borderColor: theme.palette.warning.main
}));

export const AlertBlock = ({
  alerts, reloadAlerts, availableActions, canDisplayDocumentaryAlert, isLoading
}) => {
  const theme = useTheme();
  const showModal = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const canDeleteAlert = useCallback((alert) => alert.type.value === AlertTypeEnum.Attestation_manual
    && availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_CREATE_ALERT), [availableActions]);

  const canCloseAlert = useCallback((alert) => alert.resolvable
    && availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_CLOSE_ALERT), [availableActions]);

  const canReopenAlert = useCallback((alert) => alert.resolvable
    && availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_REOPEN_ALERT)
    && (alert.status.value === AlertStatusEnum.Resolved
      || alert.status.value === AlertStatusEnum.ResolvedInstructor), [availableActions]);

  const canResolveAlertAsOperator = useCallback((alert) => alert.resolvable
    && alert.type.value === AlertTypeEnum.Attestation_manual
    && availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_RESOLVE_ALERT_AS_OPERATOR)
    && alert.status.value === AlertStatusEnum.Unresolved, [availableActions]);

  const canResolveAlertAsInstructor = useCallback((alert) => alert.resolvable
    && availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_RESOLVE_ALERT_AS_INSTRUCTOR)
    && (alert.status.value === AlertStatusEnum.Resolved
      || alert.status.value === AlertStatusEnum.Unresolved), [availableActions]);

  const hasEditAction = useCallback(
    (alert) => canCloseAlert(alert)
    || canReopenAlert(alert)
    || canResolveAlertAsOperator(alert)
    || canResolveAlertAsInstructor(alert),
    [canCloseAlert, canReopenAlert, canResolveAlertAsOperator, canResolveAlertAsInstructor]
  );

  const deleteAlert = useCallback((alert) => showModal({
    type: 'WARNING',
    onConfirm: () => AlertService.deleteAlert(alert.id)
      .then(() => {
        enqueueSnackbar(translate('attestationFormRequest.alerts.snackbar.deleted'), { variant: 'success' });
        reloadAlerts();
      })
      .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }))
  }), [showModal]);

  const editAlert = useCallback((alert) => showModal({
    type: 'RESOLVE_ALERT',
    onOperatorResolve: canResolveAlertAsOperator(alert) && ((submittedAlert) => {
      AlertService.resolveAlert(alert.id, submittedAlert)
        .then(() => {
          enqueueSnackbar(translate('attestationFormRequest.alerts.snackbar.resolved'), { variant: 'success' });
          reloadAlerts();
        })
        .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
    }),
    onInstructorResolve: canResolveAlertAsInstructor(alert) && ((submittedAlert) => {
      AlertService.instructionResolveAlert(alert.id, submittedAlert)
        .then(() => {
          enqueueSnackbar(translate('attestationFormRequest.alerts.snackbar.resolved'), { variant: 'success' });
          reloadAlerts();
        })
        .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
    }),
    onCloseAlert: canCloseAlert(alert) && (() => {
      AlertService.closeAlert(alert.id)
        .then(() => {
          enqueueSnackbar(translate('attestationFormRequest.alerts.snackbar.resolved'), { variant: 'success' });
          reloadAlerts();
        })
        .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
    }),
    onReopenAlert: canReopenAlert(alert) && ((submittedAlert) => {
      AlertService.reopenAlert(alert.id, submittedAlert)
        .then(() => {
          enqueueSnackbar(translate('attestationFormRequest.alerts.snackbar.resolved'), { variant: 'success' });
          reloadAlerts();
        })
        .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
    }),
    closeOnSubmit: true,
    currentAlert: alert
  }), [showModal, enqueueSnackbar, reloadAlerts]);

  return (
    <Accordion
      disabled={isLoading}
      style={{ borderColor: theme.palette.warning.main, borderWidth: '2px' }}
      variant="outlined"
    >
      <AccordionSummary
        expandIcon={(
          <FontAwesomeIcon
            color={theme.palette.warning.main}
            icon={ICONS.CHEVRON_LIST.icon}
            size="lg"
          />
        )}
        style={{ marginRight: '1rem' }}
      >
        <Typography color={theme.palette.warning.main} variant="h5">
          {translate('attestationFormRequest.alerts.block.title', { count: alerts?.length })}
        </Typography>

      </AccordionSummary>
      <StyledDivider flexItem orientation="horizontal" />
      <AccordionDetails>
        {alerts.map((alert) => (alert.type.value !== AlertTypeEnum.Documentary || canDisplayDocumentaryAlert) && (
          <div key={alert.id || shortid.generate()}>
            <Grid alignItems="center" container direction="row" justifyContent="space-between">
              <Grid item xs={11}>
                <Grid alignItems="center" container spacing={1.5}>
                  <Grid item xs={0.5}>
                    <FontAwesomeIcon color={theme.palette.grey.dark} icon={faHorizontalRule} size="xs" />
                  </Grid>
                  <Grid item xs={alert.status.value === AlertStatusEnum.Resolved ? 8.5 : 11.5}>
                    <Tooltip title={alert.type.label}>
                      <Typography color={theme.palette.error.main}>
                        {alert.description}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  {alert.status.value === AlertStatusEnum.Resolved && (
                    <Grid item xs={2}>
                      <WithLeftIconText
                        color={theme.palette.warning.main}
                        ellipsis
                        fontWeight="bold"
                        icon={iconEnum.eye.icon}
                        label={translate('attestationFormRequest.alerts.unread')}
                        labelColor={theme.palette.warning.main}
                        size="xs"
                        spacing={0.5}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={1}>
                {hasEditAction(alert) && (
                  <Tooltip title={translate('attestationFormRequest.alerts.block.resolveButton')}>
                    <IconButton disabled={isLoading} onClick={() => editAlert(alert)}>
                      <FontAwesomeIcon color={theme.palette.grey.dark} icon={faCommentSlash} size="sm" />
                    </IconButton>
                  </Tooltip>
                )}
                {canDeleteAlert(alert) && (
                  <IconButton
                    disabled={isLoading}
                    style={{ padding: 2 }}
                    title={translate('button.delete')}
                    onClick={() => deleteAlert(alert)}
                  >
                    <FontAwesomeIcon
                      color={theme.palette.error.main}
                      icon={faTrashAlt}
                      size="sm"
                    />
                  </IconButton>
                )}
              </Grid>
            </Grid>
            <ul style={{ margin: 0 }}>
              {alert.resolvingAction && (
                <li>
                  <Typography paddingLeft="5rem">
                    {alert.resolvingAction}
                  </Typography>
                </li>
              )}
              {alert.evaluationComment && (
                <li>
                  <Typography paddingLeft="5rem">
                    {alert.evaluationComment}
                  </Typography>
                </li>
              )}
            </ul>
          </div>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};