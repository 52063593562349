import { styled } from '@mui/material/styles';

export const TitlePage = styled('h1')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
  margin: '2rem 0',
  color: theme.palette.primary.main,
  fontSize: '2.4rem',
  textTransform: 'uppercase',
  textAlign: 'center'
}));

export const SubtitlePage = styled('h2')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  minHeight: '42px',
  margin: '1rem 0',
  color: theme.palette.common.black,
  fontSize: '1.8rem',
  textTransform: 'uppercase',
  textAlign: 'center'
}));

export const TextError = styled('p')(({ theme }) => ({
  margin: '3rem 0',
  color: theme.palette.grey.dark,
  fontWeight: '600',
  textAlign: 'center'
}));

export const TextNoOptionsAvailable = styled('p')(({ theme }) => ({
  margin: '1rem 0 2rem 0',
  color: theme.palette.warning.main,
  fontWeight: '600',
  textAlign: 'left'
}));

export const SelectLabel = styled('p')(() => ({
  margin: '0.8rem 0',
  fontSize: '1.6rem',
  fontWeight: '600'
}));