import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, TableCell, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DocumentDisplay } from 'components/_commons/Document/DocumentDisplay';
import { GenericFilters } from 'components/_commons/GenericFilters/GenericFilters';
import { GenericTable } from 'components/_commons/GenericTable/GenericTable';
import { TableTextCell } from 'components/_commons/GenericTable/TableTextCell';
import { TecneaTitle } from 'components/_commons/Title';
import { DocumentCount } from 'components/AttestationForm/shared/DocumentCount';
import { compact } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import {
  DocumentHelper, FormHelper, isTablet, translate
} from 'utils';

import { DateHelper } from 'utils/helpers';
import { StringHelper } from 'utils/helpers/StringHelper';
import { ICONS } from 'utils/icons';

const DocumentCountContainer = ({ proofTypes, tool, warningMessage }) => {
  const theme = useTheme();
  const documentCounts = proofTypes.map((type) => {
    const documentsOfType = (tool.equipmentView?.documentList?.documents ?? [])
      .filter((doc) => doc.type?.name === type);
    const count = documentsOfType?.length;
    return count > 0
      && (
        <DocumentCount
          color={theme.palette.text.primary}
          count={count}
          title={documentsOfType[0].type.label}
        />
      );
  });
  if (documentCounts.some((documentCount) => Boolean(documentCount))) {
    return documentCounts;
  }
  if (warningMessage) {
    return <Typography color={theme.palette.warning.main} variant="body2">{translate(warningMessage)}</Typography>;
  }
  return <FontAwesomeIcon icon={ICONS.NONE.icon} />;
};

const listHeaders = () => compact([
  {
    name: 'name',
    labels: [
      'attestationFormRequest.tools.table.type',
      'attestationFormRequest.tools.table.brand',
      'attestationFormRequest.tools.table.model',
      'attestationFormRequest.tools.table.serialNumber'
    ],
    template: (row) => (
      <TableTextCell
        key={`${row.id}_name`}
        value={(
          <>
            <Typography>{row?.equipmentView?.type?.label}</Typography>
            <Typography>{row?.equipmentView?.brand}</Typography>
            <Typography>{row?.equipmentView?.model}</Typography>
            <Typography>{row?.equipmentView?.serialNumber}</Typography>
          </>
        )}
      />
    )
  },
  {
    name: 'acquisition',
    labels: [
      'attestationFormRequest.tools.table.acquisition',
      'attestationFormRequest.tools.table.newTool'
    ],
    hideForTablet: true,
    template: (row) => (
      <TableTextCell
        key={`${row.id}_acquisition`}
        value={(
          <>
            <Typography>
              {DateHelper.formatDate(row.equipmentView?.acquisitionDate)}
            </Typography>
            <Typography>
              {FormHelper.translateYesOrNo(row.equipmentView?.isNew)}
            </Typography>
          </>
        )}
      />
    )
  },
  {
    name: 'detention',
    label: 'attestationFormRequest.tools.table.detention',
    width: '20%',
    template: (row) => (
      <TableCell
        key={`${row.id}_detention`}
      >
        <DocumentCountContainer
          proofTypes={row.detentionDocumentTypes.map((type) => type.value)}
          tool={row}
          warningMessage="attestationFormRequest.assignationModal.warning.noDetentionProof"
        />
      </TableCell>
    )
  },
  {
    name: 'verification',
    label: 'attestationFormRequest.tools.table.verification',
    width: '20%',
    template: (row) => (
      <TableCell
        key={`${row.id}_verification`}
      >
        <DocumentCountContainer
          proofTypes={row.verificationDocumentTypes.map((type) => type.value)}
          tool={row}
          warningMessage="attestationFormRequest.assignationModal.warning.noVerificationProof"
        />
      </TableCell>
    )
  },
  {
    name: 'others',
    label: 'attestationFormRequest.tools.table.otherDocuments',
    width: '20%',
    template: (row) => (
      <TableCell
        key={`${row.id}_other`}
      >
        <DocumentCountContainer
          proofTypes={row.otherDocumentTypes.map((type) => type.value)}
          tool={row}
        />
      </TableCell>
    )
  }
]);

export const DocumentAssignationModalAssignation = ({
  formContext,
  disableNextStep,
  isLoadingTools,
  tools
}) => {
  const { watch } = formContext;
  const document = watch('document');
  const selectedTools = watch('tools');
  const [search, setSearch] = useState('');
  const [selectedToolIds, setSelectedToolIds] = useState(selectedTools ? selectedTools.map((tool) => tool.id) : []);

  const getFilteredTools = useCallback(
    () => {
      if (search && tools) {
        return tools.filter((tool) => StringHelper.containsIgnoreCaseAndAccents(tool.equipmentView.fullName, search));
      }
      return tools;
    },
    [tools, search]
  );

  useEffect(() => {
    formContext.setValue('tools', getFilteredTools()
      .filter((tool) => selectedToolIds.includes(tool.id)));
  }, [getFilteredTools, selectedToolIds]);

  useEffect(() => {
    if (!selectedTools || selectedTools.length === 0) {
      disableNextStep(true);
    } else {
      disableNextStep(false);
    }
  }, [selectedTools]);

  return (
    <Grid container height="100%">
      { !isTablet()
        && (
          <Grid height="100%" item xs={5}>
            <DocumentDisplay
              base64Content={DocumentHelper.getDocumentWithBase64Typing(document)}
              documentName={document?.name}
              downloadLabel={translate('common.documentTooBig')}
              height="100%"
              width="100%"
            />
          </Grid>
        )}
      <Grid item style={{ height: '100%' }} xs={isTablet() ? 12 : 7}>
        <Grid container direction="column" style={{ height: '100%' }}>
          <Grid item padding={2}>
            <TecneaTitle label={translate('attestationFormRequest.assignationModal.assignation.title')} />
          </Grid>
          <Grid item padding={1}>
            <GenericFilters
              filterKey="assignationToolModal"
              placeholder="attestationFormRequest.assignationModal.assignation.filterTool"
              search={search}
              setSearch={setSearch}
            />
          </Grid>
          <Grid
            item
            style={{
              flex: 1,
              overflowY: 'scroll',
              padding: 0
            }}
          >
            <GenericTable
              displayTotal={false}
              headers={listHeaders()}
              isLoading={isLoadingTools}
              isSelectable
              rows={getFilteredTools()}
              selectedRowIds={selectedToolIds}
              setSelectedRowIds={setSelectedToolIds}
              style={{ padding: 0 }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};