import { DocumentHelper, RequestHelper } from 'utils';
import { API_ROUTES } from 'utils/constants';

// GET

const getPurchaseOrderTemplate = (requestId) => {
  RequestHelper.GET(API_ROUTES.REQUESTS.PURCHASE_ORDER_TEMPLATE(requestId))
    .then((response) => {
      DocumentHelper.downloadDocumentFromBase64(response.document.base64Content, response.document.name);
    });
};

const setLastVisitedStep = (requestId, newStep) => (
  RequestHelper.POST(`${API_ROUTES.REQUESTS.FORM_REQUEST(requestId)}/last-visited-step/${newStep}`));

const getDetails = (requestId) => (
  RequestHelper.GET(`${API_ROUTES.REQUESTS.FORM_REQUEST(requestId)}/order`));

const getAllContacts = (requestId) => (
  RequestHelper.GET(`${API_ROUTES.REQUESTS.FORM_REQUEST(requestId)}/contacts`));

const getInstitution = (requestId) => (
  RequestHelper.GET(`${API_ROUTES.REQUESTS.FORM_REQUEST(requestId)}/institution`)
);

const getRequestGlobalView = (requestId) => (
  RequestHelper.GET(`${API_ROUTES.REQUESTS.FORM_REQUEST(requestId)}/global`)
);

const getTracabilityDocuments = (requestId) => (
  RequestHelper.GET(`${API_ROUTES.REQUESTS.FORM_REQUEST(requestId)}/tracability/documents`)
);

const getTracabilityDocumentsForType = (requestId, type) => (
  RequestHelper.GET(`${API_ROUTES.REQUESTS.FORM_REQUEST(requestId)}/tracability/documents/${type}`)
);

const getWorkModeJustificationDocument = (requestId) => (
  RequestHelper.GET(`${API_ROUTES.REQUESTS.FORM_REQUEST(requestId)}/work_mode/justification_document`)
);

const getEmployees = (requestId, freeSearch, excludeOperators, excludeContacts, page = 0) => (
  RequestHelper.GET(`${API_ROUTES.REQUESTS.FORM_REQUEST(requestId)}/employees?page=${page}
  &freeSearch=${freeSearch}&excludeOperators=${Boolean(excludeOperators)}&excludeContacts=${Boolean(excludeContacts)}`)
);

const getAllAlerts = (requestId) => (RequestHelper.GET(`${API_ROUTES.REQUESTS.FORM_REQUEST(requestId)}/alerts`));

const getRequestCategories = (requestId) => (RequestHelper.GET(`${API_ROUTES.REQUESTS.CATEGORIES(requestId)}`));
const updateRequestCategories = (requestId, categories) => (
  RequestHelper.PUT(`${API_ROUTES.REQUESTS.CATEGORIES(requestId)}`, categories)
);

const updateGroupContractReference = (requestId, reference) => (
  RequestHelper.PUT(`${API_ROUTES.REQUESTS.PURCHASE_ORDER_REFERENCE(requestId)}?contractReference=${reference}`));

const getOperators = (requestId) => RequestHelper.GET(API_ROUTES.REQUESTS.OPERATORS(requestId));

const getWorkMode = (requestId) => RequestHelper.GET(API_ROUTES.REQUESTS.ORGANIZATION(requestId));

const getToolsByType = (requestId, type, freeSearch = '') => (
  RequestHelper.GET(API_ROUTES.REQUESTS.EQUIPMENT_DOCUMENTS(requestId, type, freeSearch)));

const getInstitutionTools = (requestId, type, freeSearch = '') => (
  RequestHelper.GET(`${API_ROUTES.REQUESTS.INSTITUTION_TOOLS(requestId)}?type=${type}&freeSearch=${freeSearch}`));

// PUT
const updateContractType = (requestId, isGroupContract) => (
  RequestHelper.PUT(`${API_ROUTES.REQUESTS.FORM_REQUEST(requestId)}/toggle-group`, isGroupContract));

const modifyRequest = (requestId) => RequestHelper.PUT(`${API_ROUTES.REQUESTS.MODIFY(requestId)}`);
const submitRequest = (requestId) => RequestHelper.PUT(`${API_ROUTES.REQUESTS.SUBMIT(requestId)}`);
const closeRequest = (requestId) => RequestHelper.PUT(`${API_ROUTES.REQUESTS.CLOSE(requestId)}`);
const updateSituationInstitution = (requestId, body) => RequestHelper.PUT(API_ROUTES.REQUESTS.SITUATION_UPDATE(requestId), body);
const invalidatePurchaseOrder = (requestId) => RequestHelper.PUT(API_ROUTES.REQUESTS.INVALIDATE_PURCHASE_ORDER(requestId));

const massCloseAttestationRequests = (filter) => RequestHelper.PUT(API_ROUTES.REQUESTS.MASS_CLOSE(), filter);

// POST
const saveDocument = (requestId, fileTransferModel, documentType) => (
  RequestHelper.POST(`${API_ROUTES.REQUESTS.FORM_REQUEST(requestId)}/documents/${documentType}`, fileTransferModel));

const filterInstructions = ((filter) => (
  RequestHelper.POST(`${API_ROUTES.REQUESTS.FORM_REQUEST_INSTRUCTIONS()}`, filter)
));
const createOrUpdateContacts = (requestId, attestationFormContactView) => (
  RequestHelper.POST(`${API_ROUTES.REQUESTS.FORM_REQUEST(requestId)}/contacts`, attestationFormContactView));

const createOperator = (body, requestId) => RequestHelper.POST(API_ROUTES.REQUESTS.OPERATOR(requestId), body);

const createTool = (requestId, tool) => RequestHelper.POST(API_ROUTES.REQUESTS.TOOL(requestId), tool);

const createAlert = (requestId, alert) => (RequestHelper.POST(`${API_ROUTES.REQUESTS.FORM_REQUEST(requestId)}/alerts`, alert));

const validateSection = (requestId, section) => (
  RequestHelper.POST(`${API_ROUTES.REQUESTS.VALIDATE_ALERTS(requestId)}?section=${section}`));

const validateAllSections = (requestId) => (
  RequestHelper.POST(`${API_ROUTES.REQUESTS.VALIDATE_SECTIONS(requestId)}`));

const validateCategories = (requestId) => (
  RequestHelper.POST(`${API_ROUTES.REQUESTS.CATEGORIES(requestId)}/validate`));

const getUnclassifiedDocuments = (requestId, type) => (
  RequestHelper.GET(`${API_ROUTES.REQUESTS.UNCLASSIFIED_DOCUMENTS(requestId, type)}`));

const requestUpdate = (requestId) => RequestHelper.POST(`${API_ROUTES.REQUESTS.UPDATE(requestId)}`);

const getWorkModeWithTooltips = (requestId) => RequestHelper.GET(`${API_ROUTES.REQUESTS.ORGANIZATION_TOOLTIPS(requestId)}`);

export const AttestationFormRequestService = {
  getPurchaseOrderTemplate,
  getDetails,
  getAllContacts,
  getOperators,
  getInstitution,
  getEmployees,
  getRequestGlobalView,
  getRequestCategories,
  updateRequestCategories,
  getTracabilityDocuments,
  getTracabilityDocumentsForType,
  getAllAlerts,
  updateContractType,
  saveDocument,
  createOrUpdateContacts,
  createOperator,
  createAlert,
  validateSection,
  validateAllSections,
  createTool,
  getToolsByType,
  filterInstructions,
  updateGroupContractReference,
  getWorkMode,
  getInstitutionTools,
  validateCategories,
  setLastVisitedStep,
  closeRequest,
  modifyRequest,
  requestUpdate,
  submitRequest,
  getUnclassifiedDocuments,
  massCloseAttestationRequests,
  getWorkModeJustificationDocument,
  updateSituationInstitution,
  invalidatePurchaseOrder,
  getWorkModeWithTooltips
};