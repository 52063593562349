import { format, isValid, parse } from 'date-fns';
import { fr } from 'date-fns/locale';
import { DAYS } from 'utils/constants';
import { translate } from 'utils/index';

const date1900 = new Date(Date.UTC(1900, 0, 1));

const formatDate = (date, dateFormat = 'dd/MM/yyyy') => {
  if (!date) return undefined;
  return format(new Date(date), dateFormat);
};

const formatDateFromUTC = (date, dateFormat = 'dd/MM/yyyy') => {
  if (!date) return undefined;
  return format(new Date(`${date}Z`), dateFormat);
};

const formatNiceDate = (date, showFull = false) => {
  if (!date) return '';

  let formattedDate = new Date(date);
  const day = formattedDate.getDay();

  if (!(formattedDate instanceof Date)) return '';

  if (showFull) {
    formattedDate = format(formattedDate, 'dd MMMM yyyy - HH:mm', { locale: fr });
    return `${DAYS[day]} ${formattedDate}`;
  }
  return format(formattedDate, 'dd/MM/yyyy - HH:mm', { locale: fr });
};

const getYearPrefix = (date, prefix) => (((prefix + date).length >= 4) ? prefix : getYearPrefix(date, `${prefix}0`));

const getDateWithoutTimezoneTransformation = (date) => {
  if (!date) return '';
  const d = new Date(date);
  const dayPrefix = d.getDate() >= 10 ? '' : '0';
  const monthPrefix = d.getMonth() + 1 >= 10 ? '' : '0';
  const yearPrefix = getYearPrefix(String(d.getFullYear()), '');
  return `${yearPrefix}${d.getFullYear()}-${monthPrefix}${d.getMonth() + 1}-${dayPrefix}${d.getDate()}`;
};

const isDateValid = (date) => isValid(
  parse(getDateWithoutTimezoneTransformation(date), 'yyyy-MM-dd', new Date(), { locale: fr })
) && new Date(date) > date1900;

const isInFuture = (date) => date > new Date();

const isMoreThanOneYearInThePast = (date) => {
  const dateOneYearInThePast = new Date();
  dateOneYearInThePast.setFullYear(dateOneYearInThePast.getFullYear() - 1);
  return date < dateOneYearInThePast;
};

const validateIfDateIsValidAndInThePast = (date) => {
  if (!isDateValid(date)) {
    return translate('common.dateNotValid');
  }
  if (isInFuture(date)) {
    return translate('common.dateMustBePassed');
  }
  return true;
};

const validateIfDateIsValid = (date) => {
  if (!isDateValid(date)) {
    return translate('common.dateNotValid');
  }
  return true;
};

const getDefaultTime = () => '00:00:00';

const getLocalDateTime = (date) => `${getDateWithoutTimezoneTransformation(date)}T${getDefaultTime()}`;

const addMonths = (date, months) => {
  date.setMonth(date.getMonth() + months);
  return date;
};

export const DateHelper = {
  formatDate,
  formatDateFromUTC,
  formatNiceDate,
  getDateWithoutTimezoneTransformation,
  getYearPrefix,
  isDateValid,
  isInFuture,
  validateIfDateIsValidAndInThePast,
  isMoreThanOneYearInThePast,
  validateIfDateIsValid,
  getDefaultTime,
  getLocalDateTime,
  addMonths
};