import { toLower } from 'lodash';
import { VALIDATION_STATUS } from 'utils/constants';

const MAX_WIDTH = 800;
const MAX_HEIGHT = 800;

const resizeImage = (reader) => new Promise((resolve) => {
  const img = document.createElement('img');
  img.src = reader.result;
  img.crossOrigin = 'anonymous';

  img.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);

    // eslint-disable-next-line prefer-destructuring
    let width = img.width;
    // eslint-disable-next-line prefer-destructuring
    let height = img.height;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else if (height > MAX_HEIGHT) {
      width *= MAX_HEIGHT / height;
      height = MAX_HEIGHT;
    }

    canvas.width = width;
    canvas.height = height;
    const ctxx = canvas.getContext('2d');
    ctxx.drawImage(img, 0, 0, width, height);

    resolve(canvas.toDataURL());
  };
});

const handleFileUpload = (event, needResize, maxSize) => new Promise((resolve, reject) => {
  const allFiles = event.target.files;
  if (allFiles?.length) {
    const readerOnLoad = async (readerLoaded, currentFileLoaded) => {
      const fileUploaded = {
        name: currentFileLoaded.name,
        base64Content: readerLoaded.result
      };

      if (!needResize) return resolve(fileUploaded);

      const imgResized = await resizeImage(readerLoaded);

      return resolve({
        ...fileUploaded,
        base64Content: imgResized
      });
    };

    const reader = new FileReader();
    const currentFile = allFiles[0];
    const filesize = ((currentFile.size / 1024) / 1024).toFixed(2); // in Mo

    if (filesize <= maxSize || needResize) {
      reader.readAsDataURL(currentFile);
      reader.onload = () => readerOnLoad(reader, currentFile);
    } else {
      reject(Error('documentTooBig'));
    }
  }
});

const convertBytesToStream = (response) => {
  const reader = response.body.getReader();

  return new ReadableStream({
    start(controller) {
      function pump() {
        return reader.read().then(({ done, value }) => {
          if (done) {
            controller.close();
            return null;
          }
          controller.enqueue(value);
          return pump();
        });
      }

      return pump();
    }
  });
};

const getExtension = (document) => {
  const re = /(?:\.([^.]+))?$/;
  return toLower(re.exec(document.name)[1]);
};

const isPdf = (document) => {
  const extension = getExtension(document);
  return extension === 'pdf';
};

const isVisualizable = (document) => {
  const extension = getExtension(document);
  return ['pdf', 'png', 'jpg', 'jpeg'].includes(extension);
};

const getContentWithBase64Typing = (extension, base64Content) => {
  if (!base64Content) {
    return '';
  }
  // If the encrypted content already has the typing data, return the content
  if (base64Content.includes(';base64,')) {
    return base64Content;
  }
  switch (extension) {
  case 'png':
  case 'jpg':
  case 'jpeg':
    return `data:image/${extension};base64,${base64Content}`;
  case 'pdf':
    return `data:application/pdf;base64,${base64Content}`;
  case 'xlsx':
  case 'xls':
    return `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Content}`;
  case 'rar':
  case 'zip':
    return `data:application/zip;base64,${base64Content}`;
    // Default to a png
  default:
    return `data:image/png;base64,${base64Content}`;
  }
};

const getDocumentWithBase64Typing = (document) => {
  const extension = getExtension(document);
  return getContentWithBase64Typing(extension, document.base64Content);
};

const downloadDocumentFromBase64 = (base64Content, name) => {
  const extension = getExtension({ name });
  const downloadLink = document.createElement('a');
  downloadLink.href = getContentWithBase64Typing(extension, base64Content);
  downloadLink.download = name;
  downloadLink.click();
};

const isPDFWithBase64 = (data) => (
  data.split(',')[0] === 'data:application/pdf;base64'
);

const getContentWithoutBase64Typing = (base64Content) => {
  if (!base64Content) {
    return '';
  }
  // If the encrypted content doesn't have the typing data, return the content
  if (!base64Content.includes(';base64,')) {
    return base64Content;
  }
  return base64Content.split(',')[1];
};

const getDocumentWithoutBase64 = (document) => (
  getContentWithoutBase64Typing(document.base64Content)
);

const hasValidation = (doc) => doc.validation?.validationStatus;

const notYetValidated = (doc) => !hasValidation(doc);

const isInvalid = (doc) => hasValidation(doc) && doc.validation?.validationStatus !== VALIDATION_STATUS.VALID;

const handleExcelFileDownload = (document) => {
  downloadDocumentFromBase64(document.base64Content, document.name);
};

export const DocumentHelper = {
  handleFileUpload,
  resizeImage,
  convertBytesToStream,
  downloadDocumentFromBase64,
  getExtension,
  getDocumentWithBase64Typing,
  getContentWithBase64Typing,
  getDocumentWithoutBase64,
  getContentWithoutBase64Typing,
  isPDFWithBase64,
  isPdf,
  isVisualizable,
  hasValidation,
  notYetValidated,
  isInvalid,
  handleExcelFileDownload
};