import React, { useCallback } from 'react';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUserCircle, faUserCog } from '@fortawesome/pro-regular-svg-icons';
import {
  Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography
} from '@mui/material';
import {
  APP_VERSION,
  APPLICATION_ADMIN_ROLES,
  ATTESTATION_EVALUATION_ROLES,
  CREATED,
  ENVIRONMENT,
  REVISION,
  ROUTES
} from 'utils/constants';
import { translate } from 'utils';
import { useAccessRights, useStores } from 'hooks';
import shortid from 'shortid';
import { observer } from 'mobx-react-lite';
import { format, parseISO } from 'date-fns';
import { Tablet } from 'components/_commons/MediaQueries';

const SideMenuContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,

  svg: {
    color: theme.palette.primary.main
  },

  ul: {
    width: '100%'
  }
}));

export const SideMenu = observer(({
  isOpen, setToggleMenu
}) => {
  const { userStore } = useStores();
  const navigate = useNavigate();
  const { isConnected } = userStore;
  const hasAccessRightsAdmin = useAccessRights({ roles: [...APPLICATION_ADMIN_ROLES, ...ATTESTATION_EVALUATION_ROLES] });

  const disconnect = useCallback(() => {
    userStore.disconnectUser();
  }, [userStore]);

  const navigateWithoutBreakingDrawer = useCallback((path) => {
    setToggleMenu(false);
    setTimeout(() => {
      navigate(path);
    }, '200');
  }, [setToggleMenu, navigate]);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      transitionDuration={150}
      onClose={() => setToggleMenu(false)}
    >
      <SideMenuContainer>
        <List>
          {isConnected && (
            <ListItemButton
              component="div"
              data-cy="headerMenuUser"
              onClick={() => navigateWithoutBreakingDrawer(generatePath(ROUTES.USER_DETAILS, { personId: 'current' }))}
            >
              <ListItemIcon><FontAwesomeIcon icon={faUserCircle} size="lg" /></ListItemIcon>
              <ListItemText primary={translate('menu.profile')} />
            </ListItemButton>
          )}

          {hasAccessRightsAdmin && (
            <ListItemButton
              component="div"
              data-cy="headerMenuAdministration"
              key={shortid.generate()}
              onClick={() => navigateWithoutBreakingDrawer(ROUTES.ADMINISTRATION.MAIN)}
            >
              <ListItemIcon><FontAwesomeIcon icon={faUserCog} size="lg" /></ListItemIcon>
              <ListItemText primary={translate('menu.admin')} />
            </ListItemButton>
          )}

          <Divider />

          <ListItemButton
            component={Link}
            data-cy="headerMenuLogout"
            to=""
            onClick={disconnect}
          >
            <ListItemIcon><FontAwesomeIcon icon={faSignOutAlt} size="lg" /></ListItemIcon>
            <ListItemText primary={translate('menu.disconnect')} />
          </ListItemButton>
        </List>
      </SideMenuContainer>

      <Typography align="center">
        {`${ENVIRONMENT} - ${APP_VERSION}`}
        <br />
        <Tooltip leaveDelay={1000} title={REVISION}>
          <Typography component="span">
            {`Révision: ${REVISION.substring(0, 5)}`}
          </Typography>
        </Tooltip>
        <br />
        {CREATED && (parseISO(CREATED) instanceof Date) && (
          `Créé le: ${format(parseISO(CREATED), 'dd/MM/yyyy à HH:mm:ss')}`
        )}
      </Typography>

      <Tablet>
        <img
          alt={translate('menu.logoAlt')}
          src={`${process.env.PUBLIC_URL}/assets/images/bg/footer-mobile.png`}
          style={{ maxWidth: '100%' }}
          title={translate('menu.logoAlt')}
        />
      </Tablet>
    </Drawer>
  );
});

SideMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setToggleMenu: PropTypes.func.isRequired
};