import { AutocompleteElement } from 'react-hook-form-mui';
import { Chip } from '@mui/material';
import React from 'react';
import { translate } from 'utils';

export const MultipleInputField = ({
  label, placeholder, required, variant = 'standard', fullWidth, validate, name
}) => (
  <AutocompleteElement
    autocompleteProps={{
      fullWidth,
      id: 'tags-filled',
      freeSolo: true,
      autoSelect: true,
      autoComplete: 'no-password',
      renderTags: (value, getTagProps) => value.map((option, index) => (
        <Chip label={option} variant="outlined" {...getTagProps({ index })} />
      ))
    }}
    label={label}
    multiple
    name={name}
    options={[]}
    required={required}
    rules={{
      required: translate('common.requiredField'),
      validate: {
        validation: (value) => ((validate) ? validate(value) : true)
      }
    }}
    textFieldProps={{
      inputProps: { autoComplete: 'no-password' },
      label,
      placeholder,
      variant
    }}
  />
);