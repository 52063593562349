import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons';

const StyledText = styled('p')(({ ...props }) => {
  const styles = {};
  props.position && (styles.position = props.position);
  props.display && (styles.display = props.display);
  props.top && (styles.top = props.top);
  props.left && (styles.left = props.left);
  props.right && (styles.right = props.right);
  props.bottom && (styles.bottom = props.bottom);
  props.width && (styles.width = props.width);
  props.margin && (styles.margin = props.margin);
  props.marginLeft && (styles.marginLeft = props.marginLeft);
  props.marginRight && (styles.marginRight = props.marginRight);
  props.marginTop && (styles.marginTop = props.marginTop);
  props.marginBottom && (styles.marginBottom = props.marginBottom);
  props.padding && (styles.padding = props.padding);
  props.color && (styles.color = props.color);
  props.lineHeight && (styles.lineHeight = props.lineHeight);
  props.fontSize && (styles.fontSize = props.fontSize);
  props.fontFamily && (styles.fontFamily = props.fontFamily);
  props.fontStyle && (styles.fontStyle = props.fontStyle);
  props.fontWeight && (styles.fontWeight = props.fontWeight);
  props.textAlign && (styles.textAlign = props.textAlign);
  props.textDecoration && (styles.textDecoration = props.textDecoration);
  props.textTransform && (styles.textTransform = props.textTransform);
  props.textIndent && (styles.textIndent = props.textIndent);
  props.verticalAlign && (styles.verticalAlign = props.verticalAlign);
  props.letterSpacing && (styles.letterSpacing = props.letterSpacing);
  props.border && (styles.border = props.border);
  props.background && (styles.background = props.background);
  props.textShadow && (styles.textShadow = props.textShadow);
  props.boxShadow && (styles.boxShadow = props.boxShadow);
  props.zIndex && (styles.zIndex = props.zIndex);
  return styles;
});

const StyledTextMultiline = styled(StyledText)(() => ({
  whiteSpace: 'pre-line'
}));

export const Text = ({ children, ...props }) => (
  <StyledText {...props}>{children}</StyledText>
);

Text.defaultProps = {
  children: PropTypes.element.isRequired
};

export const TextMultiline = ({ children, ...props }) => (
  <StyledTextMultiline {...props}>{children}</StyledTextMultiline>
);

export const TextAddress = ({ address, withIcon = false, variant = 'body2' }) => {
  const secondaryAddress = address.address2 ? `(${address.address2})` : '';

  return (
    <Typography variant={variant}>
      {withIcon && <FontAwesomeIcon className="mr1" icon={faMapMarkerAlt} />}
      {`${address.address1} ${secondaryAddress}`}
      <br />
      {`${address.postalCode || ''} ${address.city || ''} ${address.country ? address.country.label : ''}`}
    </Typography>
  );
};