import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Checkbox,
  Grid, Table, TableCell, TableHead, TableRow, TableSortLabel, Tooltip, Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { SkeletonSection, Spinner } from 'components/_commons/Skeletons';
import { TextError } from 'components/_commons/Text';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller'; // Docs : https://www.npmjs.com/package/react-infinite-scroller
import shortid from 'shortid';
import { isMobile, isTablet, translate } from 'utils';
import { iconEnum } from 'utils/icons';
import { InfoTooltip } from '../InfoTooltip';

const GenericTableContainer = styled('div')(() => ({
  position: 'relative',
  maxWidth: '100%',
  paddingTop: '20px',
  paddingBottom: '20px',
  overflowX: 'initial'
}));

const TotalRows = styled(Typography)(({ theme }) => ({
  fontSize: '1.4rem',
  color: theme.palette.grey.dark,
  textAlign: 'right',

  '@media (max-width: 768px)': {
    marginRight: '20px'
  }
}));

const TableSortLabelComponent = ({
  orderBy,
  order,
  header,
  handleSortAction
}) => {
  const handleClick = useCallback(() => handleSortAction(header), [header, handleSortAction]);

  return (
    <TableSortLabel
      active={orderBy === header.name}
      direction={order}
      onClick={handleClick}
    >
      {header.label}
    </TableSortLabel>
  );
};

const toolTipFromHeader = ((header) => (
  <Grid item key="toolTip">
    {header.toolTipIcon
      ? (
        <Tooltip title={translate(header.toolTipLabel)}>
          <FontAwesomeIcon
            color={iconEnum.info.defaultColor}
            icon={iconEnum.info.icon}
            size="xl"
            style={{ marginLeft: 10 }}
          />
        </Tooltip>
      )
      : <InfoTooltip label={translate(header.toolTipLabel)} size="medium" />}
  </Grid>
));

const TableHeader = ({ header }) => (
  header.labels ? (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
    >
      {(header.labels ?? []).map((label) => (
        <Grid item key={label}>
          <Typography fontWeight="bold">
            {label && translate(label)}
          </Typography>
        </Grid>
      ))}
      {header.toolTipLabel && toolTipFromHeader(header)}
    </Grid>
  ) : (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      wrap="nowrap"
    >
      <Grid alignContent="center" item>
        <Typography fontWeight="bold">
          {header.label && translate(header.label)}
        </Typography>
      </Grid>
      {header.toolTipLabel && toolTipFromHeader(header)}
    </Grid>
  )
);

const TableRowComponent = ({
  hover,
  row,
  onRowCLick,
  isSelectable,
  handleSelectClick,
  keyValue,
  isItemSelected,
  headers,
  cursor,
  index
}) => {
  const handleClick = useCallback(() => onRowCLick(row), [onRowCLick, row]);
  const theme = useTheme();
  return (
    <TableRow
      hover={hover}
      key={keyValue}
      selected={isItemSelected}
      style={{
        cursor,
        height: theme.sizes.rowHeight
      }}
      onClick={handleClick}
    >
      {isSelectable
        && (
          <TableCell padding="checkbox">
            <Checkbox
              checked={isItemSelected}
              color="primary"
              onChange={() => handleSelectClick(row.id)}
            />
          </TableCell>
        )}
      {headers.map((column) => (
        column.template(row, index)
      ))}
    </TableRow>
  );
};

const defaultKeyFunction = (row) => row.id ?? shortid.generate();

export const GenericTable = ({
  rows,
  dataCy,
  dataTestid,
  dataTour,
  hasMore,
  loadMore,
  headers,
  isSelectable,
  selectedRowIds,
  setSelectedRowIds,
  hover,
  style,
  onRowCLick,
  stickyHeader,
  id,
  displayTotal,
  noEntriesMessage = 'errors.noEntries',
  noEntriesIcon = iconEnum.emptySet.icon,
  total,
  isLoading,
  keyFunction = defaultKeyFunction
}) => {
  const tabletView = isTablet();
  const mobileView = isMobile();
  const theme = useTheme();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(headers.orderBy || headers.name);
  const [sortOnProperty, setSortOnProperty] = useState(headers.sortOn || headers.name);

  const handleSort = useCallback(({
    name,
    sortOn
  }) => {
    const isDesc = orderBy === name && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(name);
    setSortOnProperty(sortOn);
  }, [order, orderBy]);

  const onSelectAllClick = () => {
    if (selectedRowIds.length === 0) {
      const rowIds = rows.map((r) => r.id);
      setSelectedRowIds(rowIds);
    } else {
      setSelectedRowIds([]);
    }
  };

  const handleSelectClick = (itemId) => {
    const selectedIndex = selectedRowIds.indexOf(itemId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRowIds, itemId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRowIds.slice(1));
    } else if (selectedIndex === selectedRowIds.length - 1) {
      newSelected = newSelected.concat(selectedRowIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRowIds.slice(0, selectedIndex),
        selectedRowIds.slice(selectedIndex + 1)
      );
    }

    setSelectedRowIds(newSelected);
  };

  const isItemSelected = (itemId) => (isSelectable ? selectedRowIds.indexOf(itemId) !== -1 : false);

  const desc = (a, b) => {
    if (b[orderBy] && b[orderBy][sortOnProperty]) {
      if (b[orderBy][sortOnProperty] < a[orderBy][sortOnProperty]) return -1;
      if (b[orderBy][sortOnProperty] > a[orderBy][sortOnProperty]) return 1;
    } else {
      if (b[orderBy] < a[orderBy]) return -1;
      if (b[orderBy] > a[orderBy]) return 1;
    }

    return 0;
  };

  const getSorting = () => (order === 'desc' ? (a, b) => desc(a, b) : (a, b) => -desc(a, b));

  const handleSortAction = useCallback((header) => handleSort({ ...header }), [handleSort]);

  const handleRowClick = useCallback((row) => onRowCLick && onRowCLick(row), [onRowCLick]);

  const handleLoadMore = useCallback(() => loadMore && loadMore(), [loadMore]);

  const filteredHeaders = useMemo(() => (
    headers.filter((header) => (!tabletView || !header.hideForTablet) && (!mobileView || !header.hideForMobile) && !header.hidden)
  ), [tabletView, headers]);

  return (isLoading && !rows?.length)
    ? (
      <GenericTableContainer>
        <Spinner />
      </GenericTableContainer>
    ) : (
      <GenericTableContainer
        data-cy={dataCy}
        data-testid={dataTestid}
        data-tour={dataTour}
        style={style}
      >
        {displayTotal && (
          <TotalRows gutterBottom>
            {`${rows.length} / ${total || 0} ${translate('common.totalDisplayed')}`}
          </TotalRows>
        )}

        {
          rows.length ? (
            <Table
              size="small"
              stickyHeader={stickyHeader}
            >
              <TableHead>
                <TableRow>
                  {
                    isSelectable
                    && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={rows.length > 0 && selectedRowIds.length > 0 && rows
                            .map((row) => row.id)
                            .every((rid) => selectedRowIds.includes(rid))}
                          color="primary"
                          indeterminate={selectedRowIds.length > 0 && selectedRowIds.length < rows.length}
                          onChange={onSelectAllClick}
                        />
                      </TableCell>
                    )
                  }
                  {filteredHeaders.map((header) => (
                    <TableCell key={header.name} style={{ width: header.width || 'initial', ...header.style }}>
                      {header.isSortable ? (
                        <TableSortLabelComponent
                          handleSortAction={handleSortAction}
                          header={header}
                          order={order}
                          orderBy={orderBy}
                        />
                      ) : <TableHeader header={header} />}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <InfiniteScroll
                element="tbody"
                hasMore={hasMore}
                id={id}
                initialLoad={false}
                loadMore={handleLoadMore}
                pageStart={0}
                style={{ minHeight: '1200px' }}
                threshold={50}
              >
                {rows
                  .slice()
                  .sort(getSorting())
                  .map((row, index) => (
                    <TableRowComponent
                      cursor={onRowCLick ? 'pointer' : 'initial'}
                      handleSelectClick={handleSelectClick}
                      headers={filteredHeaders}
                      hover={hover}
                      index={index}
                      isItemSelected={isItemSelected(row.id)}
                      isSelectable={isSelectable}
                      key={keyFunction(row)}
                      keyValue={keyFunction(row)}
                      row={row}
                      onRowCLick={handleRowClick}
                    />
                  ))}
              </InfiniteScroll>
            </Table>
          ) : (
            <Grid alignItems="center" container direction="column">
              <FontAwesomeIcon color={theme.palette.primary.main} icon={noEntriesIcon} size="3x" />
              <TextError>{translate(noEntriesMessage)}</TextError>
            </Grid>
          )
        }
        {isLoading && <SkeletonSection />}
      </GenericTableContainer>
    );
};

GenericTable.propTypes = {
  dataCy: PropTypes.string,
  dataTour: PropTypes.string,
  displayTotal: PropTypes.bool,
  hasMore: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    labels: PropTypes.arrayOf(PropTypes.string),
    isSortable: PropTypes.bool,
    customHeader: PropTypes.bool,
    sortOn: PropTypes.string,
    style: PropTypes.shape({}),
    template: PropTypes.func,
    hideForTablet: PropTypes.bool
  })).isRequired,
  hover: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadMore: PropTypes.func,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onRowCLick: PropTypes.func,
  stickyHeader: PropTypes.bool
};

GenericTable.defaultProps = {
  dataCy: '',
  dataTour: '',
  displayTotal: true,
  hasMore: false,
  hover: true,
  isLoading: false,
  loadMore: null,
  onRowCLick: null,
  stickyHeader: true
};