import { merge } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';

import { DialogContent, FormControl, Grid } from '@mui/material';

import { OperatorModalStepsEnum } from 'core/enums';
import { AttestationFormOperatorService, DocumentService } from 'services';
import { translate } from 'utils';
import { Validators } from 'utils/helpers';

import { FormInputField } from 'components/_commons/Form/Inputs';
import { MultipleInputField } from 'components/_commons/Form/Inputs/InputField/MultipleInputField';
import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { AddOperatorDocument } from 'components/_commons/Modals/AddOperatorModal/AddOperatorDocument';
import { OperatorActionButtons } from 'components/_commons/Modals/AddOperatorModal/AddOperatorModalFooter';
import { SelectEmployee } from 'components/_commons/Modals/common/SelectEmployee';
import { StyledInput } from 'components/_commons/Modals/common/SelectEntity';

export const AddOperatorModal = ({
  defaultOperator, requestId, onClose, onSubmit, documentTypes
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [operator, setOperator] = useState(null);
  const [isNewOperator, setIsNewOperator] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(OperatorModalStepsEnum.AddOperator);
  const getEmailArray = (c) => c?.contactEmails?.split(';') ?? c?.technicalEmail?.split(';') ?? [];

  const defaultValues = {
    person: defaultOperator?.person ? {
      firstName: defaultOperator.person.firstName ?? '',
      lastName: defaultOperator.person.lastName ?? '',
      occupation: defaultOperator.person.occupation ?? '',
      contact: {
        phoneNumber: defaultOperator.person.contact?.phoneNumber ?? '',
        contactEmails: getEmailArray(defaultOperator.person.contact)
      }
    } : null,
    attestationDocuments: defaultOperator?.attestationDocuments ?? [],
    diplomaDocuments: defaultOperator?.diplomaDocuments ?? []
  };
  const formContext = useForm({ defaultValues });
  const {
    setValue, watch, formState, clearErrors, reset
  } = formContext;
  const isEdition = Boolean(defaultOperator);
  const newPersonEmployee = {
    firstName: translate('operatorsStep.operators.modalAdd.newPerson'),
    lastName: '',
    id: 'new-person-employee'
  };

  useEffect(() => {
    if (defaultOperator?.attestationDocuments?.length) {
      DocumentService.getDocumentsWithContent(defaultOperator?.attestationDocuments)
        .then((resp) => formContext.setValue('attestationDocuments', resp));
    }
    if (defaultOperator?.diplomaDocuments?.length) {
      DocumentService.getDocumentsWithContent(defaultOperator?.diplomaDocuments)
        .then((resp) => formContext.setValue('diplomaDocuments', resp));
    }
  }, [defaultOperator]);

  const onEmployeeSelected = useCallback((event, selected) => {
    setIsNewOperator(false);
    if (selected) {
      AttestationFormOperatorService.loadOperatorViewFromPerson(selected.id)
        .then((operatorView) => {
          setOperator(operatorView);
          formContext.setValue('attestationDocuments', operatorView?.attestationDocuments ?? []);
          formContext.setValue('diplomaDocuments', operatorView?.diplomaDocuments ?? []);
        })
        .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
    } else {
      setOperator(null);
    }
    setValue('person', watch('selectedPerson'));
    // Next line mandatory to ensure the MultipleInputField rerender on emails
    setValue('person.contact.contactEmails', watch('selectedPerson.contact.contactEmails'));
  }, [enqueueSnackbar, watch, setValue]);

  const onNewContact = () => {
    clearErrors();
    reset({
      person: {
        firstName: null,
        lastName: null,
        occupation: null,
        contact: {
          phoneNumber: null,
          contactEmails: []
        }
      },
      attestationDocuments: [],
      diplomaDocuments: []
    });
    setValue('selectedPerson', newPersonEmployee);
    setIsNewOperator(true);
    setOperator(null);
  };

  const onSubmitForm = (submitted) => {
    if (currentStep === OperatorModalStepsEnum.AddOperator) {
      setCurrentStep(OperatorModalStepsEnum.OperatorDocument);
    } else {
      const emails = submitted?.person?.contact?.contactEmails || [];
      const stringFormatEmails = emails.join(';');
      const stringFormattedEmailOperator = { ...submitted };
      submitted?.person?.contact && (stringFormattedEmailOperator.person.contact.contactEmails = stringFormatEmails);
      delete stringFormattedEmailOperator.selectedPerson;
      delete stringFormattedEmailOperator.selectedType;

      onSubmit({ ...stringFormattedEmailOperator, person: merge(operator?.person, stringFormattedEmailOperator?.person) });
    }
  };

  useEffect(() => {
    isEdition && setOperator(defaultOperator);
  }, [defaultOperator]);

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{ autoComplete: 'off', name: 'addOperatorForm', style: { overflow: 'auto' } }}
      onSuccess={onSubmitForm}
    >
      <ModalHeader onClose={onClose}>
        {translate(`attestationFormRequest.operator.modalAdd.title.${isEdition ? 'edit' : 'add'}`)}
      </ModalHeader>

      <DialogContent style={{ width: '80vw', height: '70vh', paddingTop: '30px' }}>
        {(currentStep === OperatorModalStepsEnum.OperatorDocument)
          ? (
            <AddOperatorDocument formContext={formContext} setIsLoading={setIsLoading} types={documentTypes} />
          )
          : (
            <SelectEmployee
              buttonTitle="attestationFormRequest.operator.modalAdd.createOperator"
              employeeValue={operator}
              excludeOperators
              isEdition={isEdition}
              isNew={isNewOperator}
              name="selectedPerson"
              newPersonTitle="attestationFormRequest.operators.modalAdd.newPerson"
              personValue={watch('person')}
              requestId={requestId}
              selectTitle="attestationFormRequest.operators.modalAdd.selectTitle"
              onNew={onNewContact}
              onSelected={onEmployeeSelected}
            >
              <Grid container direction="row" item>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth variant="standard">
                    {!operator && (
                      <StyledInput>
                        <FormInputField
                          fullWidth
                          label={translate('common.firstName')}
                          name="person.firstName"
                          required
                        />
                      </StyledInput>
                    )}
                    {!operator && (
                      <StyledInput>
                        <FormInputField
                          fullWidth
                          label={translate('common.lastName')}
                          name="person.lastName"
                          required
                        />
                      </StyledInput>
                    )}
                    <StyledInput>
                      <MultipleInputField
                        errorMessage={formState.errors?.person?.contact?.contactEmails?.message}
                        fullWidth
                        label={translate('common.email')}
                        name="person.contact.contactEmails"
                        required
                        validate={Validators.validateEmails}
                      />
                    </StyledInput>
                    <StyledInput>
                      <FormInputField
                        fullWidth
                        label={translate('common.occupation')}
                        name="person.occupation"
                      />
                    </StyledInput>
                    <StyledInput>
                      <FormInputField
                        fullWidth
                        label={translate('common.phone')}
                        name="person.contact.phoneNumber"
                      />
                    </StyledInput>
                  </FormControl>
                </Grid>
              </Grid>
            </SelectEmployee>
          )}
      </DialogContent>

      <OperatorActionButtons
        currentStep={currentStep}
        disabled={isLoading}
        goBack={() => setCurrentStep(OperatorModalStepsEnum.AddOperator)}
        onClose={onClose}
      />
    </FormContainer>
  );
};