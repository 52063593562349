import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const StyledFlag = styled('img')(({ theme }) => ({
  verticalAlign: 'middle',
  boxShadow: `0 2px 6px ${theme.palette.grey.main}`,
  width: 'auto',
  height: 'auto',
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'contain',
  boxSizing: 'border-box',
  margin: 'auto'
}));

const FlagImg = ({
  alt, isInsideSelect, countryCode, props
}) => (
  <StyledFlag
    alt={alt}
    src={isInsideSelect ? `https://flagcdn.com/w40/${countryCode}.png` : `https://flagcdn.com/${countryCode}.svg`}
    width={isInsideSelect ? 'auto' : width}
    {...props}
  />
);

const FlagContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center'
}));
const Flag = ({
  countryCode, alt, width, isInsideSelect, ...props
}) => (
  <FlagContainer style={{ height: isInsideSelect ? '40px' : 'auto', width: isInsideSelect ? 'auto' : width }}>
    <FlagImg alt={alt} countryCode={countryCode} isInsideSelect={isInsideSelect} props={props} />
  </FlagContainer>
);

export default Flag;

Flag.propTypes = {
  alt: PropTypes.string,
  countryCode: PropTypes.string.isRequired,
  select: PropTypes.bool,
  width: PropTypes.number
};

Flag.defaultProps = {
  alt: 'country-flag',
  select: false,
  width: 40
};