import { useModal } from 'hooks';
import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Divider, Grid, Typography } from '@mui/material';

import { faFileAlt } from '@fortawesome/pro-regular-svg-icons';

import { DocumentHelper, translate } from 'utils';

import { InputFile } from 'components/_commons/Form/Inputs';
import { AddDocumentItem } from 'components/_commons/Modals/common/AddDocumentItem';

export const SideDocumentBlock = ({
  labelButton, title, variant, formContext, showDivider, attestationCategories,
  diplomaTypes, fieldName, type, documentDefaultValues = {}
}) => {
  const showModal = useModal();
  const { fields, remove, append } = useFieldArray({
    control: formContext.control,
    name: fieldName
  });

  const removeDocument = useCallback((index) => showModal({
    type: 'WARNING',
    onConfirm: () => (
      remove(index)
    )
  }), []);

  return (
    <div className="m2">
      <Grid
        alignItems="center"
        container
        justifyContent="center"
        spacing={2}
        wrap="wrap"
      >
        <Grid container direction="column" item spacing={1}>
          <Grid item>
            <Typography variant="h4">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            {fields.length === 0 && (
              <InputFile
                endIcon={faFileAlt}
                fullWidth
                handleAddDocument={(file) => append({
                  ...documentDefaultValues,
                  ...file,
                  base64Content: DocumentHelper.getDocumentWithoutBase64(file)
                })}
                id={`input-file-${fieldName}`}
                label={null}
                text={labelButton}
                type={type}
                variant={variant}
                whiteSpace="break-spaces"
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid alignItems="center" container justifyContent="center">
        <Grid item>
          <div className="m3 spacing-bottom">
            {fields.map((field, index) => (
              <AddDocumentItem
                attestationCategories={attestationCategories}
                diplomaTypes={diplomaTypes}
                docIndex={index}
                document={field}
                documentType={documentDefaultValues?.type}
                fieldName={fieldName}
                formContext={formContext}
                key={`input-file-${fieldName}-${index + 1}`}
                removeDocument={() => removeDocument(index)}
                withoutDate
              />
            ))}
          </div>
        </Grid>
      </Grid>
      {showDivider && <Divider>{translate('attestationFormRequest.operators.divider')}</Divider>}
    </div>
  );
};