import { styled, useTheme } from '@mui/material/styles';
import { compact } from 'lodash';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, TableCell, Tooltip } from '@mui/material';
import { DocumentHelper, isMobile, translate } from 'utils';

import { DocumentCount } from 'components/AttestationForm/shared/DocumentCount';
import { GenericTable } from 'components/_commons/GenericTable/GenericTable';
import { TableTextCell } from 'components/_commons/GenericTable/TableTextCell';
import { RowPrefix } from 'components/_commons/RowPrefix/RowPrefix';
import { ATTESTATION_FORM_ACTIONS, DISABLE_ELEMENT_STYLE } from 'utils/constants';
import { AttestationCategoryHelper } from 'utils/helpers';
import { iconEnum, ICONS } from 'utils/icons';

const StrikedThroughCategory = styled('span')(({ theme, satisfied, operator }) => {
  if (!satisfied) {
    return {
      background: `linear-gradient(
                to bottom right,
              transparent  calc(50% - 1px),
              ${operator.activated ? 'black' : theme.palette.grey.main} calc(50% - 1px),
              ${operator.activated ? 'black' : theme.palette.grey.main} calc(50% + 1px) ,
              transparent  calc(50% + 1px)
              )`,
      minWidth: '20px',
      display: 'inline-block',
      textAlign: 'center'

    };
  }
  return null;
});

const DeactivableTableCell = styled(TableCell)(({ theme, disabled }) => {
  if (disabled) {
    return DISABLE_ELEMENT_STYLE(theme);
  }
  return {};
});

export const OperatorsTable = observer(({
  handleEdit, deactivate, handleRemove, showAttestation, showDiploma, categories,
  isLoading, operators, alertedOperators, availableActions, showHistory
}) => {
  const handleDeactivate = (row) => deactivate({ operatorId: row.id, deactivate: !row.activated });
  const theme = useTheme();
  const canValidateDocuments = availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_DOCUMENT_VALIDATION);
  const canEdit = availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_FORM);

  const listHeaders = () => compact([
    {
      name: 'state',
      width: 15,
      style: { borderBottom: 'none' },
      template: (row) => <RowPrefix alertedDataIds={alertedOperators} data={row} key={`${row.id}_state`} />
    },
    {
      name: 'change',
      label: 'attestationFormRequest.operators.table.change',
      hidden: !showHistory,
      template: (row) => (<TableTextCell disabled={!row.activated} key={`${row.id}_change`} value={row?.change?.label} />)
    },
    {
      name: 'fullName',
      labels: ['attestationFormRequest.operators.table.firstName', 'attestationFormRequest.operators.table.lastName'],
      template: (row) => (
        <TableTextCell
          disabled={!row.activated}
          key={`${row.id}_fullName`}
          value={`${row?.person?.firstName} ${row?.person?.lastName}`}
        />
      )
    },
    {
      name: 'justification',
      label: 'attestationFormRequest.operators.table.justifications',
      hideForMobile: true,
      template: (row) => (
        <DeactivableTableCell disabled={!row.activated} key={`${row.id}_justification`}>
          <DocumentCount
            color={row.activated ? theme.palette.primary.main : theme.palette.primary.lighter}
            count={row.attestationDocuments.length ?? 0}
            invalidCount={row.activated
              ? row.attestationDocuments.filter(DocumentHelper.isInvalid).length ?? 0
              : 0}
            title={translate('attestationFormRequest.operators.table.aptitudeAttestation')}
            toReviewCount={canValidateDocuments && row.activated
              ? row.attestationDocuments.filter(DocumentHelper.notYetValidated).length ?? 0
              : 0}
            onShowDocuments={() => row.attestationDocuments.length && showAttestation(row)}
          />
          <DocumentCount
            color={row.activated ? theme.palette.primary.main : theme.palette.primary.lighter}
            count={row.diplomaDocuments.length}
            hideCount={!row.diplomaDocuments.length}
            invalidCount={row.activated
              ? row.diplomaDocuments.filter(DocumentHelper.isInvalid).length ?? 0
              : 0}
            title={translate('attestationFormRequest.operators.table.diploma')}
            toReviewCount={canValidateDocuments && row.activated
              ? row.diplomaDocuments.filter(DocumentHelper.notYetValidated).length ?? 0
              : 0}
            onShowDocuments={() => showDiploma(row)}
          />
        </DeactivableTableCell>
      )
    },
    {
      name: 'categories',
      label: 'attestationFormRequest.operators.table.categories',
      hideForMobile: true,
      template: (row) => (
        <TableTextCell
          disabled={!row.activated}
          key={`${row.id}_categories`}
          value={((!row.deleted && categories) ? categories : []).map((category) => (
            <StrikedThroughCategory
              key={category}
              operator={row}
              satisfied={row.attestationDocuments?.some(
                (document) => AttestationCategoryHelper.isCategoryCovered(category, document?.attestationCategories)
              )}
            >
              {`${category} `}
            </StrikedThroughCategory>
          ))}
        />
      )
    },
    canEdit && ({
      name: 'actions',
      label: 'attestationFormRequest.operators.table.actions',
      width: '155px',
      template: (row) => (row.deleted
        ? <TableTextCell disabled={row.deleted} key={`${row.id}_actions`} value={' '} />
        : (
          <TableCell
            className="spacing-right"
            key={`${row.id}_actions`}
          >
            {!isMobile() && (
              <Tooltip title={translate('attestationFormRequest.operators.edit')}>
                <IconButton
                  color="primary"
                  edge="end"
                  onClick={(e) => { e.stopPropagation(); handleEdit(row); }}
                >
                  <FontAwesomeIcon icon={iconEnum.pen.icon} size="sm" swapOpacity />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip
              title={row.activated
                ? translate('attestationFormRequest.operators.disable')
                : translate('attestationFormRequest.operators.enable')}
            >
              <IconButton
                color="primary"
                edge="end"
                onClick={(e) => { e.stopPropagation(); handleDeactivate(row); }}
              >
                <FontAwesomeIcon icon={row.activated ? ICONS.TOGGLE.ON.icon : ICONS.TOGGLE.OFF.icon} size="sm" />
              </IconButton>
            </Tooltip>
            <Tooltip title={translate('attestationFormRequest.operators.delete')}>
              <IconButton
                color="primary"
                onClick={(e) => { e.stopPropagation(); handleRemove(row); }}
              >
                <FontAwesomeIcon
                  color={iconEnum.trashAlt.defaultColor}
                  icon={iconEnum.trashAlt.icon}
                  size="sm"
                  swapOpacity
                />
              </IconButton>
            </Tooltip>
          </TableCell>
        )
      )
    })
  ]);

  return (
    <GenericTable
      displayTotal={false}
      headers={listHeaders()}
      id="operatorsList"
      isLoading={isLoading}
      rows={operators || []}
    />
  );
});