import {
  DialogContent, Divider, Grid, Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DocumentList } from 'components/_commons/Document/DocumentList';
import { FormInputField } from 'components/_commons/Form/Inputs';
import FormSelectField from 'components/_commons/Form/Inputs/SelectField/FormSelectField';
import { CustomActionButtons } from 'components/_commons/Modals/_CustomActionButtons';
import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { useModal } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { FluidBalanceService } from 'services/FluidBalanceService';
import { DocumentHelper, translate } from 'utils';

const ItemSectionTitle = ({ title }) => {
  const theme = useTheme();
  return (
    <Grid item>
      <Typography color={theme.palette.grey.main} style={{ marginBottom: 10 }}>
        {translate(title)}
      </Typography>
    </Grid>
  );
};

const ItemStock = ({
  name, year, bold, children
}) => (
  <Grid item>
    <Grid alignItems="center" container direction="row" justifyContent="space-between">
      <Grid item>
        <Typography fontWeight={bold ? 'bold' : 'unset'}>
          {translate(`fluidBalance.updateEntryModal.${name}`, { year })}
        </Typography>
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  </Grid>
);

const ItemInputStock = ({
  name, year, canEdit, onChange, setValue
}) => (
  <ItemStock name={name} year={year}>
    <FormInputField
      disabled={!canEdit}
      endAdornment=" kg"
      name={name}
      size="small"
      textAlign="right"
      type="numeric"
      width={200}
      onChange={(event) => {
        let { value } = event.target;
        if (value.length >= 10) {
          value = value.replace(/[^\d]/, '').slice(0, 10);
        } else {
          value = value.replace(/[^\d]/, '');
        }
        if (value === '') {
          value = '0';
        }
        setValue(name, value);
        onChange && onChange();
      }}
    />
  </ItemStock>
);

const ItemInputText = ({
  name, canEdit, required = false, width = 300
}) => (
  <ItemStock name={name}>
    <FormInputField disabled={!canEdit} name={name} required={required} size="small" width={width} />
  </ItemStock>
);

const ItemAutoStock = ({
  name, year, value, bold
}) => (
  <ItemStock bold={bold} name={name} year={year}>
    <Typography
      fontWeight={bold ? 'bold' : 'unset'}
      gutterBottom
      style={{ margin: bold ? '10px 0' : '2px 0' }}
    >
      {`${value || 0} kg`}
    </Typography>
  </ItemStock>
);

const CustomDivider = () => (
  <Grid item>
    <Divider
      flexItem
      orientation="horizontal"
      sx={{ mb: '-1px' }}
      variant="middle"
    />
  </Grid>
);

export const UpdateFluidBalanceModal = ({
  onClose,
  onSubmit,
  balanceEntry,
  year,
  isCreation,
  fluidOptions,
  fluidOptionsLoading
}) => {
  const defaultValues = {
    ...balanceEntry,
    initialAmountHeld: balanceEntry?.initialAmountHeld ?? 0,
    initialAmountHeldNew: balanceEntry?.initialAmountHeldNew ?? 0,
    initialAmountHeldUsed: balanceEntry?.initialAmountHeldUsed ?? 0,
    finalAmountHeld: balanceEntry?.finalAmountHeld ?? 0,
    finalAmountHeldNew: balanceEntry?.finalAmountHeldNew ?? 0,
    finalAmountHeldUsed: balanceEntry?.finalAmountHeldUsed ?? 0,
    amountBought: balanceEntry?.amountBought ?? 0,
    amountBoughtInFrance: balanceEntry?.amountBoughtInFrance ?? 0,
    amountBoughtOutsideFrance: balanceEntry?.amountBoughtOutsideFrance ?? 0,
    amountDestroyed: balanceEntry?.amountDestroyed ?? 0,
    amountGivenBack: balanceEntry?.amountGivenBack ?? 0,
    amountHandedOver: balanceEntry?.amountHandedOver ?? 0,
    amountLoaded: balanceEntry?.amountLoaded ?? 0,
    amountLoadedMaintenance: balanceEntry?.amountLoadedMaintenance ?? 0,
    amountLoadedNewMaterial: balanceEntry?.amountLoadedNewMaterial ?? 0,
    amountProcessed: balanceEntry?.amountProcessed ?? 0,
    amountRecovered: balanceEntry?.amountRecovered ?? 0,
    amountRecoveredMaintenance: balanceEntry?.amountRecoveredMaintenance ?? 0,
    amountRecoveredOutOfOrder: balanceEntry?.amountRecoveredOutOfOrder ?? 0,
    amountRecycled: balanceEntry?.amountRecycled ?? 0,
    amountRegenerated: balanceEntry?.amountRegenerated ?? 0,
    deltaInput: balanceEntry?.deltaInput ?? 0,
    deltaOutput: balanceEntry?.deltaOutput ?? 0,
    deltaTotal: balanceEntry?.deltaTotal ?? 0
  };
  const theme = useTheme();
  const formContext = useForm({ defaultValues });
  const { watch, setValue } = formContext;
  const canEdit = !balanceEntry || balanceEntry.canEdit;
  const { enqueueSnackbar } = useSnackbar();
  const showModal = useModal();
  const [fluidBalanceHandedOverJustification, setFluidBalanceHandedOverJustification] = useState([]);

  useEffect(() => {
    if (balanceEntry && !fluidBalanceHandedOverJustification?.length) {
      FluidBalanceService.getFluidBalanceHandedOverJustification(balanceEntry.id)
        .then((documentList) => setFluidBalanceHandedOverJustification(documentList))
        .catch((error) => enqueueSnackbar(error.message || error, { variant: 'error' }));
    }
  }, [balanceEntry]);

  const sumValues = useCallback((...names) => names.reduce((prev, curr) => {
    const currentValue = watch(curr);
    return parseInt(currentValue ? (prev ?? 0) + Number(currentValue) : prev, 10);
  }, 0), [watch]);

  const onSubmitModal = useCallback((submittedFields) => {
    onSubmit({
      ...submittedFields,
      fluidBalanceDocumentViews: fluidBalanceHandedOverJustification.map((file) => ({
        id: file?.id,
        document: file?.document,
        type: 'FLUID_BALANCE_HANDED_OVER_JUSTIFICATION'
      }))
    });
  }, [onSubmit, fluidBalanceHandedOverJustification]);

  const updateInitialAmountHeld = () => (
    setValue('initialAmountHeld', sumValues('initialAmountHeldNew', 'initialAmountHeldUsed')));
  const updateFinalAmountHeld = () => (
    setValue('finalAmountHeld', sumValues('finalAmountHeldNew', 'finalAmountHeldUsed')));
  const updateAmountBought = () => (
    setValue('amountBought', sumValues('amountBoughtInFrance', 'amountBoughtOutsideFrance')));
  const updateAmountLoaded = () => (
    setValue('amountLoaded', sumValues('amountLoadedNewMaterial', 'amountLoadedMaintenance')));
  const updateAmountRecovered = () => (
    setValue('amountRecovered', sumValues('amountRecoveredOutOfOrder', 'amountRecoveredMaintenance')));
  const updateAmountProcessed = () => (
    setValue('amountProcessed', sumValues('amountRecycled', 'amountRegenerated', 'amountDestroyed')));

  const deltaInput = sumValues('initialAmountHeld', 'amountBought', 'amountRecovered') || 0;
  const deltaOutput = sumValues('finalAmountHeld', 'amountLoaded', 'amountGivenBack', 'amountHandedOver') || 0;

  useEffect(() => {
    updateInitialAmountHeld();
    updateFinalAmountHeld();
    updateAmountBought();
    updateAmountLoaded();
    updateAmountRecovered();
    updateAmountProcessed();
  }, []);

  const handleDeleteJustification = useCallback((file) => showModal({
    type: 'WARNING',
    onConfirm: () => {
      if (file.id) {
        setFluidBalanceHandedOverJustification(fluidBalanceHandedOverJustification.filter(
          (fileToCompare) => fileToCompare.id !== file.id
        ));
      } else if (fluidBalanceHandedOverJustification.some(
        (doc) => doc?.addingDate === file?.addingDate && doc?.document?.name === file?.document?.name
      )) {
        setFluidBalanceHandedOverJustification(
          fluidBalanceHandedOverJustification.filter(
            (doc) => doc?.addingDate !== file?.addingDate || doc?.document?.name !== file?.document?.name
          )
        );
      }
    }
  }), [fluidBalanceHandedOverJustification]);

  const handleAddJustification = useCallback((doc) => {
    if (doc) {
      const docToSave = {
        balanceEntryId: balanceEntry?.id,
        addingDate: Date.now(),
        document: {
          name: doc.name,
          base64Content: DocumentHelper.getDocumentWithoutBase64(doc)
        }
      };
      setFluidBalanceHandedOverJustification((docsAlreadyPresent) => [...docsAlreadyPresent, docToSave]);
    }
  }, [balanceEntry]);

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{ autoComplete: 'off', name: 'updateFluidBalance', style: { height: '100%', overflow: 'auto' } }}
      onSuccess={onSubmitModal}
    >
      <ModalHeader onClose={onClose}>
        {isCreation
          ? translate('fluidBalance.updateEntryModal.titleCreate')
          : translate('fluidBalance.updateEntryModal.titleUpdate', { name: balanceEntry?.fluid?.name })}
      </ModalHeader>

      <DialogContent style={{
        width: '65vw', height: '100%', paddingTop: '30px', overflowY: 'scroll'
      }}
      >
        <Grid container direction="column" spacing={2}>
          {isCreation && (
            <Grid item>
              <FormSelectField
                disableClearable
                label={translate('fluidBalance.updateEntryModal.selectOption')}
                loading={fluidOptionsLoading}
                loadingText={translate('common.loading')}
                name="selectedFluid"
                noOptionsText={translate('warnings.noOptionsAvailable')}
                options={fluidOptions}
                required
                variant="outlined"
                onChange={(event, selected) => setValue('fluid', { id: selected.value })}
              />
            </Grid>
          )}
          <Grid item>
            <Grid container direction="column">
              <ItemSectionTitle title="fluidBalance.updateEntryModal.stocksTitle" />
              <ItemInputStock
                canEdit={canEdit}
                name="initialAmountHeldNew"
                setValue={setValue}
                year={year}
                onChange={updateInitialAmountHeld}
              />
              <ItemInputStock
                canEdit={canEdit}
                name="initialAmountHeldUsed"
                setValue={setValue}
                year={year}
                onChange={updateInitialAmountHeld}
              />
              <ItemAutoStock
                bold
                name="initialAmountHeld"
                value={watch('initialAmountHeld')}
                year={year}
              />
              <ItemInputStock
                canEdit={canEdit}
                name="finalAmountHeldNew"
                setValue={setValue}
                year={year}
                onChange={updateFinalAmountHeld}
              />
              <ItemInputStock
                canEdit={canEdit}
                name="finalAmountHeldUsed"
                setValue={setValue}
                year={year}
                onChange={updateFinalAmountHeld}
              />
              <ItemAutoStock bold name="finalAmountHeld" value={watch('finalAmountHeld')} year={year} />
            </Grid>
          </Grid>
          <CustomDivider />
          <Grid item>
            <Grid container direction="column">
              <ItemSectionTitle title="fluidBalance.updateEntryModal.boughtTitle" />
              <ItemInputStock
                canEdit={canEdit}
                name="amountBoughtInFrance"
                setValue={setValue}
                onChange={updateAmountBought}
              />
              <ItemInputStock
                canEdit={canEdit}
                name="amountBoughtOutsideFrance"
                setValue={setValue}
                onChange={updateAmountBought}
              />
              {
                watch('amountBoughtOutsideFrance') > 0
                && (
                  <Typography color={theme.palette.warning.main} variant="body2">
                    {translate('fluidBalance.updateEntryModal.warningOutsideOfFrance')}
                  </Typography>
                )
              }
              <ItemAutoStock bold name="amountBought" value={watch('amountBought')} />
              <ItemInputStock
                canEdit={canEdit}
                name="amountLoadedNewMaterial"
                setValue={setValue}
                onChange={updateAmountLoaded}
              />
              <ItemInputStock
                canEdit={canEdit}
                name="amountLoadedMaintenance"
                setValue={setValue}
                onChange={updateAmountLoaded}
              />
              <ItemAutoStock bold name="amountLoaded" value={watch('amountLoaded')} />
              <ItemInputStock
                canEdit={canEdit}
                name="amountRecoveredOutOfOrder"
                setValue={setValue}
                onChange={updateAmountRecovered}
              />
              <ItemInputStock
                canEdit={canEdit}
                name="amountRecoveredMaintenance"
                setValue={setValue}
                onChange={updateAmountRecovered}
              />
              <ItemAutoStock bold name="amountRecovered" value={watch('amountRecovered')} />
              <ItemInputStock canEdit={canEdit} name="amountGivenBack" setValue={setValue} />
            </Grid>
          </Grid>
          <CustomDivider />
          <Grid item>
            <Grid container direction="column">
              <ItemSectionTitle title="fluidBalance.updateEntryModal.gapsTitle" />
              <ItemAutoStock name="deltaInput" value={deltaInput} />
              <ItemAutoStock name="deltaOutput" value={deltaOutput} />
              <ItemAutoStock bold name="deltaTotal" value={parseInt(Math.abs((deltaInput - deltaOutput)), 10)} />
            </Grid>
          </Grid>
          <CustomDivider />
          <Grid item>
            <Grid container direction="column">
              <ItemSectionTitle title="fluidBalance.updateEntryModal.disposalTitle" />
              <ItemInputStock canEdit={canEdit} name="amountHandedOver" setValue={setValue} />
              {
                watch('amountHandedOver') > 0
                  && (
                    <Grid item>
                      <Grid item marginTop="10px">
                        <ItemInputText canEdit={canEdit} name="justificationHandedOver" width={500} />
                      </Grid>
                      <Grid item marginTop="10px">
                        <DocumentList
                          canEdit={canEdit}
                          canValidate={false}
                          documents={fluidBalanceHandedOverJustification}
                          importLabel="button.import"
                          marginTop="10px"
                          spacing={2.5}
                          titleStyles={{
                            textAlign: 'center',
                            variant: 'h6'
                          }}
                          onDelete={handleDeleteJustification}
                          onImport={handleAddJustification}
                        />
                      </Grid>
                    </Grid>
                  )
              }
            </Grid>
          </Grid>
          <CustomDivider />
          <Grid item>
            <Grid container direction="column">
              <ItemSectionTitle title="fluidBalance.updateEntryModal.processedTitle" />
              <ItemInputStock
                canEdit={canEdit}
                name="amountRecycled"
                setValue={setValue}
                onChange={updateAmountProcessed}
              />
              <ItemInputStock
                canEdit={canEdit}
                name="amountRegenerated"
                setValue={setValue}
                onChange={updateAmountProcessed}
              />
              <ItemInputText canEdit={canEdit} name="regenerationInstitution" required={watch('amountRegenerated') > 0} />
              <ItemInputStock
                canEdit={canEdit}
                name="amountDestroyed"
                setValue={setValue}
                onChange={updateAmountProcessed}
              />
              <ItemInputText canEdit={canEdit} name="destructionInstitution" required={watch('amountDestroyed') > 0} />
              <ItemAutoStock bold name="amountProcessed" value={watch('amountProcessed')} />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <CustomActionButtons disabled={!canEdit} onClose={onClose} />
    </FormContainer>
  );
};