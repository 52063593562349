import { DocumentAssignationModal } from 'components/_commons/Modals/DocumentAssignation/DocumentAssignationModal';
import { ImportModal } from 'components/_commons/Modals/ImportModal';
import { ValidateCertificationProgramModal } from 'components/_commons/Modals/ValidateCertificationProgramModal';
import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Slide } from '@mui/material';
import {
  AddAlertModal,
  AddContactModal,
  AddOperatorModal,
  AddToolsModal, AdministrateToolModal,
  AttestationCategoriesModal,
  ChangeLanguageModal,
  ClassifyDocumentModal,
  ConfirmModal,
  CreateAuditModal,
  CreateCompanyModal,
  CreateInstitutionGroupModal,
  CreateInstitutionModal,
  CreateUserModal,
  DefaultModal, DisableAttestationModal,
  DisplayAuditModal,
  DisplayDocumentModal,
  DocumentsVerificationModal, EditFluidBalanceCampaignModal,
  ErrorModal, FluidBalanceAssignationModal,
  RejectRequestSupervisionModal,
  ResolveAlertModal,
  UpdateFluidBalanceModal,
  UpdateRolesModal,
  UpdateUserLevelModal,
  UpdateUserModal,
  WarningModal
} from 'components/_commons/Modals';
import { CookiesModal } from 'components/_commons/Modals/CookiesModal';
import { RequestAssignationModal } from 'components/_commons/Modals/RequestAssignationModal';

const MODAL_TYPES = {
  ADD_ALERT: AddAlertModal,
  ADD_TOOLS: AddToolsModal,
  ADMINISTRATE_TOOL: AdministrateToolModal,
  ADD_CONTACT: AddContactModal,
  CHANGE_INSTITUTION: UpdateUserLevelModal,
  CHANGE_LANGUAGE: ChangeLanguageModal,
  DEFAULT: DefaultModal,
  ADD_OPERATOR: AddOperatorModal,
  DISPLAY_DOCUMENT: DisplayDocumentModal,
  DOCUMENTS_VERIFICATION: DocumentsVerificationModal,
  DOCUMENT_ASSIGNATION: DocumentAssignationModal,
  CLASSIFY_DOCUMENTS: ClassifyDocumentModal,
  RESOLVE_ALERT: ResolveAlertModal,
  CREATE_COMPANY: CreateCompanyModal,
  CREATE_INSTITUTION: CreateInstitutionModal,
  CREATE_INSTITUTION_GROUP: CreateInstitutionGroupModal,
  EDIT_FLUID_BALANCE: UpdateFluidBalanceModal,
  COOKIES_MODAL: CookiesModal,
  CREATE_USER: CreateUserModal,
  ASSIGN_REQUEST: RequestAssignationModal,
  ASSIGN_FLUID_BALANCE: FluidBalanceAssignationModal,
  EDIT_FLUID_BALANCE_CAMPAIGN: EditFluidBalanceCampaignModal,
  UPDATE_USER: UpdateUserModal,
  UPDATE_ROLES: UpdateRolesModal,
  SELECT_ATTESTATION_CATEGORIES: AttestationCategoriesModal,
  CREATE_AUDIT: CreateAuditModal,
  REJECT_REQUEST_SUPERVISION: RejectRequestSupervisionModal,
  DISPLAY_AUDIT: DisplayAuditModal,
  DISABLE_ATTESTATION: DisableAttestationModal,
  IMPORT: ImportModal,
  VALIDATE_CERTIFICATION_PROGRAM: ValidateCertificationProgramModal
};

const SECONDARY_MODAL_TYPES = {
  CONFIRM: ConfirmModal,
  ERROR: ErrorModal,
  WARNING: WarningModal
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const Modal = ({
  isOpen, modalState, closeModal
}) => {
  if (!isOpen || !modalState) {
    return null;
  }
  const {
    onClose,
    onSubmit,
    type,
    backdropClick,
    closeOnSubmit,
    fullscreen = false,
    maxWidth = 'xl',
    height,
    ...rest
  } = modalState;

  const CurrentModal = type && MODAL_TYPES[type];
  const CurrentSecondaryModal = type && SECONDARY_MODAL_TYPES[type];
  const isFullscreen = fullscreen || document.getElementsByTagName('body')[0].clientWidth <= 768 || type === 'DISPLAY_DOCUMENT';

  const closeInnerModal = (event) => {
    onClose && onClose(event);
    closeModal();
  };

  const handleClose = (event, reason) => {
    if (reason && backdropClick && ['backdropClick', 'escapeKeyDown'].includes(reason)) {
      closeInnerModal(event);
    }
  };

  const handleSubmit = (e) => {
    onSubmit && onSubmit(e);
    if (closeOnSubmit) {
      closeModal();
    }
  };

  if (!CurrentModal && !CurrentSecondaryModal) return null;

  return (
    <>
      {CurrentModal && (
        <Dialog
          disableEscapeKeyDown={!backdropClick}
          fullScreen={isFullscreen}
          maxWidth={maxWidth}
          open={isOpen}
          PaperProps={{ sx: { height, maxWidth: 2000 } }}
          TransitionComponent={Transition}
          transitionDuration={300}
          onClose={handleClose}
        >
          <CurrentModal onClose={closeInnerModal} onSubmit={handleSubmit} {...rest} />
        </Dialog>
      )}
      {CurrentSecondaryModal && (
        <Dialog
          disableEscapeKeyDown={!backdropClick}
          fullScreen={false}
          maxWidth="lg"
          open={isOpen}
          TransitionComponent={Transition}
          transitionDuration={300}
          onClose={onClose}
        >
          <CurrentSecondaryModal onClose={closeInnerModal} onSubmit={handleSubmit} {...rest} />
        </Dialog>
      )}
    </>
  );
};

Modal.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  modalState: PropTypes.shape({})
};

Modal.defaultProps = {
  closeModal: () => {},
  isOpen: false,
  modalState: {}
};

export default Modal;