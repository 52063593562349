import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { translate } from 'utils';
import {
  Button, DialogActions, DialogContent, Typography
} from '@mui/material';
import { ModalFormField } from 'components/_commons/Modals/_ModalFormField';
import { FormInputField } from 'components/_commons/Form/Inputs';
import { FormContainer, useForm } from 'react-hook-form-mui';
import React, { useCallback } from 'react';
import { ATTESTATION_STATUS } from 'utils/constants';

export const DisableAttestationModal = ({
  onClose,
  onSubmit,
  multiple,
  canOnlyRemove
}) => {
  const formContext = useForm();
  const { watch, trigger } = formContext;

  const getSubmittedComment = useCallback(() => watch('comment'), [watch]);

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{ autoComplete: 'off', name: 'disableAttestationModal' }}
    >
      <ModalHeader onClose={onClose}>
        {multiple ? translate('disableAttestationModal.mass.title') : translate('disableAttestationModal.title')}
      </ModalHeader>

      {multiple && (
        <DialogContent style={{ width: '50vw', marginTop: '30px' }}>
          <Typography>
            {translate('disableAttestationModal.warning.massDisable')}
          </Typography>
        </DialogContent>
      )}

      <DialogContent style={{ width: '50vw', marginTop: '30px' }}>
        <ModalFormField title={translate('disableAttestationModal.commentTitle')}>
          <FormInputField
            fullWidth
            label={translate('disableAttestationModal.commentLabel')}
            name="comment"
            required
          />
        </ModalFormField>
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          onClick={onClose}
        >
          {translate('button.cancel')}
        </Button>

        <Button
          color="primary"
          onClick={async () => {
            const result = await trigger();
            result && onSubmit({ comment: getSubmittedComment(), status: ATTESTATION_STATUS.REMOVED });
            result && onClose();
          }}
        >
          {multiple ? translate('button.disableAttestation.mass.withdraw') : translate('button.disableAttestation.withdraw')}
        </Button>

        {!canOnlyRemove && (
          <Button
            color="primary"
            onClick={async () => {
              const result = await trigger();
              result && onSubmit({
                comment: getSubmittedComment(),
                status: ATTESTATION_STATUS.SUSPENDED
              });
              result && onClose();
            }}
          >
            {multiple ? translate('button.disableAttestation.mass.suspend') : translate('button.disableAttestation.suspend')}
          </Button>
        )}
      </DialogActions>
    </FormContainer>
  );
};