import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { Typography } from '@mui/material';
import { Flag } from 'components/_commons/Flag';
import ItemOption from 'components/_commons/Form/ItemOption/ItemOption';
import { SelectField } from 'components/_commons/Form/Inputs';

const CustomOption = ({ data, innerProps, selectProps }) => {
  const { isFlagElement, leftElement } = selectProps;
  const optionFlag = (
    <Flag
      alt={data.countryCode ?? data.value}
      countryCode={data.countryCode ?? data.value}
      isInsideSelect
      style={{ margin: '3px 10px' }}
    />
  );
  return (
    <ItemOption {...innerProps} isInsideSelect>
      {isFlagElement ? optionFlag : leftElement && leftElement(data)}
      <Typography>
        {data.label}
      </Typography>
    </ItemOption>
  );
};

const CustomValue = ({ data, innerProps, selectProps }) => {
  const { isFlagElement, leftElement, valueLabelVariant } = selectProps;
  const valueFlag = (
    <Flag
      alt={data.countryCode ?? data.value}
      countryCode={data.countryCode ?? data.value}
      isInsideSelect
      style={{ margin: '5px 0' }}
    />
  );
  return (
    <ItemOption {...innerProps} isInsideSelect style={{ gridArea: ' 1/1/2/3' }}>
      {isFlagElement ? valueFlag : leftElement && leftElement(data)}
      <Typography style={{ margin: '0.8rem', whiteSpace: 'nowrap' }} variant={valueLabelVariant}>
        {data.label}
      </Typography>
    </ItemOption>
  );
};

const SelectItemWithLeftElement = ({
  value, onChange, required, isLoading, options, label, placeholder, isFlagElement,
  leftElement, valueLabelVariant, isDisabled, ...props
}) => {
  const handleChange = useCallback((language) => onChange(language), [onChange]);

  return (
    isLoading ? (
      <FontAwesomeIcon icon={faSpinner} spin />
    ) : (
      <SelectField
        components={{ Option: CustomOption, SingleValue: CustomValue }}
        isDisabled={isDisabled}
        isFlagElement={isFlagElement}
        label={label}
        leftElement={leftElement}
        options={options}
        placeholder={placeholder}
        required={required}
        value={value}
        valueLabelVariant={valueLabelVariant}
        onChange={handleChange}
        {...props}
      />
    )
  );
};

SelectItemWithLeftElement.propTypes = {
  isClearable: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({}))
  ]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  notEditable: PropTypes.bool
};

SelectItemWithLeftElement.defaultProps = {
  isClearable: false,
  label: 'common.selectOption',
  notEditable: false,
  required: false,
  value: null
};

export default SelectItemWithLeftElement;