import React, { useCallback, useEffect, useState } from 'react';

import styled from 'styled-components';
import {
  Button, DialogActions,
  DialogContent, Grid, Typography
} from '@mui/material';
import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { translate } from 'utils';
import { CookiesHelper } from 'utils/helpers/CookiesHelper';
import sanitizeHtml from 'sanitize-html';

const StyledDialogContent = styled(DialogContent)`
  max-width: 800px;
  min-height: 245px;
`;

export const CookiesModal = ({
  onClose
}) => {
  const expirationDate = new Date();
  expirationDate.setMonth(expirationDate.getMonth() + 6);
  const breakpoint = 600;
  const [justify, setJustify] = useState(window.innerWidth < breakpoint ? 'center' : 'space-between');

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth < breakpoint) {
        setJustify('center');
      } else {
        setJustify('space-between');
      }
    });
  }, []);

  const rejectCookiesAndClose = useCallback(() => {
    CookiesHelper.setGACookie(false);
    onClose();
  }, [onClose, expirationDate]);

  const acceptCookiesAndClose = useCallback(() => {
    CookiesHelper.setGACookie(true);
    onClose();
  }, [onClose, expirationDate]);

  return (
    <>
      <ModalHeader onClose={rejectCookiesAndClose}>
        <Typography style={{ fontSize: '2.0rem' }}>
          {translate('commons.rgpd.cookies.welcome', { applicationName: 'Datafluides' })}
        </Typography>
      </ModalHeader>
      <StyledDialogContent>
        <Grid container spacing={1} style={{ marginTop: 30 }}>
          <Grid item sm={6} xs={12}>
            <img alt="tecnea_welcome" src={`${process.env.PUBLIC_URL}/assets/images/tecnea-welcome.png`} width="100%" />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(translate('common.rgpd.cookies.popupIntro')) }}
              style={{ marginBottom: 20, fontSize: '1.7rem' }}
            />
          </Grid>
        </Grid>
        <Typography
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(translate('common.rgpd.cookies.defaultAction')) }}
          style={{ marginTop: 10, fontSize: '1.7rem' }}
        />
        <Typography
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(translate('common.rgpd.cookies.howToChange')) }}
          style={{ marginTop: 10, fontSize: '1.7rem' }}
        />
      </StyledDialogContent>
      <DialogActions>
        <Grid container justifyContent={justify} spacing={1} style={{ margin: 20 }}>
          <Grid item />
          <Grid item>
            <Grid container>
              <Grid item>
                <Button
                  color="secondary"
                  id="cancel"
                  style={{ marginRight: 30 }}
                  variant="contained"
                  onClick={rejectCookiesAndClose}
                >
                  {translate('button.refuse')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  id="confirm"
                  variant="contained"
                  onClick={acceptCookiesAndClose}
                >
                  {translate('button.accept')}
                </Button>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </DialogActions>
    </>
  );
};