import { faChevronRight, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Tooltip, Typography } from '@mui/material';
import { Button } from 'components/_commons/Button';
import { GenericFilters } from 'components/_commons/GenericFilters/GenericFilters';
import { ButtonSpinner, Spinner } from 'components/_commons/Skeletons';
import { SubtitlePage } from 'components/_commons/Text';
import { UnclassifiedDocumentType } from 'components/AttestationForm';
import { BlockTool } from 'components/AttestationForm/_sections/Tools';
import { SectionEnum } from 'components/AttestationForm/attestationFormSection.enum';
import { DocumentsTable } from 'components/AttestationForm/shared/DocumentsTable';
import { useFetch, useModal } from 'hooks';
import { useExcelImport } from 'hooks/useExcelImport';
import { union, xor } from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import {
  AttestationFormRequestService, AttestationFormToolService, DocumentService, InstitutionService
} from 'services';
import { ToolService } from 'services/ToolService';
import { DocumentHelper, translate } from 'utils';
import { ATTESTATION_FORM_ACTIONS } from 'utils/constants';
import { DateHelper } from 'utils/helpers';
import { iconEnum, ICONS } from 'utils/icons';

export const ToolsStep = ({
  requestId,
  updateAlerts,
  availableActions,
  alertedTools,
  institutionId,
  showHistory,
  updateUnreadDocumentAlerts
}) => {
  const openModal = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [openedAccordionsTypes, setOpenedAccordionTypes] = useState([]);
  const [refreshDocumentList, setRefreshDocumentList] = useState(0);
  const [refreshToolList, setRefreshToolList] = useState(0);
  const [search, setSearch] = useState('');
  const [isExporting, setIsExporting] = useState(false);
  const canEdit = availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_FORM)
    || availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_FORM_TOOL_STEP);
  const canValidate = availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_DOCUMENT_VALIDATION);

  // eslint-disable-next-line max-len
  const { response: toolTypes, isLoading } = useFetch(() => ToolService.getEquipmentTypesForAttestationRequest(requestId), {}, [refreshToolList]);
  const { response: history } = (
    useFetch(() => InstitutionService.getChangesSinceLastEvaluation(institutionId, SectionEnum.Tooling), [])
  );
  const {
    response: unclassifiedDocuments,
    isLoading: unclassifiedDocumentsLoading
  } = useFetch(
    () => AttestationFormRequestService.getUnclassifiedDocuments(
      requestId,
      UnclassifiedDocumentType.UnclassifiedTool
    ),
    [],
    [refreshDocumentList]
  );

  const reloadAlerts = useCallback(
    () => {
      updateAlerts(SectionEnum.Tooling);
      if (canValidate) {
        updateUnreadDocumentAlerts();
      }
    },
    [updateAlerts, canValidate]
  );

  const reloadListAndAlerts = useCallback(() => {
    setRefreshToolList((reloadIndexes) => reloadIndexes + 1);
    reloadAlerts();
  }, [reloadAlerts]);

  useEffect(() => {
    reloadAlerts();
  }, [refreshDocumentList, reloadAlerts]);

  const {
    isImporting,
    importFunc: importTools
  } = useExcelImport((file) => AttestationFormToolService.importAttestationFormTools(requestId, file), reloadListAndAlerts);

  const getToolsWithHistory = useCallback((tools, category) => [
    ...tools.map((tool) => ({
      ...tool,
      change: history.find((t) => t.subjectId === tool.id)?.type
    })),
    ...history.filter((t) => t.type.value === 'DELETION' && t.subjectDetail === category)
      .map((t) => ({
        deleted: true,
        change: t.type,
        equipmentView: {
          type: { label: t.subjectString },
          brand: '',
          model: '',
          serialNumber: ''
        }
      }))
  ], [history]);

  const addTools = useCallback((equipmentType, reload, equipment) => {
    openModal({
      type: 'ADD_TOOLS',
      closeOnSubmit: true,
      maxWidth: false,
      modalState: {
        equipment,
        equipmentType,
        requestId
      },
      onSubmit: (data) => {
        const equipmentData = {
          ...data,
          equipmentView: {
            ...data.equipmentView,
            acquisitionDate: DateHelper.getDateWithoutTimezoneTransformation(data.equipmentView.acquisitionDate),
            documentList: {
              ...data?.equipmentView?.documentList,
              documents: Object.keys(data.equipmentView?.documentList?.innerDocuments ?? {})
                .reduce((acc, prev) => ([...acc, ...data.equipmentView.documentList.innerDocuments[prev]]), [])
            }
          },
          activated: true
        };

        delete equipmentData.equipmentView.innerDocuments;

        if (equipment?.id) {
          AttestationFormToolService.update(equipmentData)
            .then(() => {
              enqueueSnackbar(translate('attestationFormRequest.tools.snackbar.edited'), { variant: 'success' });
              updateAlerts(SectionEnum.Tooling);
              reload();
            })
            .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }));
        } else {
          AttestationFormRequestService.createTool(requestId, equipmentData)
            .then(() => {
              enqueueSnackbar(translate('attestationFormRequest.tools.snackbar.created'), { variant: 'success' });
              updateAlerts(SectionEnum.Tooling);
              reload();
            })
            .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }));
        }
      }
    });
  }, [openModal, updateAlerts]);

  const toggleEquipmentTypes = useCallback(
    () => setOpenedAccordionTypes(openedAccordionsTypes.length
      ? []
      : [...toolTypes.mandatoryTypes, ...toolTypes.optionalTypes].map((it) => it.value)),
    [openedAccordionsTypes, toolTypes]
  );

  const onToggleEquipmentType = useCallback((type, open) => {
    if (open === true) {
      setOpenedAccordionTypes((collapsed) => union(collapsed, [type]));
    } else {
      setOpenedAccordionTypes((collapsed) => xor(collapsed, [type]));
    }
  }, [openedAccordionsTypes]);

  const exportAttestationFormTools = useCallback(() => {
    setIsExporting(true);
    AttestationFormToolService.exportAttestationFormTools(requestId)
      .then((response) => DocumentHelper.handleExcelFileDownload(response))
      .catch((exception) => { enqueueSnackbar(exception.message, { variant: 'error' }); })
      .finally(() => setIsExporting(false));
  }, [enqueueSnackbar]);

  const openDocumentAssignationModal = useCallback(() => openModal({
    type: 'DOCUMENT_ASSIGNATION',
    requestId,
    onSubmit: (form) => {
      AttestationFormToolService.assignDocumentToTools(form)
        .then(() => {
          enqueueSnackbar(translate('attestationFormRequest.tools.snackbar.documentAssigned'), { variant: 'success' });
          reloadListAndAlerts();
        })
        .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }));
    },
    closeOnSubmit: true
  }), [openModal, requestId, enqueueSnackbar, reloadListAndAlerts]);

  const allCollapsed = !openedAccordionsTypes.length;

  return (
    <>
      <GenericFilters
        dataTour="step-tools-filter"
        filterKey="InstitutionAttestationToolsStep"
        inputPlaceholder="attestationFormRequest.tools.filter"
        search={search}
        setSearch={setSearch}
      />
      {canEdit && (
        <Button
          margin="0 0 10px 0"
          onClick={openDocumentAssignationModal}
        >
          <Grid container direction="column">
            <Typography>{translate('attestationFormRequest.tools.addProofToTools')}</Typography>
            <Typography fontSize="1rem">{translate('attestationFormRequest.tools.subtitleAddProofToTools')}</Typography>
          </Grid>
        </Button>
      )}
      <Grid container justifyContent="space-between" style={{ margin: '0 0 16px 0' }}>
        <Grid item sm={3} xs={12}>
          <Button
            startIcon={<FontAwesomeIcon icon={allCollapsed ? faChevronRight : faChevronUp} />}
            variant="text"
            onClick={toggleEquipmentTypes}
          >
            {translate(allCollapsed
              ? 'attestationFormRequest.tools.actions.openAll'
              : 'attestationFormRequest.tools.actions.closeAll')}
          </Button>
        </Grid>
        <Grid item sm={9} xs={12}>
          <Grid container justifyContent="flex-end" spacing={2}>
            {canEdit
            && (
              <>
                <Grid item>
                  <Tooltip placement="left" title={translate('attestationFormRequest.tools.importToolsTooltip')}>
                    <div>
                      <Button
                        color="primary"
                        disabled={isImporting}
                        startIcon={isImporting ? <ButtonSpinner /> : <FontAwesomeIcon icon={ICONS.IMPORT.icon} />}
                        onClick={importTools}
                      >
                        {translate('attestationFormRequest.tools.importTools')}
                      </Button>
                    </div>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Button
                    startIcon={<FontAwesomeIcon icon={iconEnum.download.icon} />}
                    type="secondary"
                    onClick={() => DocumentService.getImportExampleFile('attestation-form-tool')}
                  >
                    {translate('common.exampleFile')}
                  </Button>
                </Grid>
              </>
            )}
            <Grid item>
              <Button
                color="primary"
                disabled={isExporting}
                startIcon={isExporting ? <ButtonSpinner /> : <FontAwesomeIcon icon={ICONS.EXPORT.icon} />}
                onClick={exportAttestationFormTools}
              >
                {translate('attestationFormRequest.tools.exportTools')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <SubtitlePage>{translate('attestationFormRequest.tools.mandatoryTools')}</SubtitlePage>
          {toolTypes?.mandatoryTypes?.map((equipmentType) => (
            <BlockTool
              alertedTools={alertedTools}
              availableActions={availableActions}
              editTool={(equipment, reload) => addTools(equipmentType, reload, equipment)}
              equipmentType={equipmentType.value}
              getToolsWithHistory={getToolsWithHistory}
              key={equipmentType.value + refreshToolList[equipmentType.value]}
              openIds={openedAccordionsTypes}
              requestId={requestId}
              searchText={search}
              showHistory={showHistory}
              title={equipmentType.label}
              toggleEquipment={(toggle) => onToggleEquipmentType(equipmentType.value, toggle)}
              updateAlerts={updateAlerts}
              updateUnreadDocumentAlerts={updateUnreadDocumentAlerts}
              onAddTools={(reload) => addTools(equipmentType, reload, null)}
            />
          ))}
          {toolTypes.optionalTypes?.length > 0 && (
            <>
              <br />
              <SubtitlePage>{translate('attestationFormRequest.tools.optionalTools')}</SubtitlePage>
              {toolTypes?.optionalTypes?.map((equipmentType) => (
                <BlockTool
                  alertedTools={alertedTools}
                  availableActions={availableActions}
                  editTool={(equipment, reload) => addTools(equipmentType, reload, equipment)}
                  equipmentType={equipmentType.value}
                  getToolsWithHistory={getToolsWithHistory}
                  key={equipmentType.value}
                  openIds={openedAccordionsTypes}
                  optional
                  requestId={requestId}
                  searchText={search}
                  showHistory={showHistory}
                  title={equipmentType.label}
                  toggleEquipment={(toggle) => onToggleEquipmentType(equipmentType.value, toggle)}
                  updateAlerts={updateAlerts}
                  updateUnreadDocumentAlerts={updateUnreadDocumentAlerts}
                  onAddTools={(reload) => addTools(equipmentType, reload, null)}
                />
              ))}
            </>
          )}
        </>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DocumentsTable
            canEdit={canEdit}
            canValidate={availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_DOCUMENT_VALIDATION)}
            folders={unclassifiedDocuments}
            isLoading={unclassifiedDocumentsLoading}
            requestId={requestId}
            section={SectionEnum.Tooling}
            styles={{ marginTop: '3rem' }}
            onUpdate={() => setRefreshDocumentList((value) => value + 1)}
            onUpdateCurrentSection={() => setRefreshToolList((reloadIndexes) => reloadIndexes + 1)}
          />
        </Grid>
      </Grid>
    </>
  );
};

ToolsStep.propTypes = {
  requestId: PropTypes.string.isRequired
};