import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableCell } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React, { useCallback } from 'react';
import { ICONS } from 'utils/icons';

const RowBox = styled(TableCell)(({ bgcolor, maincolor }) => (
  {
    backgroundColor: bgcolor,
    border: '1px solid',
    borderColor: maincolor
  }));

export const RowPrefix = ({ data, alertedDataIds }) => {
  const theme = useTheme();

  const getIconsAndColorsForData = useCallback(() => {
    if (data.deleted === true) {
      return {
        bgColor: theme.palette.grey.lighter,
        mainColor: theme.palette.grey.light
      };
    }
    if (data.activated === false) {
      return {
        bgColor: theme.palette.grey.light,
        mainColor: theme.palette.grey.dark,
        icon: ICONS.TOGGLE.OFF.icon
      };
    }
    const alerted = alertedDataIds.includes(data.id);
    const bgColor = alerted ? theme.palette.error.lighter : theme.palette.success.lighter;
    const mainColor = alerted ? theme.palette.error.dark : theme.palette.success.main;
    const icon = alerted ? ICONS.BOOLEAN.FALSE.icon : ICONS.BOOLEAN.TRUE.icon;
    return ({ bgColor, mainColor, icon });
  }, [alertedDataIds, data, alertedDataIds]);

  const { icon, bgColor, mainColor } = getIconsAndColorsForData(data, alertedDataIds);

  return (
    <RowBox
      activated={(!data.activated === false).toString()}
      align="center"
      bgcolor={bgColor}
      key={`${data.id}_state`}
      maincolor={mainColor}
      padding="none"
    >
      {icon && <FontAwesomeIcon color={mainColor} data-close fixedWidth icon={icon} />}
    </RowBox>
  );
};