import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

/**
 * Base hook to wrap basic useEffect fetch (with no dependencies) and handle error popup
 * @param serviceRequest a Service function (GET, POST...) returning a json promise
 * @param initialState optional parameter to have an initial response
 * @param reloadOn an array of dependencies that reload the response on every change
 * @param displayError true to display the error in a snackbar, false otherwise
 * @returns {{isLoading: boolean, response, setResponse, error}}
 */
export function useFetch(serviceRequest, initialState = null, reloadOn = [], displayError = true) {
  const { enqueueSnackbar } = useSnackbar();
  const [response, setResponse] = useState(initialState);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    serviceRequest()
      .then((resp) => setResponse(resp))
      .catch((err) => {
        displayError && enqueueSnackbar(err.message || err, { variant: 'error' });
        setError(err);
      })
      .finally(() => setIsLoading(false));
  }, reloadOn);

  return {
    response,
    setResponse,
    error,
    isLoading,
    setIsLoading
  };
}

export function useFetchState(serviceRequest, initialState = null, reloadOn = [], displayError = true) {
  const { response, setResponse, error, isLoading } = useFetch(serviceRequest, initialState, reloadOn, displayError);
  return [ response, setResponse, isLoading, error ];
}