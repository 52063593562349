import * as React from 'react';
import { ToggleButtonGroupElement } from 'react-hook-form-mui';

import { FormControl, FormGroup } from '@mui/material';
import PropTypes from 'prop-types';

export const ToggleButtons = ({
  name, size, exclusive = true, options, enforceSelection, ...props
}) => (
  <FormControl component="fieldset">
    <FormGroup aria-label="position">
      <ToggleButtonGroupElement
        enforceAtLeastOneSelected={enforceSelection}
        exclusive={exclusive}
        name={name}
        options={options}
        size={size}
        {...props}
      />
    </FormGroup>
  </FormControl>
);

ToggleButtons.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  )
};

ToggleButtons.defaultProps = {
  options: []
};