import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';

import {
  Button, DialogActions, DialogContent, Grid, Typography
} from '@mui/material';
import { translate } from 'utils';
import { FormInputField } from 'components/_commons/Form/Inputs';
import { FormContainer, useForm } from 'react-hook-form-mui';

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.grey.lighter,
  padding: '10px',
  borderRadius: '2px'
}));

const CONFIRM_VARIANT = {
  PRIMARY: {
    className: '',
    color: 'primary'
  },
  WARNING: {
    className: 'warningModal',
    color: 'warning'
  }
};

export const ConfirmModal = ({
  onClose,
  onConfirm,
  content,
  title,
  showIcon,
  closeLabel = 'button.cancel',
  confirmLabel = 'button.save',
  variant = 'PRIMARY',
  withComment,
  commentRequired
}) => {
  const formContext = useForm();
  const { watch } = formContext;
  const onSubmit = useCallback(() => {
    if (onConfirm) { onConfirm(withComment && watch('comment')); }
    return onClose();
  }, [onConfirm, onClose, watch]);

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{ autoComplete: 'off', name: 'resolveAlertForm' }}
      onSuccess={onSubmit}
    >
      <ModalHeader className={CONFIRM_VARIANT[variant].className} onClose={onClose}>
        {showIcon && <FontAwesomeIcon icon={faCheckCircle} />}
        {translate(title)}
      </ModalHeader>

      <DialogContent style={{ width: '40vw', minHeight: '10vh', marginTop: '30px' }}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography>
              {content}
            </Typography>
          </Grid>
          {withComment && (
            <Grid item>
              <FormInputField
                fullWidth
                label={translate('common.comment')}
                name="comment"
                required={commentRequired}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <StyledDialogActions>
        <Button color={CONFIRM_VARIANT[variant].color} variant="outlined" onClick={onClose}>
          {translate(closeLabel)}
        </Button>
        <Button color={CONFIRM_VARIANT[variant].color} type="submit" variant="contained">
          {translate(confirmLabel)}
        </Button>
      </StyledDialogActions>
    </FormContainer>
  );
};

ConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  title: PropTypes.string
};

ConfirmModal.defaultProps = {
  content: null,
  onConfirm: null,
  title: 'common.actionSuccessful'
};