import React from 'react';
import { styled } from '@mui/material/styles';
import { Skeleton, Table } from '@mui/material';

const SkeletonContainer = styled('tr')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '2rem',
  borderTop: `1px solid ${theme.palette.grey.lighter}`,
  borderBottom: `1px solid ${theme.palette.grey.lighter}`,

  td: {
    width: '20%',
    height: '20px'
  },

  div: {
    marginRight: '5%'
  }
}));

export const SkeletonTableChild = () => (
  <>
    <SkeletonContainer className="MuiTableRow-root">
      <td><Skeleton variant="rect" /></td>
      <td><Skeleton variant="rect" /></td>
      <td><Skeleton variant="rect" /></td>
      <td><Skeleton variant="rect" /></td>
      <td><Skeleton variant="rect" /></td>
    </SkeletonContainer>
    <SkeletonContainer className="MuiTableRow-root">
      <td><Skeleton variant="rect" /></td>
      <td><Skeleton variant="rect" /></td>
      <td><Skeleton variant="rect" /></td>
      <td><Skeleton variant="rect" /></td>
      <td><Skeleton variant="rect" /></td>
    </SkeletonContainer>
  </>
);

export const SkeletonTable = () => (
  <Table data-testid="skeleton-table">
    <tbody>
      <SkeletonTableChild />
    </tbody>
  </Table>
);