import { faChevronRight, faChevronUp, faScrewdriverWrench } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid, IconButton, TableCell, Tooltip, Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { CardButtonAdd } from 'components/_commons/Button';
import { GenericTable } from 'components/_commons/GenericTable/GenericTable';
import { TableTextCell } from 'components/_commons/GenericTable/TableTextCell';
import { RowPrefix } from 'components/_commons/RowPrefix/RowPrefix';

import { Spinner } from 'components/_commons/Skeletons';
import { SectionEnum } from 'components/AttestationForm/attestationFormSection.enum';
import { DocumentCount } from 'components/AttestationForm/shared/DocumentCount';
import { useModal } from 'hooks';
import { compact, find } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { AttestationFormRequestService, AttestationFormToolService } from 'services';
import shortid from 'shortid';

import { DocumentHelper, FormHelper, translate } from 'utils';
import { ATTESTATION_FORM_ACTIONS, DISABLE_ELEMENT_STYLE } from 'utils/constants';
import { DateHelper } from 'utils/helpers';
import { iconEnum, ICONS } from 'utils/icons';

const ToggleButton = styled(IconButton)(({ theme, ...props }) => ({
  color: theme.palette.getContrastText(theme.palette.primary.main),
  borderRadius: '8px',
  width: '40px',
  height: '40px',
  backgroundColor: props.backgroundcolor ? props.backgroundcolor.main : theme.palette.primary.main,
  '&:hover': {
    backgroundColor: props.backgroundcolor ? props.backgroundcolor.dark : theme.palette.primary.dark
  }
}));

const Container = styled('div')(() => ({
  ' & > * ': {
    marginBottom: '16px'
  }
}));

const AccordionContainer = styled('div')(({ closed }) => ({
  display: closed ? 'block' : 'none',
  ' & > * ': {
    marginBottom: '16px'
  }
}));

const DeactivableTableCell = styled(TableCell)(({ theme, disabled }) => {
  if (disabled) {
    return DISABLE_ELEMENT_STYLE(theme);
  }
  return {};
});

export const BlockTool = observer(({
  onAddTools, equipmentType, editTool, requestId, title, openIds, searchText,
  alertedTools, toggleEquipment, updateAlerts,
  availableActions, showHistory, getToolsWithHistory, optional, updateUnreadDocumentAlerts
}) => {
  const theme = useTheme();
  const openModal = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [toolList, setToolList] = useState([]);
  const [loading, setLoading] = useState(false);
  const blockIsOpen = useMemo(() => openIds.includes(equipmentType), [openIds]);
  const canValidate = availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_DOCUMENT_VALIDATION);
  const canEdit = availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_FORM)
    || availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_FORM_TOOL_STEP);

  const updateToolRow = useCallback((toolId, field, value) => {
    setToolList(toolList?.map((opt) => (toolId === opt.id ? { ...opt, [field]: value } : opt)));
  }, [toolList, setToolList]);

  const deactivateTool = useCallback(({ toolId, deactivate }) => {
    setLoading(true);
    AttestationFormToolService.toggleActivation(toolId, deactivate)
      .then(() => {
        updateToolRow(toolId, 'activated', deactivate);
        updateAlerts(SectionEnum.Tooling);
      })
      .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }))
      .finally(() => setLoading(false));
  }, [enqueueSnackbar, setLoading, updateToolRow, updateAlerts]);

  const handleDeactivate = (row) => deactivateTool({ toolId: row.id, deactivate: !row.activated });

  const loadEquipment = () => {
    setLoading(true);
    AttestationFormRequestService.getToolsByType(requestId, equipmentType, searchText)
      .then((tools) => {
        setToolList(tools);
        if (tools.length > 0) {
          // Force toggle active here because otherwise it won't work
          toggleEquipment(true);
        }
      })
      .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadEquipment();
  }, [searchText]);

  const filterDocumentsByType = (type, documents) => (documents ?? []).filter((doc) => doc.type?.name === type);
  const getDocumentBy = (type, documents) => filterDocumentsByType(type, documents)?.length;
  const getDocumentToReviewBy = (type, documents) => (
    availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_EDIT_DOCUMENT_VALIDATION)
      ? filterDocumentsByType(type, documents).filter(DocumentHelper.notYetValidated).length
      : 0
  );
  const getInvalidDocumentBy = (type, documents) => (
    filterDocumentsByType(type, documents).filter(DocumentHelper.isInvalid).length
  );

  const showDocuments = useCallback((equipment, documentType) => {
    openModal({
      canValidate,
      canEdit,
      type: 'DOCUMENTS_VERIFICATION',
      isProof: true,
      onSubmit: ({ documents }) => {
        const request = {
          ...equipment,
          equipmentView: {
            ...equipment.equipmentView,
            documentList: {
              ...equipment.equipmentView?.documentList,
              documents: [
                ...documents
              ]
            }
          },
          documentTypesToUpdate: documentType ? [documentType] : null
        };
        AttestationFormToolService.update(request)
          .then(() => {
            if (canValidate) {
              updateUnreadDocumentAlerts();
            }
            enqueueSnackbar(canValidate ? translate('attestationFormRequest.validationModal.snackbar.saved')
              : translate('attestationFormRequest.verificationModal.snackbar.saved'), { variant: 'success' });
            loadEquipment();
            updateAlerts(SectionEnum.Tooling);
          })
          .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
      },
      closeOnSubmit: true,
      maxWidth: false,
      fullscreen: true,
      ignoreValidation: true,
      modalState: {
        documentTypes: [
          ...equipment.detentionDocumentTypes,
          ...equipment.verificationDocumentTypes,
          ...equipment.otherDocumentTypes
        ],
        documents: filterDocumentsByType(documentType, equipment.equipmentView?.documentList?.documents)
      }
    });
  }, [openModal]);

  const deleteEquipment = ({ id: equipmentId }) => {
    openModal({
      type: 'WARNING',
      onConfirm: () => {
        AttestationFormToolService.remove(equipmentId)
          .then(() => {
            setToolList(toolList?.filter((opt) => equipmentId !== opt.id));
            enqueueSnackbar(translate('attestationFormRequest.tools.snackbar.removed'), { variant: 'success' });
            updateAlerts(SectionEnum.Tooling);
          })
          .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
      }
    });
  };

  const renderDocumentCount = useCallback((proofTypes, tool) => {
    const documentCounts = proofTypes?.map((documentType) => {
      const documentTypeKey = documentType.value;
      const count = getDocumentBy(documentTypeKey, tool.equipmentView?.documentList?.documents);
      const toReviewCount = getDocumentToReviewBy(documentTypeKey, tool.equipmentView?.documentList?.documents);
      const invalidCount = getInvalidDocumentBy(documentTypeKey, tool.equipmentView?.documentList?.documents);
      return count > 0
          && (
            <DocumentCount
              color={tool.activated ? theme.palette.primary.main : theme.palette.primary.lighter}
              count={count}
              invalidCount={invalidCount}
              key={shortid.generate()}
              title={find(proofTypes, { value: documentTypeKey })?.label}
              toReviewCount={toReviewCount}
              onShowDocuments={() => showDocuments(tool, documentTypeKey)}
            />
          );
    });
    return documentCounts?.some((documentCount) => Boolean(documentCount)) ? documentCounts : null;
  }, []);

  const listHeaders = () => compact([
    {
      name: 'state',
      width: 15,
      style: { borderBottom: 'none' },
      template: (row) => <RowPrefix alertedDataIds={alertedTools} data={row} key={`${row.id}_state`} />
    },
    {
      name: 'change',
      label: 'attestationFormRequest.tools.table.change',
      hidden: !showHistory,
      template: (row) => (<TableTextCell disabled={!row.activated} key={`${row.id}_change`} value={row?.change?.label} />)
    },
    {
      name: 'name',
      labels: [
        'attestationFormRequest.tools.table.type',
        'attestationFormRequest.tools.table.brand',
        'attestationFormRequest.tools.table.model',
        'attestationFormRequest.tools.table.serialNumber',
        'attestationFormRequest.tools.table.parenthesisInternalIdentification'
      ],
      template: (row) => (
        <TableTextCell
          disabled={!row.activated}
          key={`${row.id}_name`}
          value={(
            <>
              <Typography>{row?.equipmentView?.type?.label}</Typography>
              <Typography>{row?.equipmentView?.brand}</Typography>
              <Typography>{row?.equipmentView?.model}</Typography>
              <Typography>{row?.equipmentView?.serialNumber}</Typography>
              <Typography>
                {
                  row?.equipmentView.internalIdentification
                    ? `(${row?.equipmentView?.internalIdentification})`
                    : ''
                }
              </Typography>
            </>
          )}
        />
      )
    },
    {
      name: 'acquisition',
      labels: [
        'attestationFormRequest.tools.table.acquisition',
        'attestationFormRequest.tools.table.newTool'
      ],
      hideForMobile: true,
      hideForTablet: true,
      template: (row) => (
        <TableTextCell
          disabled={!row.activated}
          key={`${row.id}_acquisition`}
          value={(
            <>
              <Typography>
                {DateHelper.formatDate(row.equipmentView?.acquisitionDate)}
              </Typography>
              <Typography>
                {!row.deleted && FormHelper.translateYesOrNo(row.equipmentView?.isNew)}
              </Typography>
            </>
          )}
        />
      )
    },
    {
      name: 'detention',
      label: 'attestationFormRequest.tools.table.detention',
      width: '20%',
      hideForMobile: true,
      template: (row) => (
        <DeactivableTableCell disabled={!row.activated} key={`${row.id}_detention`}>
          {renderDocumentCount(row.detentionDocumentTypes, row)
            ?? (row.deleted ? ' ' : <FontAwesomeIcon icon={ICONS.NONE.icon} />)}
        </DeactivableTableCell>
      )
    },
    {
      name: 'verification',
      label: 'attestationFormRequest.tools.table.verification',
      width: '20%',
      hideForMobile: true,
      template: (row) => (
        <TableTextCell
          disabled={!row.activated}
          key={`${row.id}_verification`}
          value={
            renderDocumentCount(row.verificationDocumentTypes, row)
            ?? (row.deleted ? ' ' : <FontAwesomeIcon icon={ICONS.NONE.icon} />)
          }
        />
      )
    },
    {
      name: 'others',
      label: 'attestationFormRequest.tools.table.otherDocuments',
      width: '20%',
      hideForMobile: true,
      template: (row) => (
        <TableTextCell
          disabled={!row.activated}
          key={`${row.id}_others`}
          value={
            renderDocumentCount(row.otherDocumentTypes, row)
                  ?? (row.deleted ? ' ' : <FontAwesomeIcon icon={ICONS.NONE.icon} />)
          }
        />
      )
    }, canEdit
    && ({
      name: 'actions',
      label: 'attestationFormRequest.operators.table.actions',
      width: '155px',
      template: (row) => (row.deleted
        ? <TableTextCell disabled={row.deleted} key={`${row.id}_actions`} value={' '} />
        : (
          <TableCell className="spacing-right" key={`${row.id}_actions`}>
            <Tooltip title={translate('attestationFormRequest.tools.actions.edit')}>
              <IconButton
                color="primary"
                edge="end"
                onClick={(e) => { e.stopPropagation(); editTool(row, loadEquipment); }}
              >
                <FontAwesomeIcon icon={iconEnum.pen.icon} size="sm" swapOpacity />
              </IconButton>
            </Tooltip>
            <Tooltip title={row.activated
              ? translate('attestationFormRequest.tools.disable')
              : translate('attestationFormRequest.tools.enable')}
            >
              <IconButton
                color="primary"
                edge="end"
                onClick={(e) => { e.stopPropagation(); handleDeactivate(row); }}
              >
                <FontAwesomeIcon icon={row.activated ? ICONS.TOGGLE.ON.icon : ICONS.TOGGLE.OFF.icon} size="sm" />
              </IconButton>
            </Tooltip>
            <Tooltip title={translate('attestationFormRequest.tools.actions.remove')}>
              <IconButton
                color="primary"
                onClick={(e) => { e.stopPropagation(); deleteEquipment(row); }}
              >
                <FontAwesomeIcon
                  color={iconEnum.trashAlt.defaultColor}
                  icon={iconEnum.trashAlt.icon}
                  size="sm"
                  swapOpacity
                />
              </IconButton>
            </Tooltip>
          </TableCell>
        )
      )
    })
  ]);

  return (
    <Container>
      <Grid alignItems="center" container spacing={2}>
        <Grid item>
          <ToggleButton backgroundcolor={optional ? theme.palette.grey : null} variant="contained" onClick={toggleEquipment}>
            <FontAwesomeIcon icon={blockIsOpen ? faChevronUp : faChevronRight} />
          </ToggleButton>
        </Grid>
        <Grid item>
          <Typography
            color={toolList?.length ? theme.palette.common.black : theme.palette.grey.main}
            variant="h5"
          >
            {`${title} ${optional ? translate('attestationFormRequest.tools.optional') : ''} (${toolList?.length})`}
          </Typography>
        </Grid>
      </Grid>
      <AccordionContainer closed={blockIsOpen}>
        {canEdit
          && (
            <CardButtonAdd
              icon={faScrewdriverWrench}
              label={title?.toUpperCase()}
              variant="outlined"
              onClick={() => onAddTools(loadEquipment)}
            />
          )}
        {loading && <Spinner />}
        {
          (!!toolList?.length || !canEdit) && (
            <GenericTable
              displayTotal={false}
              headers={listHeaders()}
              isLoading={false}
              rows={(showHistory ? getToolsWithHistory(toolList, equipmentType) : toolList) ?? []}
              style={{ padding: '0 0 10px 0' }}
            />
          )
        }
      </AccordionContainer>
    </Container>
  );
});