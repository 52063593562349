import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { translate } from 'utils';
import { iconEnum } from 'utils/icons';

export const PageSubtitle = ({ text, styles }) => (
  <Typography
    align="center"
    style={{ margin: '1rem 0', ...styles }}
    variant="h3"
  >
    {translate(text)}
  </Typography>
);

PageSubtitle.propTypes = {
  text: PropTypes.string.isRequired
};

export const PageTitle = ({
  title, text, icon, iconColor, iconColorVariant, iconTooltip, withMargin, button, align, backButtonRoute
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Typography
      align={align}
      style={{ margin: withMargin ? '3rem 0' : 0 }}
      variant="h2"
    >
      {backButtonRoute
        && (
          <Tooltip title={translate('button.back')}>
            <IconButton style={{ marginRight: 5 }} onClick={() => navigate(backButtonRoute)}>
              <FontAwesomeIcon color={iconEnum.back.defaultColor} icon={iconEnum.back.icon} size="sm" />
            </IconButton>
          </Tooltip>
        )}

      {(title && translate(title)) || text}

      {icon && (
        <span className="ml1">
          <Tooltip aria-label={translate(iconTooltip)} title={translate(iconTooltip)}>
            <IconButton>
              <FontAwesomeIcon color={theme.palette[iconColor][iconColorVariant]} icon={icon} />
            </IconButton>
          </Tooltip>
        </span>
      )}

      {button && (
        <span className="ml1">
          {button}
        </span>
      )}
    </Typography>
  );
};

PageTitle.propTypes = {
  align: PropTypes.string,
  button: PropTypes.element,
  icon: PropTypes.shape({}),
  backButtonRoute: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconColor: PropTypes.string,
  iconColorVariant: PropTypes.string,
  iconTooltip: PropTypes.string,
  withMargin: PropTypes.bool
};

PageTitle.defaultProps = {
  align: 'left',
  backButtonRoute: -1,
  button: null,
  icon: null,
  iconColor: 'success',
  iconColorVariant: 'main',
  iconTooltip: '',
  withMargin: true
};