import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4'; // DOCS: https://www.npmjs.com/package/react-ga4
import { APP_VERSION, GA4_TAG_ID } from 'utils/constants';
import { CookiesHelper } from 'utils/helpers/CookiesHelper';
import { useModal } from 'hooks';

const GoogleAnalytics = () => {
  const showModal = useModal();
  const location = useLocation();
  const [gaCookiesActivated, setGaCookiesActivated] = useState(false);

  useEffect(() => {
    if (CookiesHelper.getGAConsentCookie()) {
      return;
    }

    showModal({
      type: 'COOKIES_MODAL'
    });
  }, []);

  useEffect(() => {
    if (gaCookiesActivated) {
      ReactGA.initialize(GA4_TAG_ID);
    }
  }, [gaCookiesActivated]);

  useEffect(() => {
    if (!gaCookiesActivated) {
      return;
    }

    // Transform current url from ie: '/stocks/stats' to 'stocks-stats'
    const urlTitle = location.pathname.split('/').join('-').substring(1);

    document.title = `Datafluides ${urlTitle}`;

    ReactGA.send({
      hitType: 'pageview',
      page: `${location.pathname}${location.search}`,
      title: `Datafluides ${urlTitle}`,
      appVersion: APP_VERSION
    });
  }, [location, gaCookiesActivated]);

  // Set an interval to check Google Analytics consent
  useEffect(() => {
    setInterval(() => {
      setGaCookiesActivated(CookiesHelper.getGAConsent());
    }, 1000 * 60);
  }, []);

  return null;
};

export default GoogleAnalytics;