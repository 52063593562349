import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import PropTypes from 'prop-types';
import { Button, IconButton, Tooltip } from '@mui/material';
import { translate } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { useTheme } from '@mui/material/styles';

const DropdownButton = ({
  label, icon, disabled, placement, children, buttonStyle,
  isIconOnly, isLoading, iconSize, color, tooltip, popperMargin,
  forceClose
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef();
  const theme = useTheme();

  const handleToggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const handleClose = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    if (forceClose) {
      setIsOpen(false);
    }
  }, [forceClose]);

  const button = (isIconOnly)
    ? (
      <span ref={anchorRef}>
        <IconButton
          color={color}
          disabled={disabled || isLoading}
          style={buttonStyle}
          onClick={handleToggle}
        >
          <FontAwesomeIcon fixedWidth icon={isLoading ? faSpinner : icon} size={iconSize} spin={isLoading} />
        </IconButton>
      </span>
    )
    : (
      <Button
        color={color}
        disabled={disabled || isLoading}
        icon={faAngleDown}
        ref={anchorRef}
        startIcon={icon && <FontAwesomeIcon icon={isLoading ? faSpinner : icon} spin={isLoading} />}
        style={buttonStyle}
        variant="contained"
        onClick={handleToggle}
      >
        {translate(label)}
      </Button>
    );

  return (
    <>
      {tooltip ? (
        <Tooltip title={tooltip}>
          <span>
            {button}
          </span>
        </Tooltip>
      ) : button }
      <Popper
        anchorEl={anchorRef.current}
        disablePortal
        open={isOpen}
        placement={placement}
        style={{ zIndex: theme.zIndex.small }}
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: 'top center' }}
          >
            <Paper style={{ padding: '1rem', margin: popperMargin }}>
              <ClickAwayListener onClickAway={handleClose}>
                {children}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

DropdownButton.propTypes = {
  buttonStyle: PropTypes.shape({}),
  color: PropTypes.string,
  disabled: PropTypes.bool,
  iconSize: PropTypes.string,
  isIconOnly: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  placement: PropTypes.string,
  popperMargin: PropTypes.string
};

DropdownButton.defaultProps = {
  buttonStyle: {},
  color: 'primary',
  disabled: false,
  iconSize: 'xs',
  isIconOnly: false,
  isLoading: false,
  label: '',
  placement: 'bottom-end',
  popperMargin: '0'

};

export default DropdownButton;