import React from 'react';
import { translate } from 'utils';
import { styled } from '@mui/material/styles';
import { Button, Grid } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { OperatorModalStepsEnum } from 'core/enums';

const StyledContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey.lighter,
  padding: '10px',
  borderRadius: '2px'
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 8,
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: theme.palette.primary.main,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.secondary.main
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 6,
    backgroundColor: theme.palette.primary.main
  }
}));

export const OperatorActionButtons = ({
  onClose, goBack, disabled, currentStep
}) => (
  <StyledContainer>
    <Grid alignItems="center" container justifyContent="space-between" spacing={1}>
      <Grid item xs={4}>
        <div className="display-flex">
          <Button color="secondary" variant="contained" onClick={() => onClose()}>
            {translate('button.cancel')}
          </Button>
          {currentStep === OperatorModalStepsEnum.OperatorDocument && (
            <div className="ml1">
              <Button className="ml1" color="primary" disabled={disabled} variant="contained" onClick={goBack}>
                {translate('button.previous')}
              </Button>
            </div>
          )}

        </div>
      </Grid>
      <Grid item xs={4}>
        <BorderLinearProgress value={currentStep === OperatorModalStepsEnum.AddOperator ? 50 : 100} variant="determinate" />
      </Grid>
      <Grid item xs={4}>
        <div className="flex-end">
          <Button color="primary" disabled={disabled} type="submit" variant="contained">
            {translate(currentStep === OperatorModalStepsEnum.OperatorDocument
              ? 'button.save'
              : 'attestationFormRequest.operators.modalAdd.addJustification')}
          </Button>
        </div>
      </Grid>
    </Grid>
  </StyledContainer>
);