import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { SelectField } from '..';

const SelectLanguage = ({
  value, onChange, required, isFetchingLanguages, languages
}) => {
  const handleChange = useCallback((language) => onChange(language), [onChange]);

  return isFetchingLanguages
    ? (
      <FontAwesomeIcon icon={faSpinner} spin />
    ) : (
      <SelectField
        isClearable={false}
        label="common.selectLanguage"
        name="language"
        options={languages}
        required={required}
        value={value}
        onChange={handleChange}
      />
    );
};

SelectLanguage.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({}))
  ]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool
};

SelectLanguage.defaultProps = {
  required: false,
  value: ''
};

export default SelectLanguage;