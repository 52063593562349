import { Grid, IconButton } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { addHours } from 'date-fns';
import { translate } from 'utils';

import { Document } from 'components/_commons/Document/Document';
import { CheckboxGroup } from 'components/_commons/Form/Inputs';
import FormDatePicker from 'components/_commons/Form/Inputs/Datepicker/FormDatePicker';
import { SingleSelect } from 'components/_commons/Modals/common/SingleSelect';
import { DOCUMENT_TYPE } from 'utils/constants';
import { DateHelper } from 'utils/helpers';

export const AddDocumentItem = ({
  docIndex, fieldName, attestationCategories, diplomaTypes, formContext, document, removeDocument, documentType, withoutDate
}) => {
  const [gridSize, setGridSize] = useState(2.5);
  const validateDocumentDate = useCallback(
    (date) => {
      const initialValidation = DateHelper.validateIfDateIsValidAndInThePast(date);
      if (initialValidation === true && documentType?.value === DOCUMENT_TYPE.INVOICE) {
        const acquisitionDate = formContext.getValues('equipmentView.acquisitionDate');
        const newAtAcquisition = formContext.getValues('equipmentView.wasNewAtAcquisition');
        if (acquisitionDate != null && newAtAcquisition && date < addHours(new Date(acquisitionDate), -3)) {
          return translate('error.equipmentDocument.invoiceBeforeAcquisition');
        }
      }
      return initialValidation;
    },
    [formContext, documentType]
  );

  useEffect(() => {
    if (withoutDate) {
      return setGridSize(11);
    }
    if (attestationCategories || diplomaTypes) {
      return setGridSize(2.5);
    }
    return setGridSize(5);
  }, [attestationCategories, diplomaTypes, withoutDate]);

  return (
    <Grid alignItems="center" container justifyContent="space-between" spacing={1}>
      <Grid item xs={gridSize}>
        <Document
          base64Content={document.base64Content}
          deletable={false}
          name={document.name}
        />
      </Grid>

      {!withoutDate && (
        <Grid item xs={(attestationCategories || diplomaTypes) ? 2.5 : 5}>
          <FormDatePicker
            disableFuture
            label="common.documentDate"
            name={`${fieldName}[${docIndex}].documentDate`}
            required
            validate={(value) => validateDocumentDate(value)}
            value={formContext.watch(`${fieldName}[${docIndex}].documentDate`)}
          />
        </Grid>
      )}

      {attestationCategories && (
        <Grid item xs={5}>
          <CheckboxGroup
            name={`${fieldName}[${docIndex}].attestationCategories`}
            options={attestationCategories}
            required
            row
          />
        </Grid>
      )}
      {diplomaTypes && (
        <Grid item xs={5}>
          <SingleSelect
            label={translate('attestationFormRequest.operators.categoryType')}
            name={`${fieldName}[${docIndex}].type`}
            options={diplomaTypes}
            required
          />
        </Grid>
      )}
      <Grid item xs={1}>
        <IconButton color="error" onClick={() => removeDocument()}>
          <FontAwesomeIcon icon={faTrashAlt} size="sm" swapOpacity />
        </IconButton>
      </Grid>
    </Grid>
  );
};