import { createTheme } from '@mui/material/styles';

export const DATAFLUIDES_THEME = createTheme({
  palette: {
    primary: {
      dark: '#01587E',
      main: '#027CB1',
      light: '#03B1FC',
      lighter: '#B3E7FE',
      lightest: '#F2FBFF',
      contrastText: '#fff'
    },
    secondary: {
      dark: '#fff',
      main: '#fff',
      light: '#fff',
      contrastText: '#027CB1'
    },
    error: {
      dark: '#B3003F',
      main: '#DC004E',
      light: '#FF1A6B',
      lighter: '#FFA1C2',
      lightest: '#FFF2F6',
      contrastText: '#fff'
    },
    warning: {
      dark: '#A8410A',
      main: '#D8530D',
      light: '#F26D27',
      lighter: '#FDE5D9',
      lightest: '#FEF6F2',
      contrastText: '#fff'
    },
    success: {
      dark: '#265A28',
      main: '#38853C',
      light: '#5EBB62',
      lighter: '#E3F3E4',
      lightest: '#F6FBF6',
      contrastText: '#fff'
    },
    grey: {
      darker: '#404040',
      dark: '#737373',
      main: '#a6a6a6',
      light: '#d9d9d9',
      lighter: '#ececec'
    },
    decorativeDataC2E: {
      dark: '#00743A',
      main: '#009149',
      light: '#33A76D',
      lighter: '#80C8A4',
      lightest: '#E6F4ED',
      contrastText: '#fff'
    },
    disabled: {
      main: '#DEDEDE',
      contrastText: '#5E5E5E',
      light: '#7e7e7e'
    },
    background: {
      main: '#FFFFFF',
      contrastText: '#444444'
    }
  },
  typography: {
    htmlFontSize: 10,
    h1: {
      fontSize: '3.6rem'
    },
    h2: {
      fontSize: '3.2rem'
    },
    h3: {
      fontSize: '2.4rem'
    },
    h4: {
      fontSize: '2.2rem'
    },
    h5: {
      fontSize: '2rem'
    },
    h6: {
      fontSize: '1.8rem'
    }
  },
  props: {
    MuiButton: {
      variant: 'contained'
    },
    MuiCard: {
      elevation: 2
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 913, // Dimensions Surface 7 (913 * 1368)
      lg: 1368,
      xl: 1536
    }
  },
  zIndex: {
    small: 10,
    medium: 50,
    big: 100,
    large: 1000,
    xLarge: 10000
  },
  sizes: {
    wrapperWidthMax: '1200px',
    wrapperWidthMin: '340px',
    smallWrapperWidthMax: '1200px',
    largeWrapperWidthMax: '1400px',
    tabletWidth: '768px',
    headerHeight: 60,
    headerNavHeight: 120,
    headerItemWidth: '220px',
    rowHeight: '55px'
  }
});