import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

const getInstitutionGroup = (institutionGroupId) => RequestHelper.GET(
  API_ROUTES.INSTITUTION_GROUP.ADMINISTRATION(institutionGroupId)
);
const deleteInstitutionGroup = (institutionGroupId) => RequestHelper.DELETE(
  API_ROUTES.INSTITUTION_GROUP.ADMINISTRATION(institutionGroupId)
);
const createInstitutionGroup = (institutionGroup, activity) => RequestHelper.POST(
  API_ROUTES.INSTITUTION_GROUP.SAVE(activity),
  institutionGroup
);
const updateInstitutionGroup = (institutionGroupId, institutionGroup, activity) => RequestHelper.PUT(
  API_ROUTES.INSTITUTION_GROUP.UPDATE(institutionGroupId, activity),
  institutionGroup
);

export const InstitutionGroupService = {
  getInstitutionGroup,
  createInstitutionGroup,
  deleteInstitutionGroup,
  updateInstitutionGroup
};