import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Grid, Fab as MuiFab, Stack, Switch, Tooltip, Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { BackToAttestationFormIcon } from 'components/_commons/Icon/BackToAttestationFormIcon';
import { useAccessRights } from 'hooks';
import React from 'react';
import { Action, Fab } from 'react-tiny-fab';
import shortid from 'shortid';
import { isMobile, translate } from 'utils';
import { APPLICATION_MANAGEMENT_ROLES, ATTESTATION_FORM_ACTIONS } from 'utils/constants';
import { iconEnum } from 'utils/icons';

const StyledContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.grey.lighter,
  padding: '10px',
  borderRadius: '2px'
}));

const StyledFormActions = styled('div')(({ theme }) => ({
  position: 'fixed',
  zIndex: theme.zIndex.large,
  bottom: 0,
  left: 0,
  right: 0
}));

export const AttestationFormActions = ({
  currentStep,
  numberOfSteps,
  handleNextStep,
  disabled,
  showHistory,
  isHome,
  setShowHistory,
  handlePreviousStep,
  handleQuit,
  handleAddAlert,
  availableActions,
  handleEditRequest,
  handleRequestUpdate,
  handleStartInstruction,
  handleStartSupervision,
  handleSeeHistory
}) => {
  const theme = useTheme();
  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === (numberOfSteps - 1);
  const isBackOfficeUser = useAccessRights({ roles: APPLICATION_MANAGEMENT_ROLES });
  const stepWithHistory = [2, 3];
  const renderFormAction = (label, action, icon) => (
    <Action
      key={shortid.generate()}
      style={{ backgroundColor: theme.palette.primary.contrastText }}
      text={<Typography>{label}</Typography>}
      onClick={action}
    >
      <FontAwesomeIcon color={theme.palette.secondary.contrastText} icon={icon} size="xl" />
    </Action>
  );

  const formActions = [];
  if (availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_CREATE_ALERT) && !isLastStep) {
    formActions.push(renderFormAction(
      translate('attestationFormRequest.alerts.buttonCreate'),
      handleAddAlert,
      iconEnum.exclamationTriangle.icon
    ));
  }
  if (availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_START_INSTRUCTION)) {
    formActions.push(renderFormAction(
      translate('attestationFormRequest.actions.startInstruction'),
      handleStartInstruction,
      iconEnum.pen.icon
    ));
  }
  if (availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_START_SUPERVISION)) {
    formActions.push(renderFormAction(
      translate('attestationFormRequest.actions.startSupervision'),
      handleStartSupervision,
      iconEnum.pen.icon
    ));
  }
  if (availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_MODIFY_REQUEST_OPERATOR)) {
    formActions.push(renderFormAction(
      translate('attestationFormRequest.actions.editSubmittedRequest'),
      handleEditRequest,
      iconEnum.pen.icon
    ));
  }
  if (availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_CREATE_UPDATE_REQUEST)) {
    formActions.push(renderFormAction(
      translate('attestationFormRequest.actions.requestUpdate'),
      handleRequestUpdate,
      iconEnum.pen.icon
    ));
  }
  if (isBackOfficeUser) {
    formActions.push(renderFormAction(
      translate('attestationFormRequest.actions.seeHistory'),
      handleSeeHistory,
      iconEnum.calendarClock.icon
    ));
  }
  formActions.push(renderFormAction(
    translate('attestationFormRequest.actions.seeGuide'),
    () => window.open(
      'https://dataform.cemafroid.fr/sequences/8e2346f2-4869-45b2-8feb-430bdae82e8c',
      '_blank',
      'noopener,noreferrer'
    ),
    iconEnum.questionMark.icon
  ));

  return (
    <>
      {Boolean(formActions.length) && (
        <Fab
          alwaysShowTitle
          event="click"
          icon={<FontAwesomeIcon icon={iconEnum.toolbox.icon} size="xl" />}
          mainButtonStyles={{ backgroundColor: theme.palette.primary.main }}
          style={{
            bottom: 48,
            right: 0,
            zIndex: theme.zIndex.small
          }}
        >
          {formActions}
        </Fab>
      )}
      {!isHome && (
        <>
          <Tooltip placement="right" title={translate('button.returnToForm')}>
            <MuiFab
              aria-label="return"
              color="secondary"
              style={{
                position: 'fixed',
                bottom: 73,
                left: 25,
                zIndex: theme.zIndex.small
              }}
              onClick={() => handleQuit()}
            >
              <BackToAttestationFormIcon />
            </MuiFab>
          </Tooltip>

          <StyledFormActions id="form-navbar">
            <StyledContainer alignItems="center" container justifyContent="space-between">
              <Button
                disabled={disabled || isFirstStep}
                style={{ visibility: isFirstStep ? 'hidden' : 'visible' }}
                variant="contained"
                onClick={handlePreviousStep}
              >
                {translate('button.previous')}
              </Button>
              {stepWithHistory.includes(currentStep) && setShowHistory && (
                <Stack alignItems="center" direction="row" spacing={1}>
                  {!isMobile() && <Typography>{translate('attestationFormRequest.actions.hideChanges')}</Typography>}
                  <Switch checked={showHistory} onChange={(event) => setShowHistory(event.target.checked)} />
                  {!isMobile() && <Typography>{translate('attestationFormRequest.actions.seeChanges')}</Typography>}
                </Stack>
              )}
              {isLastStep
                ? <Button disabled hidden />
                : (
                  <Button disabled={disabled} variant="contained" onClick={handleNextStep}>
                    {translate('button.next')}
                  </Button>
                )}
            </StyledContainer>
          </StyledFormActions>
        </>
      )}
    </>
  );
};