import React from 'react';
import { Divider, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { translate } from 'utils';
import { Link } from 'react-router-dom';
import { ASSISTANCE_EMAIL, ROUTES } from 'utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { Wrapper } from 'components/_commons/Wrapper';
import { Mobile, Tablet } from 'components/_commons/MediaQueries';
import { faCookieBite } from '@fortawesome/pro-solid-svg-icons';

const StyledFooter = styled('footer')(() => ({
  marginTop: '40px',
  textAlign: 'center',

  '@media (max-width: 1081px)': {
    paddingBottom: '40px'
  }
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  display: 'inline-block',
  width: '2px',
  backgroundColor: `${theme.palette.grey.dark} !important`,
  height: '14px !important'
}));

const StyledLogo = styled('img')(() => ({
  maxWidth: '100%',

  '@media (min-width: 1080px)': {
    marginTop: '-2rem'
  }
}));

export const Footer = () => (
  <Wrapper>
    <StyledFooter>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid
            alignItems="center"
            container
            justifyContent="center"
            spacing={2}
            wrap="wrap"
          >
            <Grid item>
              <Link to={ROUTES.RGPD}>
                <FontAwesomeIcon className="mr1" icon={faBalanceScale} />
                {translate('common.personalData')}
              </Link>
            </Grid>

            <Grid item>
              <StyledDivider orientation="vertical" />
            </Grid>

            <Grid item>
              <Link to={ROUTES.COOKIES}>
                <FontAwesomeIcon className="mr1" icon={faCookieBite} />
                {translate('link.rgpd.cookies')}
              </Link>
            </Grid>

            <Grid item>
              <StyledDivider orientation="vertical" />
            </Grid>

            <Grid item>
              <a href={`mailto:${ASSISTANCE_EMAIL}`}>
                <FontAwesomeIcon className="mr1" icon={faEnvelope} />
                {`${translate('common.assistance')} (${ASSISTANCE_EMAIL})`}
              </a>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <img alt="TECNEA_GROUP" src={`${process.env.PUBLIC_URL}/assets/images/companies/TECNEA_GROUP.png`} />
        </Grid>

        <Grid item>
          <Tablet>
            <StyledLogo
              alt={translate('menu.logoAlt')}
              src={`${process.env.PUBLIC_URL}/assets/images/bg/footer.png`}
              title={translate('menu.logoAlt')}
            />
          </Tablet>
          <Mobile>
            <StyledLogo
              alt={translate('menu.logoAlt')}
              src={`${process.env.PUBLIC_URL}/assets/images/bg/footer-mobile.png`}
              title={translate('menu.logoAlt')}
            />
          </Mobile>
        </Grid>
      </Grid>
    </StyledFooter>
  </Wrapper>
);