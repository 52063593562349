import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, FormLabel } from '@mui/material';

export const FormLine = styled('article')(() => ({
  display: 'flex',
  alignItems: 'center',

  '& > div': {
    flex: '1',

    '&:not(:last-of-type)': {
      marginRight: '1rem'
    }
  }
}));

const StyledFormElement = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  margin: '0.5rem 0',

  '.MuiFormControl-root': {
    display: 'flex',
    flex: 1,
    marginBottom: '1rem',

    '&:last-child': {
      marginBottom: 0
    },

    '&.MuiTextField-root': {
      flex: 1
    },

    legend: {
      position: 'absolute',
      top: '-5px',
      left: '10px',
      padding: '0 0.5rem',
      marginBottom: '0.5rem',
      color: theme.palette.grey.main,
      fontSize: '1.2rem',
      background: 'white',
      zIndex: 10
    }
  }
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  maxWidth: '90%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden'
}));

export const FormElement = ({
  label, icon, required, helperText, error, children
}) => (
  <StyledFormElement>
    <FormControl component="fieldset" error={error} required={required}>
      {label && (
        <StyledFormLabel component="legend">
          {label}
          {icon}
        </StyledFormLabel>
      )}
      {children}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  </StyledFormElement>
);

FormElement.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  required: PropTypes.bool
};

FormElement.defaultProps = {
  error: null,
  helperText: '',
  icon: null,
  label: '',
  required: false
};