import { Avatar, Grid, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React from 'react';

import ItemOption from 'components/_commons/Form/ItemOption/ItemOption';

const StyledBanner = styled(Grid)(({ theme }) => ({
  padding: 10,
  height: 60,
  background: theme.palette.primary.lightest,
  alignContent: 'center'
}));

export const StyledInput = styled('div')(() => ({
  margin: '5px'
}));

export const BannerInfo = ({ avatar, title, description }) => {
  const theme = useTheme();
  return (
    <Grid alignItems="center" container direction="row" spacing={2}>
      {avatar && (
        <Grid item>
          <Avatar sx={{ bgcolor: theme.palette.primary.main }}>{avatar}</Avatar>
        </Grid>
      )}
      <Grid alignItems="baseline" container item spacing={1} xs>
        <Grid item>
          <Typography color={theme.palette.primary.main} variant={description ? 'h4' : 'h6'}>{title}</Typography>
        </Grid>
        {description && (
          <Grid item>
            <Typography color={theme.palette.common.black} variant="h9">
              {description}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export const BannerItemOption = ({
  avatar, title, description, innerProps
}) => title && (
  <ItemOption {...innerProps} isInsideSelect>
    <BannerInfo avatar={avatar} description={description} title={title} />
  </ItemOption>
);

export const Banner = ({ avatar, title, description }) => (
  <StyledBanner container direction="row">
    <BannerInfo avatar={avatar} description={description} title={title} />
  </StyledBanner>
);