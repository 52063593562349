import { Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Progress } from 'components/_commons/Progress/Progress';
import { DocumentAssignationModalStepsEnum } from 'core/enums/steps/document-assignation-modal-steps.enum';
import React from 'react';
import { translate } from 'utils';

const StyledContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey.lighter,
  padding: '10px',
  borderRadius: '2px'
}));

export const DocumentAssignationModalActions = ({
  onClose,
  goBack,
  disabled,
  currentStep
}) => (
  <StyledContainer>
    <Grid alignItems="center" container justifyContent="space-between" spacing={1}>
      <Grid item xs={4}>
        <div className="display-flex">
          {DocumentAssignationModalStepsEnum.isFirst(currentStep)
            ? (
              <Button color="secondary" variant="contained" onClick={onClose}>
                {translate('button.cancel')}
              </Button>
            ) : (
              <div className="ml1">
                <Button className="ml1" color="primary" variant="contained" onClick={goBack}>
                  {translate('button.previous')}
                </Button>
              </div>
            )}
        </div>
      </Grid>
      <Grid item xs={4}>
        <Progress value={DocumentAssignationModalStepsEnum.Steps[currentStep].percent} variant="determinate" />
      </Grid>
      <Grid item xs={4}>
        <div className="flex-end">
          {
            DocumentAssignationModalStepsEnum.isLast(currentStep)
              ? (
                <Button
                  color="primary"
                  disabled={disabled}
                  type="submit"
                  variant="contained"
                >
                  {translate('button.save')}
                </Button>
              )
              : (
                <Button
                  color="primary"
                  disabled={disabled}
                  type="submit"
                  variant="contained"
                >
                  {translate('button.next')}
                </Button>
              )
          }
        </div>
      </Grid>
    </Grid>
  </StyledContainer>
);