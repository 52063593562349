import React, { useEffect, useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { find } from 'lodash';
import { DialogContent } from '@mui/material';

import { useFetch } from 'hooks';
import { translate } from 'utils';
import { ATTESTATION_FORM_STEPS } from 'utils/constants';

import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { DocumentActionButtons } from 'components/_commons/Modals/DocumentVerification/DocumentActionButtons';
import { ClassifyDocumentFormContainer } from 'components/_commons/Modals/ClassifyDocument/ClassifyDocumentFormContainer';
import { SectionEnum } from 'components/AttestationForm/attestationFormSection.enum';

import {
  AttestationFormOperatorService, AttestationFormRequestService, AttestationFormToolService, DocumentService
} from 'services';
import { enumOptionTypes, EnumService, enumValueTypes } from 'services/EnumService';

export const ClassifyDocumentModal = ({
  onClose,
  onSubmit,
  canValidate,
  modalState: {
    folder, requestId, section, canEdit = true
  }
}) => {
  const [categories, setCategories] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [options, setOptions] = useState([]);
  const [onChangeEntity, setOnChangeEntity] = useState(null);
  const [loadingDocumentTypes, setLoadingDocumentTypes] = useState(null);
  const { response: fileData, isLoading: loadingDocumentContent } = useFetch(
    () => DocumentService.getFileTransferModel(folder.documentId),
    null
  );

  const {
    response: allowedSections, isLoading: sectionsLoading
  } = useFetch(() => EnumService.getEnumOptions(enumOptionTypes.SECTION_DOCUMENT), []);

  const formContext = useForm({
    defaultValues: {
      document: folder.document,
      section: { value: section, label: translate(find(ATTESTATION_FORM_STEPS, { section })?.label) }
    }
  });

  const loadOperators = () => {
    setOptions([]);
    setLoadingOptions(true);
    AttestationFormRequestService.getOperators(requestId)
      .then((resp) => {
        setLoadingOptions(false);
        setOnChangeEntity(null);
        setOptions(resp.map((op) => ({ value: op.id, label: `${op.person?.firstName} ${op.person?.lastName}` })));
      });
  };

  const loadCategories = () => {
    EnumService.getEnumValues(enumValueTypes.ATTESTATION_CATEGORY)
      .then((list) => setCategories(list.map((opt) => ({ value: opt, label: opt }))));
  };

  const loadDocumentsOperatorTypes = () => {
    AttestationFormOperatorService.getDocumentsType()
      .then((list) => setDocumentTypes(list));
  };

  const loadSectionOperatorResources = () => {
    loadOperators();
    loadCategories();
    loadDocumentsOperatorTypes();
  };

  const loadSectionToolResources = () => {
    setOptions([]);
    setLoadingOptions(true);
    AttestationFormToolService.getAttestationFormTools(requestId).then((tools) => {
      if (formContext.watch('section')?.value === SectionEnum.Tooling) {
        setOptions(
          tools.map((tool) => ({
            value: tool.id,
            label: `${tool.equipmentView.fullName}`,
            type: tool.equipmentView.type.value
          }))
        );
        setOnChangeEntity(() => (e, entity) => {
          formContext.resetField('documentType');
          setLoadingDocumentTypes(true);
          AttestationFormToolService.getAvailableDocumentTypes(entity.type)
            .then((resp) => setDocumentTypes(resp))
            .finally(() => setLoadingDocumentTypes(false));
        });
      }
    }).finally(() => setLoadingOptions(false));
  };

  const loadSectionTracabilityResources = () => {
    setOptions([]);
    setLoadingOptions(true);
    AttestationFormRequestService.getTracabilityDocuments(requestId).then((resp) => {
      setLoadingOptions(false);
      setOnChangeEntity(null);
      setOptions(resp.map((element) => element.listType));
    });
  };

  useEffect(() => {
    switch (formContext.watch('section')?.value) {
    case SectionEnum.Operators:
      loadSectionOperatorResources();
      break;

    case SectionEnum.Tooling:
      loadSectionToolResources();
      break;

    case SectionEnum.Tracability:
      loadSectionTracabilityResources();
      break;

    default:
      break;
    }
    formContext.setValue('documentType', null);
  }, [formContext.watch('section')?.value]);

  return (
    <FormContainer formContext={formContext} FormProps={{ autoComplete: 'off', name: 'classifyDocuments' }} onSuccess={onSubmit}>
      <ModalHeader onClose={onClose}>{translate('attestationFormRequest.classifyDocumentsModal.title')}</ModalHeader>
      <DialogContent>
        <ClassifyDocumentFormContainer
          allowedSections={allowedSections}
          allowedSectionsLoading={sectionsLoading}
          canEdit={canEdit}
          canValidate={canValidate}
          categories={categories}
          documentIndex={0}
          documentsCount={1}
          documentTypes={documentTypes}
          fileData={fileData}
          formContext={formContext}
          loadingDocumentContent={loadingDocumentContent}
          loadingDocumentTypes={loadingDocumentTypes}
          loadingOptions={loadingOptions}
          options={options}
          section={section}
          onChangeLinkedEntity={onChangeEntity}
        />
      </DialogContent>
      <DocumentActionButtons canEdit={canEdit} documentNumber={1} documentsCount={1} goBack={() => {}} />
    </FormContainer>
  );
};