import React from 'react';
import { PropTypes } from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { translate } from 'utils';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useTheme } from '@mui/material/styles';

export const InfoTooltip = ({ label, size }) => {
  const theme = useTheme();

  return (
    <Tooltip aria-label={label} title={label}>
      <IconButton aria-label={label} size={size === 'xs' ? 'small' : 'medium'}>
        <FontAwesomeIcon color={theme.palette.primary.main} icon={faQuestionCircle} size={size} />
      </IconButton>
    </Tooltip>
  );
};

InfoTooltip.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string
};

InfoTooltip.defaultProps = {
  label: '',
  size: 'xs'
};

export const VisibilityTooltip = observer(({ isPublic, color = 'inherit' }) => {
  const { userStore } = useStores();
  const { isConnected } = userStore;

  const icon = isPublic ? faEye : faEyeSlash;
  const label = isPublic ? translate('common.isPublic') : translate('common.isPrivate');

  return (
    isConnected && (
      <Tooltip aria-label={label} title={label}>
        <IconButton aria-label={label} style={{ padding: 0 }}>
          <FontAwesomeIcon color={color} icon={icon} />
        </IconButton>
      </Tooltip>
    )
  );
});