import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, DialogActions } from '@mui/material';
import { translate } from 'utils';

export const CustomActionButtons = ({
  onCancel, onClose, disabled, cancelLabelKey, submitLabelKey
}) => {
  const handleClick = useCallback(() => {
    onCancel();
    onClose();
  }, [onCancel, onClose]);

  return (
    <DialogActions>
      <Button
        variant="text"
        onClick={handleClick}
      >
        {translate(cancelLabelKey)}
      </Button>

      <Button
        color="primary"
        disabled={disabled}
        type="submit"
      >
        {translate(submitLabelKey)}
      </Button>
    </DialogActions>
  );
};

CustomActionButtons.propTypes = {
  cancelLabelKey: PropTypes.string,
  disabled: PropTypes.bool,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  submitLabelKey: PropTypes.string
};

CustomActionButtons.defaultProps = {
  cancelLabelKey: 'button.cancel',
  disabled: false,
  onCancel: () => {},
  onClose: () => {},
  submitLabelKey: 'button.save'
};