import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

const getIdentifiersByCountryId = (countryId) => RequestHelper.GET(API_ROUTES.COUNTRY.IDENTIFIERS(countryId));
const getCountryById = (countryId) => RequestHelper.GET(API_ROUTES.COUNTRY.GET(countryId));
const getCountrySelectItems = () => RequestHelper.GET(API_ROUTES.COUNTRY.SELECT_ITEMS());

export const CountryService = {
  getIdentifiersByCountryId,
  getCountryById,
  getCountrySelectItems
};