import * as React from 'react';

import { translate } from 'utils';
import shortid from 'shortid';
import FormSelectField from 'components/_commons/Form/Inputs/SelectField/FormSelectField';
import ItemOption from 'components/_commons/Form/ItemOption/ItemOption';

export const SingleSelect = ({
  name, label, options, labelKey = 'label', isLoading, required, disabled = false, onChange
}) => (
  <FormSelectField
    disabled={disabled}
    fullWidth
    getOptionLabel={(option) => option[labelKey]}
    label={label}
    loading={isLoading}
    name={name}
    noOptionsText={translate('warnings.noOptionsAvailable')}
    options={options}
    placeholder={label}
    renderOption={(props, option) => (
      <ItemOption {...props} isInsideSelect key={shortid.generate()}>{option[labelKey]}</ItemOption>
    )}
    required={required}
    size="small"
    variant="outlined"
    onChange={onChange}
  />
);