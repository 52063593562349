import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material/styles';
import { CheckboxElement, FormContainer, useForm } from 'react-hook-form-mui';
import React, { useCallback } from 'react';
import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { CertificationProgramService } from 'services/CertificationProgramService';
import {
  Button, DialogContent, Grid, Typography
} from '@mui/material';
import { ModalFormField } from 'components/_commons/Modals/_ModalFormField';
import { CustomActionButtons } from 'components/_commons/Modals/_CustomActionButtons';
import { iconEnum } from 'utils/icons';
import { translate } from 'utils';

export const ValidateCertificationProgramModal = ({
  onClose,
  onSubmit
}) => {
  const theme = useTheme();
  const formContext = useForm({ defaultValues: { validated: false } });
  const { watch } = formContext;

  const onSubmitModal = useCallback((submittedFields) => {
    submittedFields.validated && onSubmit();
  }, [onSubmit]);

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{ autoComplete: 'off', name: 'validateCertificationForm' }}
      onSuccess={onSubmitModal}
    >
      <ModalHeader onClose={onClose}>
        {translate('certificationProgramModal.title')}
      </ModalHeader>

      <DialogContent style={{ width: '50vw', marginTop: '30px' }}>
        <ModalFormField title={translate('certificationProgramModal.title')}>
          <Grid alignItems="center" container direction="column" spacing={2}>
            <Grid item>
              <Typography noWrap>
                {translate('certificationProgramModal.body')}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                startIcon={<FontAwesomeIcon color={theme.palette.primary.contrastText} icon={iconEnum.download.icon} />}
                variant="contained"
                onClick={() => CertificationProgramService.getCertificationProgram()}
              >
                <Typography noWrap>
                  {translate('certificationProgramModal.download')}
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              <CheckboxElement label={translate('certificationProgramModal.checkbox.accept')} name="validated" required />
            </Grid>
          </Grid>
        </ModalFormField>
      </DialogContent>

      <CustomActionButtons
        disabled={!watch('validated')}
        submitLabelKey="attestationFormRequest.transmission.submit"
        onClose={onClose}
      />
    </FormContainer>
  );
};