import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { translate } from 'utils';
import { DialogContent } from '@mui/material';
import { ModalFormField } from 'components/_commons/Modals/_ModalFormField';
import { FormContainer, useForm } from 'react-hook-form-mui';
import React, { useCallback } from 'react';
import FormDatePicker from 'components/_commons/Form/Inputs/Datepicker/FormDatePicker';
import { CustomActionButtons } from 'components/_commons/Modals/_CustomActionButtons';
import { DateHelper } from 'utils/helpers';

export const EditFluidBalanceCampaignModal = ({
  onClose,
  onSubmit,
  reopen,
  defaultValues
}) => {
  const formContext = useForm({ defaultValues });
  const { watch } = formContext;

  const onSubmitModal = useCallback(() => onSubmit(DateHelper.getLocalDateTime(watch('endDate'))), [watch]);

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{ autoComplete: 'off', name: 'disableAttestationModal' }}
      onSuccess={onSubmitModal}
    >
      <ModalHeader onClose={onClose}>
        {reopen ? translate('fluidBalanceCampaignModal.reopen.title') : translate('fluidBalanceCampaignModal.edit.title')}
      </ModalHeader>

      <DialogContent style={{ marginTop: 30 }}>
        <ModalFormField title={translate('fluidBalanceCampaignModal.endDate.title')}>
          <FormDatePicker
            disablePast
            label="fluidBalanceCampaignModal.endDate.label"
            name="endDate"
            required
          />
        </ModalFormField>
      </DialogContent>

      <CustomActionButtons submitLabelKey="confirms.confirm" onClose={onClose} />
    </FormContainer>
  );
};