import { DocumentHelper, RequestHelper } from 'utils';
import { API_ROUTES } from 'utils/constants';

const getAuditors = () => RequestHelper.GET(API_ROUTES.AUDIT.AUDITORS());
const updateAudit = (audit, auditId) => RequestHelper.PUT(API_ROUTES.AUDIT.UPDATE(auditId), audit);
const getFilteredAudits = (filter, page, signal) => RequestHelper.POST(
  API_ROUTES.AUDIT.FILTER(page),
  filter,
  null,
  signal
);

const exportAudits = (filter) => RequestHelper.POST(API_ROUTES.AUDIT.EXPORT(), filter).then((response) => {
  DocumentHelper.handleExcelFileDownload(response);
});

const exportAuditsByMail = (filter, signal) => RequestHelper.POST(
  API_ROUTES.AUDIT.EXPORT_MAIL(),
  filter,
  null,
  signal
);

const deleteAudit = (auditId) => RequestHelper.DELETE(API_ROUTES.AUDIT.DELETE(auditId));

export const AuditService = {
  getAuditors,
  updateAudit,
  getFilteredAudits,
  deleteAudit,
  exportAudits,
  exportAuditsByMail
};