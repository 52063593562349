import { faScrewdriverWrench } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FormControl, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormInputField, ToggleButtons } from 'components/_commons/Form/Inputs';
import FormDatePicker from 'components/_commons/Form/Inputs/Datepicker/FormDatePicker';

import FormSelectField, { getOptionLabelWithInputValue } from 'components/_commons/Form/Inputs/SelectField/FormSelectField';
import ItemOption from 'components/_commons/Form/ItemOption/ItemOption';
import { BannerItemOption } from 'components/_commons/Modals/common/BannerItems';
import { SelectEntity, StyledInput } from 'components/_commons/Modals/common/SelectEntity';
import { Spinner } from 'components/_commons/Skeletons';
import debounce from 'debounce-promise';

import { SideDocumentBlock } from 'components/_commons/Modals/common/SideDocumentBlock';
import { useFetch } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { AttestationFormRequestService, DocumentService } from 'services';
import { ToolService } from 'services/ToolService';
import shortid from 'shortid';
import { FormHelper, translate } from 'utils';
import { MINIMAL_TOOL_TYPES, SPECIFIC_DOCUMENT_TYPES } from 'utils/constants';
import { DateHelper, Validators } from 'utils/helpers';

const Container = styled('div')(() => ({
  ' & > :not(:last-child)': {
    marginBottom: '1rem'
  }
}));

export const RegistrationStep = ({
  equipmentType, requestId, formContext, isEdition, equipment, specificDocumentTypes, specificDocumentTypesLoading
}) => {
  const { response: brands } = useFetch(() => ToolService.getBrands(), [], [equipmentType?.id]);
  const {
    clearErrors, setValue, watch, reset
  } = formContext;
  const { enqueueSnackbar } = useSnackbar();
  const [tool, setTool] = useState(equipment);
  const [toolsOptions, setToolsOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isNew, setIsNew] = useState(false);
  const [currentKey, setCurrentKey] = useState(shortid.generate());

  const newTool = {
    equipmentView: {
      identifier: translate('attestationFormRequest.tools.modal.newTool'),
      fullName: translate('attestationFormRequest.tools.modal.newTool'),
      model: ''
    },
    id: 'new-tool'
  };
  const [isNewTool, setIsNewTool] = useState(watch('selectedTool.id') === newTool.id);

  const getToolsPromise = useCallback((inputValue) => new Promise((resolve, reject) => {
    AttestationFormRequestService
      .getInstitutionTools(requestId, equipmentType.value, inputValue)
      .then((response) => resolve(response))
      .catch((error) => reject((error && error.message) || error));
  }), [requestId]);

  const debouncedLoadTools = debounce(getToolsPromise, 500);

  const fetchTools = (freeSearch) => {
    setIsLoading(true);
    debouncedLoadTools(freeSearch)
      .then((tools) => setToolsOptions(tools.map((equipmentView) => ({ equipmentView }))))
      .catch((error) => enqueueSnackbar(error, { variant: 'error' }))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchTools('');
  }, []);

  const onCreate = () => {
    clearErrors();
    reset({
      equipmentView: {
        brand: '',
        model: null,
        serialNumber: null,
        internalIdentification: null,
        acquisitionDate: null,
        wasNewAtAcquisition: null,
        comment: null,
        type: equipmentType
      }
    });
    setCurrentKey(shortid.generate());
    setValue('selectedTool', newTool);
    setIsNewTool(true);
    setTool(null);
  };

  const onSelected = (event, selectedTool) => {
    clearErrors();
    setIsNewTool(false);
    setTool(selectedTool ?? null);
    setValue('equipmentView', watch('selectedTool.equipmentView'));
    if (selectedTool?.equipmentView?.documentList?.documents?.length) {
      DocumentService.getDocumentsWithContent(selectedTool?.equipmentView?.documentList?.documents)
        .then((resp) => setValue(
          'equipmentView.documentList',
          {
            ...selectedTool?.equipmentView?.documentList,
            documents: resp,
            innerDocuments: resp.reduce((prev, curr) => ({
              ...prev,
              [curr.type?.name]: (prev[curr.type?.name]) ? [...prev[curr.type?.name], curr] : [curr]
            }), {})
          }
        ));
    }
  };

  const acquisitionDate = watch('equipmentView.acquisitionDate');
  const wasNewAtAcquisition = watch('equipmentView.wasNewAtAcquisition');

  useEffect(() => {
    setIsNew(Validators.isCurrentToolNew(
      wasNewAtAcquisition,
      acquisitionDate
    ));
  }, [acquisitionDate, wasNewAtAcquisition]);

  const getKey = useCallback(() => currentKey, [currentKey]);

  const fullNameWithInternalIdentification = useCallback((selectedTool) => {
    let name;
    if (selectedTool) {
      name = selectedTool.equipmentView?.fullName;
    } else if (tool?.equipmentView?.fullName) {
      const toolFullName = tool.equipmentView.fullName;
      const internalId = selectedTool?.equipmentView?.internalIdentification || '';
      name = `${toolFullName} ${internalId}`.trim();
    } else {
      name = translate('attestationFormRequest.tools.modal.newTool');
    }
    return name;
  }, [tool]);

  return (
    <SelectEntity
      buttonTitle="modalTool.createTool"
      createTooltip={translate('attestationFormRequest.tools.modal.newTool')}
      entityValue={{
        avatar: <FontAwesomeIcon icon={faScrewdriverWrench} />,
        description: equipmentType?.label,
        title: fullNameWithInternalIdentification(tool || watch('selectedTool'))
      }}
      getOptionLabel={(option) => fullNameWithInternalIdentification(option)}
      isEdition={isEdition}
      loading={isLoading}
      loadOptions={fetchTools}
      name="selectedTool"
      newEntityValue={{
        avatar: <FontAwesomeIcon icon={faScrewdriverWrench} />,
        description: equipmentType?.name,
        title: translate('attestationFormRequest.tools.modal.newTool')
      }}
      options={toolsOptions}
      renderSelectOption={(props, option) => (
        option.id !== 'new-tool' && (
          <BannerItemOption
            avatar={<FontAwesomeIcon icon={faScrewdriverWrench} />}
            innerProps={props}
            key={shortid.generate()}
            title={fullNameWithInternalIdentification(option)}
          />
        )
      )}
      required={!isNewTool}
      selectLabel={translate('attestationFormRequest.tools.modal.selectPlaceholder')}
      selectPlaceholder={translate('attestationFormRequest.tools.modal.selectPlaceholder')}
      title="attestationFormRequest.tools.modal.title.registration"
      value={tool ?? watch('selectedTool')}
      onChange={onSelected}
      onCreate={onCreate}
    >
      <Grid container direction="row" item spacing={4}>
        <Grid item lg={6} md={8} xs={10}>
          <Container>
            {!MINIMAL_TOOL_TYPES.includes(equipmentType.value) && (
              <>
                <StyledInput>
                  <FormSelectField
                    clearOnBlur
                    freeSolo
                    fullWidth
                    getOptionLabel={getOptionLabelWithInputValue}
                    key={getKey()}
                    label={translate('attestationFormRequest.tools.table.brand')}
                    name="equipmentView.brand"
                    noOptionsText={translate('warnings.noOptionsAvailable')}
                    options={brands}
                    renderOption={(props, option) => (
                      <ItemOption {...props} isInsideSelect key={shortid.generate()}>
                        {getOptionLabelWithInputValue(option)}
                      </ItemOption>
                    )}
                    required
                    size="small"
                    variant="outlined"
                  />
                </StyledInput>
                <StyledInput>
                  <FormInputField
                    fullWidth
                    label={translate('attestationFormRequest.tools.table.model')}
                    name="equipmentView.model"
                    required
                  />
                </StyledInput>
              </>
            )}
            <FormControl fullWidth variant="standard">
              {(equipmentType.value !== 'FLEXIBLE_COUPLINGS') && (
                <StyledInput>
                  <FormInputField
                    fullWidth
                    label={translate('attestationFormRequest.tools.table.serialNumber')}
                    name="equipmentView.serialNumber"
                    required={!watch('equipmentView')?.internalIdentification}
                  />
                </StyledInput>
              )}
              <StyledInput>
                <FormInputField
                  fullWidth
                  label={translate('attestationFormRequest.tools.table.internalIdentification')}
                  name="equipmentView.internalIdentification"
                  required={!watch('equipmentView')?.serialNumber}
                />
              </StyledInput>
              <StyledInput>
                <FormDatePicker
                  animateYearScrolling
                  disableFuture
                  fullWidth
                  label="attestationFormRequest.tools.table.acquisition"
                  name="equipmentView.acquisitionDate"
                  required
                  validate={(value) => DateHelper.validateIfDateIsValidAndInThePast(value)}
                  value={watch('equipmentView.acquisitionDate')}
                  variant="standard"
                />
              </StyledInput>
              {
                acquisitionDate && !DateHelper.isMoreThanOneYearInThePast(acquisitionDate) && (
                  <StyledInput>
                    <Typography variant="h5">{translate('attestationFormRequest.tools.modal.newToolQuestion')}</Typography>
                    <ToggleButtons
                      name="equipmentView.wasNewAtAcquisition"
                      options={FormHelper.yesNoOption()}
                      size="small"
                      style={{ marginBottom: '10px', marginTop: '5px' }}
                    />
                  </StyledInput>
                )
              }
              <StyledInput>
                <FormInputField
                  fullWidth
                  label={translate('attestationFormRequest.tools.table.comment')}
                  name="equipmentView.comment"
                />
              </StyledInput>
            </FormControl>
          </Container>
        </Grid>
        <Grid item lg={6} md={4}>
          {isNew && (
            <Grid container>
              {
                specificDocumentTypesLoading
                  ? <Spinner />
                  : specificDocumentTypes.map((docType, index) => (
                    <Grid item key={docType.name}>
                      <SideDocumentBlock
                        documentDefaultValues={{ documentDate: undefined, type: docType }}
                        fieldName={`equipmentView.documentList.innerDocuments.${docType.name}`}
                        formContext={formContext}
                        labelButton="Importer"
                        showDivider={index !== (specificDocumentTypes.length - 1)}
                        title={docType.label}
                        type="primary"
                        variant={index ? 'outlined' : 'contained'}
                        withoutDate={SPECIFIC_DOCUMENT_TYPES.includes(docType.name)}
                      />
                    </Grid>
                  ))
              }

            </Grid>
          )}
        </Grid>
      </Grid>
    </SelectEntity>
  );
};