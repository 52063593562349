import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import frLocale from 'date-fns/locale/fr';
import React, { useRef } from 'react';
import { DatePickerElement } from 'react-hook-form-mui';
import { translate } from 'utils';
import { DateHelper } from 'utils/helpers';

const FormDatePicker = ({
  disableFuture,
  disablePast,
  validate = (value) => DateHelper.validateIfDateIsValid(value),
  name,
  label,
  onChange,
  onError,
  variant,
  fullWidth,
  inputFormat = 'dd/MM/yyyy',
  required,
  ...props
}) => {
  const ref = useRef();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div ref={ref}>
      <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
        <DatePickerElement
          disableFuture={disableFuture}
          disablePast={disablePast}
          InputAdornmentProps={{ position: 'start' }}
          inputFormat={inputFormat}
          inputProps={{
            variant: variant || 'outlined',
            fullWidth,
            onClick: handleClick
          }}
          label={label && `${translate(label)}${required ? ' *' : ''}`}
          name={name}
          PopperProps={{ anchorEl, placement: 'top-start' }}
          required={required}
          validation={{
            required: (required ? translate('common.requiredField') : false),
            validate
          }}
          onChange={(date) => onChange && onChange(name, date)}
          onError={(error) => onError && onError(error)}
          {...props}
        />
      </LocalizationProvider>
    </div>
  );
};

export default FormDatePicker;