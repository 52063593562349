import { DocumentHelper, RequestHelper } from 'utils';
import { API_ROUTES } from 'utils/constants';

// GET

const getCertificationProgram = () => {
  RequestHelper.GET(API_ROUTES.CERTIFICATION.PROGRAM())
    .then((doc) => {
      DocumentHelper.downloadDocumentFromBase64(doc.base64Content, doc.name);
    });
};

export const CertificationProgramService = {
  getCertificationProgram
};