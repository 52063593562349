import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import shortid from 'shortid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarMinus, faCalendarPlus, faSearch, faSlidersH, faSpinner, faTimes, faTrashAlt, faXmark
} from '@fortawesome/pro-regular-svg-icons';
import {
  Button, Chip, Drawer, Grid, IconButton, InputAdornment, TextField
} from '@mui/material';
import {
  FilterHelper, isTablet, StorageHelper, translate
} from 'utils';
import { useDebounce } from 'utils/helpers';
import { InfoTooltip } from 'components/_commons/InfoTooltip';

const InputAndFilters = styled(Grid)(({ theme }) => ({
  padding: '1rem 0',
  marginBottom: '1rem',
  borderRadius: theme.shape.borderRadius,

  '.MuiTextField-root': {
    flex: 1
  },

  input: {
    '&:disabled': {
      cursor: 'not-allowed'
    }
  },

  '& > button': {
    '&:last-child': {
      marginLeft: '1rem'
    },

    '&:disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'all'
    }
  }
}));

const TagList = styled(Grid)(() => ({
  flex: 1,
  overflowX: 'auto',

  'div:not(:last-child), svg:not(.MuiSvgIcon-root)': {
    marginRight: '0.5rem'
  }
}));

const FilterDrawerMain = styled('main')(({ theme }) => ({
  width: '100%',
  maxWidth: '400px',
  minWidth: theme.sizes.wrapperWidthMin,
  flex: 1,
  margin: '2rem auto',
  padding: '0 2rem',

  h2: {
    justifyContent: 'flex-start'
  }
}));

const FilterDrawerButtons = styled(Grid)(() => ({
  padding: '2rem',

  '@media (min-width: 481px)': {
    'button:first-child': {
      marginRight: '2rem'
    }
  },

  '@media (max-width: 480px)': {
    flexDirection: 'column',

    'button:first-child': {
      marginBottom: '2rem'
    }
  }
}));

export const GenericFilters = ({
  ComponentFilter,
  dataTour,
  filters, setFilters,
  search, setSearch,
  setCurrentPage,
  filterKey,
  withDrawer,
  disabled, isVisible,
  inputPlaceholder,
  tooltip,
  placeholder,
  updateCount = 0
}) => {
  const theme = useTheme();
  const tabletView = isTablet();
  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(false);
  const [currentFilters, setCurrentFilters] = useState(filters);
  const [internalSearch, setInternalSearch] = useState(search);
  const [isSearching, setIsSearching] = useState(false);

  const debouncedSearch = useDebounce(internalSearch, 1000);

  useEffect(() => {
    setSearch(debouncedSearch);
    setIsSearching(false);
  }, [setSearch, debouncedSearch]);

  const handleInputChange = useCallback((input) => {
    setIsSearching(true);
    setInternalSearch(input.target.value);
  }, []);

  const saveFilters = useCallback(() => {
    FilterHelper.setFilters(filterKey, currentFilters);
  }, [filterKey, currentFilters]);

  useEffect(() => {
    setCurrentFilters(filters);
    saveFilters();
  }, [updateCount]);

  const removeFilters = useCallback(() => {
    setFilters([]);
    setCurrentFilters([]);
    StorageHelper.DELETE(`filters_${filterKey}`);
  }, [setFilters, filterKey]);

  const resetFilters = useCallback(() => {
    setFilters(filters);
    setCurrentFilters(filters);
    setIsFiltersDrawerOpen(false);
  }, [filters, setFilters]);

  const setNewFilters = useCallback(() => {
    setFilters(currentFilters);
    setIsFiltersDrawerOpen(false);
    setCurrentPage(1);
    saveFilters();
  }, [currentFilters, setFilters, saveFilters, setCurrentPage]);

  const handleDeleteFilter = useCallback((filter) => {
    const newFilters = filters.filter((f) => f.key !== filter.key);
    setFilters(newFilters);
    setCurrentFilters(newFilters);
    FilterHelper.setFilters(filterKey, newFilters);
  }, [filters, filterKey, setFilters]);

  const handleDeleteSearch = useCallback(() => setInternalSearch(''), []);

  const handleOpenDrawer = useCallback(() => setIsFiltersDrawerOpen(true), []);

  const handleCloseDrawer = useCallback(() => setIsFiltersDrawerOpen(false), []);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <aside data-tour={dataTour}>
        <InputAndFilters alignItems="center" container justifyContent="space-between">
          {tooltip && (
            <InfoTooltip label={translate(tooltip)} size="sm" />
          )}
          <TextField
            disabled={disabled}
            InputProps={{
              endAdornment: internalSearch && (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleDeleteSearch}>
                    <FontAwesomeIcon color={theme.palette.grey.main} icon={faTimes} size="xs" />
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  {isSearching
                    ? <FontAwesomeIcon icon={faSpinner} spin />
                    : <FontAwesomeIcon color={theme.palette.grey.main} icon={faSearch} />}
                </InputAdornment>
              )
            }}
            label={translate(inputPlaceholder ?? 'common.search')}
            name="search"
            placeholder={placeholder && translate(placeholder)}
            value={internalSearch}
            variant="outlined"
            onChange={handleInputChange}
          />
          {withDrawer && (
            <Button
              color="primary"
              data-cy="genericListFilters"
              disabled={disabled}
              startIcon={<FontAwesomeIcon icon={faSlidersH} />}
              variant="text"
              onClick={handleOpenDrawer}
            >
              {translate('button.filters')}
            </Button>
          )}
        </InputAndFilters>

        {filters && filters.length > 0 && (
          <Grid alignItems="center" container justifyContent="space-between" style={{ marginBottom: '2rem' }}>
            <TagList data-cy="genericListFiltersList">
              {filters.map((filter) => {
                if (!filter.label || (filter.label instanceof Array && filter.label.length === 0)) return false;

                let formattedFilterLabel;
                if (filter.label instanceof Array && filter.label.length > 0) {
                  formattedFilterLabel = filter.label.map((filt) => filt.label).join(', ');
                } else if (filter.label instanceof Date) {
                  formattedFilterLabel = (
                    <>
                      <FontAwesomeIcon icon={filter.key === 'startDate' ? faCalendarMinus : faCalendarPlus} />
                      {new Date(filter.label).toLocaleDateString()}
                    </>
                  );
                } else if (filter.value && filter.value.label) {
                  formattedFilterLabel = filter.value.label;
                } else {
                  formattedFilterLabel = translate(filter.label);
                }

                if (filter.translationKey) {
                  formattedFilterLabel = translate(`${filter.translationKey}.${formattedFilterLabel}`);
                }

                return (
                  <Chip
                    color="primary"
                    key={shortid.generate()}
                    label={formattedFilterLabel}
                    onDelete={() => handleDeleteFilter(filter)}
                  />
                );
              })}
            </TagList>
            {filters.length > 0 && (
              <Button
                startIcon={<FontAwesomeIcon icon={faTrashAlt} />}
                variant="text"
                onClick={removeFilters}
              >
                {translate('button.removeFilters')}
              </Button>
            )}
          </Grid>
        )}
      </aside>

      {withDrawer && (
        <Drawer
          anchor="right"
          id="filterDrawer"
          open={isFiltersDrawerOpen}
          PaperProps={{
            sx: {
              width: tabletView ? '50%' : 400
            }
          }}
          onClose={handleCloseDrawer}
        >
          <Grid container justifyContent="flex-end">
            <Button variant="text" onClick={resetFilters}>
              <FontAwesomeIcon color={theme.palette.primary.main} icon={faXmark} size="2x" />
            </Button>
          </Grid>
          <FilterDrawerMain>
            {ComponentFilter && ComponentFilter({ currentFilters, setCurrentFilters })}
          </FilterDrawerMain>
          <FilterDrawerButtons alignItems="center" container justifyContent="flex-end">
            <Button data-cy="genericListFilterCancel" variant="text" onClick={resetFilters}>
              {translate('button.cancel')}
            </Button>
            <Button color="primary" data-cy="genericListFilterValidate" onClick={setNewFilters}>
              {translate('button.validateFilters')}
            </Button>
          </FilterDrawerButtons>
        </Drawer>
      )}
    </>
  );
};

GenericFilters.propTypes = {
  ComponentFilter: PropTypes.func,
  dataTour: PropTypes.string,
  filterKey: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
      PropTypes.arrayOf(PropTypes.shape({}))
    ])
  })),
  isVisible: PropTypes.bool,
  search: PropTypes.string,
  setFilters: PropTypes.func,
  setSearch: PropTypes.func,
  setCurrentPage: PropTypes.func,
  tooltip: PropTypes.string,
  withDrawer: PropTypes.bool
};

GenericFilters.defaultProps = {
  ComponentFilter: null,
  dataTour: '',
  filterKey: 'generic_',
  filters: [],
  isVisible: true,
  search: '',
  setCurrentPage: () => {},
  setFilters: () => {},
  setSearch: () => {},
  tooltip: '',
  withDrawer: false
};