import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconButton } from 'components/_commons/Button';
import { TextButton } from 'components/_commons/Button/TextButton';
import { InvalidDocumentIcon } from 'components/_commons/Icon/InvalidDocumentIcon';
import { useModal } from 'hooks';
import React, { useCallback } from 'react';
import { translate } from 'utils';
import { iconEnum } from 'utils/icons';

export const Document = (({
  name, base64Content, deletable, handleDelete, tooltip, document, handleValidation,
  canValidate, unread, invalid, modalFullScreen = true, loadContent = false
}) => {
  const showModal = useModal();
  const theme = useTheme();

  const handleOpenPDFModal = useCallback(() => (
    canValidate
      ? showModal({
        onSubmit: (resp) => {
          handleValidation(document.id, resp.documents[0].validation);
        },
        canValidate,
        type: 'DOCUMENTS_VERIFICATION',
        closeOnSubmit: true,
        maxWidth: false,
        canEdit: canValidate,
        fullscreen: modalFullScreen,
        modalState: {
          documents: [{
            base64Content, name, id: document.documentId, validation: document.validation
          }]
        }
      }) : showModal({
        type: 'DISPLAY_DOCUMENT',
        backdropClick: true,
        fullscreen: modalFullScreen,
        loadContent,
        document: {
          name,
          documentId: document?.documentId,
          base64Content
        }
      })
  ), [showModal, handleValidation]);

  return (
    <Grid alignItems="center" container direction="row">
      <Grid item width="100%" xs={deletable ? 10 : 12}>
        <Grid alignItems="center" container direction="row" spacing={1}>
          <Grid item xs={((unread && canValidate) || invalid) ? 10 : 12}>
            <TextButton
              label={name}
              tooltip={tooltip ? translate(tooltip) : name}
              onClick={handleOpenPDFModal}
            />
          </Grid>
          {unread && canValidate
            && (
              <Grid item xs={2}>
                <Tooltip title={translate('attestationFormRequest.alerts.unread')}>
                  <Grid item>
                    <IconButton onClick={handleOpenPDFModal}>
                      <FontAwesomeIcon color={theme.palette.warning.main} icon={iconEnum.eye.icon} />
                    </IconButton>
                  </Grid>
                </Tooltip>
              </Grid>
            )}
          {invalid
            && (
              <Grid item xs={2}>
                <Tooltip title={translate('attestationFormRequest.alerts.invalidDocument')}>
                  <Grid item>
                    <InvalidDocumentIcon />
                  </Grid>
                </Tooltip>
              </Grid>
            )}
        </Grid>

      </Grid>
      {deletable
        && (
          <Grid container item justifyContent="center" xs={2}>
            <Tooltip title={translate('button.delete')}>
              <Grid item>
                <IconButton
                  style={{ padding: 2 }}
                  onClick={handleDelete}
                >
                  <FontAwesomeIcon
                    color={iconEnum.trashAlt.defaultColor}
                    icon={iconEnum.trashAlt.icon}
                    size="sm"
                    swapOpacity
                  />
                </IconButton>
              </Grid>
            </Tooltip>
          </Grid>
        )}

    </Grid>
  );
});