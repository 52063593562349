import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { translate } from 'utils';
import { useModal } from 'hooks';
import { DateHelper } from 'utils/helpers';
import { ATTESTATION_EVALUATION_RESULT, ATTESTATION_FORM_ACTIONS, ROUTES } from 'utils/constants';
import { AttestationEvaluationService } from 'services/AttestationEvaluationService';
import { DecisionButton } from 'components/_commons/Button/DecisionButton';

export const ConclusionSupervisor = ({
  requestId,
  unresolvedAlerts,
  actionsDisabled,
  transmissionDate,
  status,
  instructionStatus,
  availableActions
}) => {
  const theme = useTheme();
  const showModal = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const transmittedLessThanOneYearAgo = transmissionDate && !DateHelper.isMoreThanOneYearInThePast(new Date(transmissionDate));

  const unresolvedAlertsRemaining = unresolvedAlerts?.length > 0;
  const wasRefusedInstructor = ATTESTATION_EVALUATION_RESULT.REFUSED === instructionStatus?.value;
  const canCloseAttestation = availableActions.includes(ATTESTATION_FORM_ACTIONS.CAN_CLOSE_ATTESTATION_AFTER_SUPERVISION);

  const handleSuccess = useCallback(() => {
    navigate(ROUTES.INSTRUCTIONS);
    enqueueSnackbar(translate('attestationFormRequest.conclusion.supervision.decisionApplied'), { variant: 'success' });
  }, [enqueueSnackbar, navigate]);

  const handleError = useCallback(
    (error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }),
    [enqueueSnackbar]
  );

  const validateSupervision = useCallback(() => (showModal({
    type: 'CONFIRM',
    variant: unresolvedAlertsRemaining ? 'WARNING' : 'PRIMARY',
    title: 'attestationFormRequest.conclusion.supervision.confirmTitle',
    content: translate(unresolvedAlertsRemaining
      ? 'attestationFormRequest.conclusion.supervision.confirmValidateUnresolvedAlerts'
      : 'attestationFormRequest.conclusion.supervision.confirmValidate'),
    onConfirm: () => {
      AttestationEvaluationService.validateSupervision(requestId)
        .then(handleSuccess)
        .catch(handleError);
    },
    confirmLabel: 'button.confirmDecision',
    closeOnSubmit: true
  })), [navigate, requestId, showModal, enqueueSnackbar]);

  const dismissSupervisionToOperator = useCallback(() => (showModal({
    type: 'CONFIRM',
    variant: unresolvedAlertsRemaining ? 'PRIMARY' : 'WARNING',
    title: 'attestationFormRequest.conclusion.supervision.confirmTitle',
    content: translate(unresolvedAlertsRemaining
      ? 'attestationFormRequest.conclusion.supervision.confirmDismissOperator'
      : 'attestationFormRequest.conclusion.supervision.confirmDismissOperatorNoAlerts'),
    onConfirm: () => {
      AttestationEvaluationService.dismissSupervisionOperator(requestId)
        .then(handleSuccess)
        .catch(handleError);
    },
    confirmLabel: 'button.confirmDecision',
    closeOnSubmit: true
  })), [navigate, requestId, showModal, enqueueSnackbar]);

  const dismissSupervisionToInstructor = useCallback(() => (showModal({
    type: 'CONFIRM',
    variant: unresolvedAlertsRemaining ? 'PRIMARY' : 'WARNING',
    title: 'attestationFormRequest.conclusion.supervision.confirmTitle',
    content: translate(unresolvedAlertsRemaining
      ? 'attestationFormRequest.conclusion.supervision.confirmDismissInstructor'
      : 'attestationFormRequest.conclusion.supervision.confirmDismissInstructorNoAlerts'),
    onConfirm: () => {
      AttestationEvaluationService.dismissSupervisionInstructor(requestId)
        .then(handleSuccess)
        .catch(handleError);
    },
    confirmLabel: 'button.confirmDecision',
    closeOnSubmit: true
  })), [navigate, requestId, showModal, enqueueSnackbar]);

  const rejectSupervision = useCallback(() => (showModal({
    type: 'REJECT_REQUEST_SUPERVISION',
    modalState: {
      transmittedLessThanOneYearAgo,
      wasRefusedInstructor,
      canCloseAttestation
    },
    onSubmit: (rejectionView) => {
      AttestationEvaluationService.rejectSupervision(requestId, rejectionView)
        .then(handleSuccess)
        .catch(handleError);
    },
    confirmLabel: 'button.confirmDecision',
    closeOnSubmit: true
  })), [navigate, requestId, status, showModal, enqueueSnackbar, transmittedLessThanOneYearAgo, wasRefusedInstructor]);

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={3}>
        <DecisionButton
          color={theme.palette.success}
          label="button.conform"
          onClick={() => !actionsDisabled && validateSupervision()}
        />
      </Grid>
      <Grid item xs={3}>
        <DecisionButton
          color={theme.palette.warning}
          description="attestationFormRequest.conclusion.supervision.dismissOperator"
          label="button.notConform"
          onClick={() => !actionsDisabled && dismissSupervisionToOperator()}
        />
      </Grid>
      <Grid item xs={3}>
        <DecisionButton
          color={theme.palette.warning}
          description="attestationFormRequest.conclusion.supervision.dismissInstructor"
          label="button.notConform"
          onClick={() => !actionsDisabled && dismissSupervisionToInstructor()}
        />
      </Grid>
      <Grid item xs={3}>
        <DecisionButton
          color={theme.palette.error}
          description="attestationFormRequest.conclusion.supervision.reject"
          label="button.notConform"
          onClick={() => !actionsDisabled && rejectSupervision()}
        />
      </Grid>
    </Grid>
  );
};