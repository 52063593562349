import { translate } from 'utils';
import { EmailValidator } from 'commons-validator-js';
import { DateHelper } from 'utils/helpers/DateHelper';

const validateEmails = (emails) => {
  const emailValidator = new EmailValidator();
  const notMatchingEmail = emails.find((email) => !emailValidator.isValid(email));
  return (notMatchingEmail !== undefined)
    ? translate('attestationFormRequest.contacts.modalAdd.invalidEmail', { email: notMatchingEmail })
    : true;
};

const isCurrentToolNew = (wasNewAtAcquisition, acquisitionDate) => {
  let isNew = false;
  if (wasNewAtAcquisition && acquisitionDate) {
    isNew = !DateHelper.isMoreThanOneYearInThePast(new Date(acquisitionDate));
  }
  return isNew;
};

const validateEmail = (email) => {
  if (!email) return null;
  const emailValidator = new EmailValidator();
  const isValid = emailValidator.isValid(email);
  if (!isValid) {
    return translate('common.emailNotValid');
  }
  return null;
};

export const Validators = {
  validateEmails,
  validateEmail,
  isCurrentToolNew
};