import React, { useEffect, useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';

import { ToolsModalStepsEnum } from 'core/enums';
import { translate } from 'utils';

import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { DocumentService } from 'services';
import { AddToolModalActions } from './AddToolModalActions';
import { ToolFormContainer } from './ToolFormContainer';

export const AddToolsModal = ({
  modalState: {
    equipment, equipmentType, requestId
  },
  onSubmit, onClose
}) => {
  const [currentStep, setCurrentStep] = useState(ToolsModalStepsEnum.Registration);

  const formContext = useForm({
    defaultValues: {
      ...equipment,
      equipmentView: {
        ...equipment?.equipmentView,
        documentList: {
          ...equipment?.equipmentView?.documentList,
          innerDocuments: equipment?.equipmentView?.documentList?.documents?.reduce((prev, curr) => ({
            ...prev,
            [curr.type?.name]: (prev[curr.type?.name]) ? [...prev[curr.type?.name], curr] : [curr]
          }), {})
        },
        type: equipmentType
      }
    }
  });

  useEffect(() => {
    if (equipment?.equipmentView?.documentList?.documents?.length) {
      DocumentService.getDocumentsWithContent(equipment?.equipmentView?.documentList?.documents)
        .then((resp) => formContext.setValue(
          'equipmentView.documentList',
          {
            ...equipment?.equipmentView?.documentList,
            documents: resp,
            innerDocuments: resp.reduce((prev, curr) => ({
              ...prev,
              [curr.type?.name]: (prev[curr.type?.name]) ? [...prev[curr.type?.name], curr] : [curr]
            }), {})
          }
        ));
    }
  }, [equipment]);

  const onGoBack = () => {
    setCurrentStep(ToolsModalStepsEnum.prevStep(currentStep));
  };
  const onSubmitForm = (values) => {
    if ([ToolsModalStepsEnum.Registration, ToolsModalStepsEnum.Summary].includes(currentStep)) {
      onSubmit(values);
    } else {
      setCurrentStep(ToolsModalStepsEnum.nextStep(currentStep));
    }
  };

  const handleNextStep = () => {
    formContext.handleSubmit(() => setCurrentStep(ToolsModalStepsEnum.nextStep(currentStep)))();
  };

  return (
    <FormContainer
      formContext={formContext}
      FormProps={{ autoComplete: 'off', name: 'addToolsForm', style: { overflow: 'auto' } }}
      onSuccess={onSubmitForm}
    >
      <ModalHeader onClose={onClose}>
        {`${equipmentType.label} - ${translate(equipment?.id
          ? 'attestationFormRequest.tools.modal.title.edit'
          : 'attestationFormRequest.tools.modal.title.add')}`}
      </ModalHeader>
      <ToolFormContainer
        currentStep={currentStep}
        equipment={equipment}
        equipmentType={equipmentType}
        formContext={formContext}
        requestId={requestId}
      />
      <AddToolModalActions
        currentStep={currentStep}
        disabled={false}
        goBack={onGoBack}
        onClose={onClose}
        onNextStep={handleNextStep}
      />
    </FormContainer>
  );
};