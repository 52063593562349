import { faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DialogContent, Grid, TextField, Typography
} from '@mui/material';
import { CloseModalButton } from 'components/_commons/Modals/CloseModalButton';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { translate } from 'utils';
import { useTheme } from '@mui/material/styles';
import { ModalHeader } from './_ModalHeader';
import { CustomActionButtons } from './_CustomActionButtons';

export const CreateInstitutionGroupModal = ({
  onConfirm, onClose, closeOnSubmit, defaultValues, defaultActivity, parentInstitutionGroupId, parentCompanyId, notEditable
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(defaultValues.name);
  const isEdit = defaultValues && defaultValues.name;
  const theme = useTheme();

  const handleValidateModal = useCallback((e) => {
    e.preventDefault();
    setIsLoading(true);
    const newInstitutionGroup = {
      ...defaultValues,
      name,
      parentInstitutionGroupId,
      parentCompanyId,
      activity: defaultActivity?.value
    };
    // In case of edition do not pass parent data
    if (isEdit) {
      newInstitutionGroup.parentInstitutionGroupId = null;
      newInstitutionGroup.parentCompanyId = null;
    }
    onConfirm(newInstitutionGroup).finally(() => setIsLoading(false));
    if (closeOnSubmit) {
      onClose();
    }
  }, [closeOnSubmit, onClose, onConfirm, defaultValues, name, parentInstitutionGroupId, parentCompanyId, defaultActivity]);

  return (
    <form autoComplete="off" name="institutionForm" onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={faBuilding} />
        <Typography component="span">
          {(notEditable && translate('modalInstitutionGroup.viewInstitutionGroup')) || (isEdit
            ? translate('modalInstitutionGroup.editInstitutionGroup')
            : translate('modalInstitutionGroup.newInstitutionGroup'))}
        </Typography>
      </ModalHeader>

      <DialogContent style={{ minWidth: '500px', paddingTop: 15 }}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <TextField
              disabled={notEditable}
              label={translate('modalInstitutionGroup.name')}
              name="institutionGroupName"
              required
              value={name}
              variant="standard"
              onChange={(event) => setName(event.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              disabled={notEditable}
              label={translate('common.activity')}
              name="institutionActivity"
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  backgroundColor: theme.palette.common.white,
                  color: theme.palette.common.white
                }
              }}
              value={defaultActivity?.label || ' '}
              variant="standard"
            />
          </Grid>
        </Grid>
      </DialogContent>

      {notEditable
        ? (
          <CloseModalButton onClose={onClose} />
        )
        : (
          <CustomActionButtons
            disabled={notEditable}
            isLoading={isLoading}
            onClose={onClose}
          />
        )}
    </form>
  );
};

CreateInstitutionGroupModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  closeOnSubmit: PropTypes.bool,
  defaultValues: PropTypes.shape({}),
  parentInstitutionGroupId: PropTypes.string,
  parentCompanyId: PropTypes.string,
  notEditable: PropTypes.bool
};

CreateInstitutionGroupModal.defaultProps = {
  closeOnSubmit: true,
  defaultValues: {},
  notEditable: false,
  parentCompanyId: '',
  parentInstitutionGroupId: ''
};