import React from 'react';
import { styled } from '@mui/material/styles';
import { ButtonBase, Card } from '@mui/material';
import PropTypes from 'prop-types';

const StyledCardButton = styled(ButtonBase)(() => ({
  width: '100%',
  minHeight: '100px'
}));

const StyledCard = styled(Card)(({ theme, ...props }) => ({
  color: theme.palette[props.color]?.main,
  transition: `all ${theme.transitions.duration.standard}ms`,

  '&:hover': {
    backgroundColor: theme.palette[props.color]?.lightest,
    borderColor: `${theme.palette[props.color]?.main} !important`
  }
}));

export const CardButton = ({ children, color, ...props }) => (
  <StyledCard color={color} {...props}>
    <StyledCardButton style={{ height: '100%' }}>
      {children}
    </StyledCardButton>
  </StyledCard>
);

CardButton.propTypes = {
  color: PropTypes.string
};

CardButton.defaultProps = {
  color: 'primary'
};