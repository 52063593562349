import {
  action, makeObservable, observable, runInAction
} from 'mobx';
import { FilterHelper } from 'utils/helpers';
import { UserAccountService } from 'services';

class UserStore {
  constructor() {
    // User
    this.isConnecting = true;
    this.isConnected = false;
    this.isOffline = false;
    this.keycloak = null;
    this.userConnected = null;
    this.scope = null;
    // Scope
    this.isCurrentScopeLoading = true;
    this.currentStructureLevel = {};
    this.currentRoles = [];
    this.structureLevelOptions = [];
    this.currentCountry = null;
    this.currentCompany = {};
    this.treeRootCompany = {};

    makeObservable(this, {
      isConnecting: observable,
      isConnected: observable,
      isOffline: observable,
      userConnected: observable,
      scope: observable,
      isCurrentScopeLoading: observable,
      currentRoles: observable,
      currentStructureLevel: observable,
      structureLevelOptions: observable,
      currentCountry: observable,
      currentCompany: observable,
      treeRootCompany: observable,
      setIsConnecting: action,
      connectUser: action,
      disconnectUser: action,
      getToken: action,
      isTokenExpired: action,
      refreshToken: action,
      keycloakLogin: action,
      setOffline: action,
      getCurrentScope: action
    });
  }

  setIsConnecting(isConnecting = true) {
    this.isConnecting = isConnecting;
  }

  connectUser(keycloak) {
    this.keycloak = keycloak;

    if (keycloak && keycloak.tokenParsed) {
      this.isConnected = true;
      this.isOffline = false;
      this.userConnected = keycloak.tokenParsed;
    }
  }

  disconnectUser() {
    this.isConnected = false;
    this.userConnected = false;
    this.isOffline = true;
    FilterHelper.deleteStoredFilters();
    const logoutOption = { redirectUri: window.location.origin };
    return this.keycloak.logout(logoutOption);
  }

  getToken() {
    return this.keycloak ? this.keycloak.token : null;
  }

  isTokenExpired() {
    return this.keycloak ? this.keycloak.isTokenExpired() : null;
  }

  refreshToken() {
    return this.keycloak.updateToken();
  }

  keycloakLogin() {
    const currentCountry = localStorage.getItem('i18nextLng') || 'fr';
    return this.keycloak.login({ locale: currentCountry });
  }

  setOffline(offline = true) {
    return new Promise((resolve) => {
      this.isOffline = offline;
      resolve();
    });
  }

  getCurrentScope() {
    UserAccountService.getCurrentScope()
      .then((resp) => runInAction(() => {
        this.structureLevelOptions = resp.accessibleLevels;
        this.currentCountry = resp.country;
        this.currentStructureLevel = resp.currentStructureLevel;
        this.currentRoles = Object.values(resp.roles);
        this.scope = resp;
        this.currentCompany = resp.company;
        this.treeRootCompany = resp.currentStructureLevelIsParentCompany ? this.structureLevelOptions[1] : this.currentCompany;
      })).finally(() => runInAction(() => {
        this.isCurrentScopeLoading = false;
      }));
  }
}

export const userStore = new UserStore();