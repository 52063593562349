function mapFromApi(address) {
  return {
    id: address.id,
    street: address.address1,
    building: address.address2,
    postalCode: address.postalCode,
    city: address.city,
    country: address.country.label
  };
}

export const AddressModel = {
  mapFromApi
};