import React, { createContext, useState, useContext, useCallback } from 'react';
import { Modal } from 'components/_commons/Modals';

export const ModalContext = createContext((_) => console.log("Context not updated yet!"));

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({children}) => {
  const [isOpen, setOpen] = useState(false);
  const [modalState, setModalState] = useState(undefined);

  const openModal = useCallback((options) => {
    setOpen(true);
    setModalState(options);
  }, []);

  const closeModal = () => {
    setOpen(false);
    setModalState(undefined);
  }

  return (
    <>
      <ModalContext.Provider value={openModal}>
        {children}
      </ModalContext.Provider>

      <Modal isOpen={isOpen} modalState={modalState} closeModal={closeModal} />
    </>
  );
};