import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, DialogActions, DialogContent } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/pro-regular-svg-icons';

import { translate } from 'utils';
import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import { StructureLevelSelect } from 'components/_commons/Modals/common/StructureLevelSelect';

export const UpdateUserLevelModal = ({
  onClose,
  onConfirm,
  defaultValue,
  structureLevelOptions,
  isLoading
}) => {
  const [selectedOption, setSelectedOption] = useState(
    structureLevelOptions.find((levelOption) => levelOption.value === defaultValue)
  );

  const handleValidateModal = (e) => {
    e.preventDefault();
    onConfirm({ institution: selectedOption });
    onClose();
  };

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={faBuilding} />
        {translate('modalStructureLevelUpdate.title')}
      </ModalHeader>

      <DialogContent style={{ marginTop: '30px' }}>
        <StructureLevelSelect
          isLoading={isLoading}
          options={structureLevelOptions}
          placeholder="common.searchByStructureLevel"
          required
          selectedItem={selectedOption || ''}
          onSelectItem={(value) => setSelectedOption(value)}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{translate('button.cancel')}</Button>
        <Button color="primary" type="submit">{translate('button.save')}</Button>
      </DialogActions>
    </form>
  );
};

UpdateUserLevelModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({})
};

UpdateUserLevelModal.defaultProps = {
  defaultValues: {}
};